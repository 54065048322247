export const integrations = [
  // Disabled Hubspot temporary because it is confusing customers (21/08/2022)
  {
    title: 'Hubspot',
    type: 'CRM',
    internalName: 'hubspot',
    logo: '../assets/images/integrations/hubspot.png',
    connected: null,
    customIntegration: false,
    available: true,
    active: false,
    mappingFields: [
      "Person.company",
      "Person.email",
      "Person.firstname",
      "Person.lastname",
      "Person.phone",
      "Person.website",
      "Company.city",
      "Company.domain",
      "Company.industry",
      "Company.name",
      "Company.phone",
      "Company.state"
    ],
    variables: []
  },
  {
    title: 'Gong',
    type: 'Sales Enablement',
    internalName: 'gong',
    available: true,
    logo: '../assets/images/integrations/gong.png',
    connected: null,
    customIntegration: false,
    active: false,
    variables: [ {
      "type": "input",
      "name": "prospectEmail",
      "title": "Prospect email",
      "default": "",
      "tip": "Your prospect email, as set in your CRM.",
      "validation": {
        "required": false
      }
    } ]
  },
  {
    title: 'Salesforce',
    type: 'CRM',
    hasSandbox: true,
    internalName: 'salesforce',
    logo: '../assets/images/integrations/salesforce.png',
    connected: null,
    customIntegration: false,
    available: true,
    active: false,
    mappingReference: 'https://developer.salesforce.com/docs/atlas.en-us.object_reference.meta/object_reference/sforce_api_objects_contact.htm',
    mappingFields: [
      "AccountId",
      "AssistantName",
      "AssistantPhone",
      "Birthdate",
      "CanAllowPortalSelfReg",
      "CleanStatus",
      "ConnectionReceivedId",
      "ConnectionSentId",
      "Department",
      "Description",
      "DoNotCall",
      "Email",
      "EmailBouncedDate",
      "EmailBouncedReason",
      "Fax",
      "FirstCallDateTime",
      "FirstEmailDateTime",
      "FirstName",
      "HasOptedOutOfEmail",
      "HasOptedOutOfFax",
      "HomePhone",
      "IndividualId",
      "IsDeleted",
      "IsEmailBounced",
      "IsPersonAccount",
      "LastActivityDate",
      "LastName",
      "LastReferencedDate",
      "LastViewedDate",
      "LeadSource",
      "MailingAddress",
      "MailingCity",
      "MailingState",
      "MailingCountry",
      "MailingPostalCode",
      "MailingStateCode",
      "MailingCountryCode",
      "MailingStreet",
      "MailingGeocodeAccuracy",
      "MailingLatitude",
      "MailingLongitude",
      "MasterRecordId",
      "MiddleName",
      "MobilePhone",
      "Name",
      "OtherAddress",
      "OtherCity",
      "OtherCountry",
      "OtherPostalCode",
      "OtherState",
      "OtherCountryCode",
      "OtherStateCode",
      "OtherGeocodeAccuracy",
      "OtherLatitude",
      "OtherLongitude",
      "OtherPhone",
      "OtherStreet",
      "OwnerId",
      "Phone",
      // "PhotoUrl", // disabled because this is path, not a url
      "RecordTypeId",
      "ReportsToId",
      "Salutation",
      "Suffix",
      "Title",
      "Account.Name",
      "Account.NumberOfEmployees",
      "Account.AnnualRevenue",
      "Account.Industry",
      "Account.Phone",
      "Account.Type",
      "Account.Website"
    ],
    variables: [{
      "type": "input",
      "name": "salesforceId",
      "title": "Salesforce Id",
      "mapping": "Id",
      "hidden": "true",
      "tip": "Your prospect id, as set in your Salesforce CRM.",
      "validation": {
        "required": false
      }
    }]
  },
  {
    title: 'Zapier',
    internalName: 'zapier',
    type: 'API',
    logo: '../assets/images/integrations/zapier.png',
    connected: null,
    active: false,
    available: true,
    customIntegration: false,
    variables: [ {
      "type": "input",
      "name": "prospectEmail",
      "title": "Prospect email",
      "default": "",
      "tip": "Your prospect email, as set in your CRM.",
      "validation": {
        "required": false
      }
    } ]
  },
  {
    title: 'Pipedrive',
    internalName: 'pipedrive',
    type: 'Sales Enablement',
    logo: '../assets/images/integrations/pipedrive.png',
    connected: null,
    active: false,
    customIntegration: false,
    variables: [ {
      "type": "input",
      "name": "prospectEmail",
      "title": "Prospect email",
      "default": "",
      "tip": "Your prospect email, as set in your CRM.",
      "validation": {
        "required": false
      }
    } ]
  },
  {
    title: 'Salesloft',
    type: 'CRM',
    internalName: 'salesloft',
    logo: '../assets/images/integrations/salesloft.png',
    connected: null,
    customIntegration: false,
    available: true,
    triggers: [
    // "account_created",
    // "account_deleted",
    // "account_updated",
    // "bulk_job_completed",
    // "cadence_created",
    // "cadence_deleted",
    "cadence_membership_created",
    // "cadence_membership_updated",
    // "cadence_updated",
    // "call_created",
    // "call_data_record_created",
    // "call_data_record_updated",
    // "call_updated",
    // "email_updated",
    // "link_swap",
    // "meeting_booked",
    // "meeting_updated",
    // "note_created",
    // "note_deleted",
    // "note_updated",
    // "person_created",
    // "person_deleted",
    // "person_updated",
    // "step_created",
    // "step_deleted",
    // "step_updated",
    // "success_created",
    // "task_created",
    // "task_deleted",
    // "task_updated",
    // "user_created",
    // "user_updated"
    ],
    mappingFields: [
      "Account.name",
      "Account.domain",
      "Account.conversational_name",
      "Account.description",
      "Account.phone",
      "Account.website",
      "Account.linkedin_url",
      "Account.twitter_handle",
      "Account.street",
      "Account.city",
      "Account.state",
      "Account.postal_code",
      "Account.country",
      "Account.locale",
      "Account.industry",
      "Account.company_type",
      "Account.founded",
      "Account.revenue_range",
      "Account.size",
      "Person.first_name",
      "Person.last_name",
      "Person.email_address",
      "Person.secondary_email_address",
      "Person.personal_email_address",
      "Person.phone",
      "Person.home_phone",
      "Person.mobile_phone",
      "Person.linkedin_url",
      "Person.title",
      "Person.city",
      "Person.state",
      "Person.country",
      "Person.work_city",
      "Person.work_state",
      "Person.work_country",
      "Person.person_company_name",
      "Person.person_company_website",
      "Person.person_company_industry",
      "Person.personal_website",
      "Person.twitter_handle",
      "Person.job_seniority",
      "User.name",
      "User.first_name",
      "User.last_name",
      "User.job_role",
      "User.slack_username",
      "User.twitter_handle",
      "User.email",
      "User.email_client_email_address",
      "User.sending_email_address",
      "User.from_address",
      "User.full_email_address",
      "User.bcc_email_address",
      "User.email_signature",
    ],
    variables: [{
      "type": "input",
      "name": "salesloftId",
      "title": "Salesloft Id",
      "mapping": "Person.id",
      "hidden": "true",
      "tip": "Your prospect id, as set in your Salesloft CRM.",
      "validation": {
        "required": false
      }
    }]
  },
  {
    title: 'Outreach.io',
    internalName: 'outreach',
    type: 'Sales Enablement',
    logo: '../assets/images/integrations/outreach.png',
    connected: null,
    customIntegration: false,
    variables: [ {
      "type": "input",
      "name": "prospectEmail",
      "title": "Prospect email",
      "default": "",
      "tip": "Your prospect email, as set in your CRM.",
      "validation": {
        "required": false
      }
    } ]
  },
  {
    title: 'Zoho',
    internalName: 'zoho',
    type: 'CRM',
    logo: '../assets/images/integrations/zoho.png',
    connected: null,
    customIntegration: false,
    variables: [ {
      "type": "input",
      "name": "prospectEmail",
      "title": "Prospect email",
      "default": "",
      "tip": "Your prospect email, as set in your CRM.",
      "validation": {
        "required": false
      }
    } ]
  },
  {
    title: 'Marketo',
    internalName: 'marketo',
    type: 'Marketing',
    logo: '../assets/images/integrations/marketo.png',
    connected: null,
    customIntegration: false,
    variables: [ {
      "type": "input",
      "name": "prospectEmail",
      "title": "Prospect email",
      "default": "",
      "tip": "Your prospect email, as set in your CRM.",
      "validation": {
        "required": false
      }
    } ]
  },
  {
    title: 'Zoominfo',
    internalName: 'zoominfo',
    logo: '../assets/images/integrations/zoominfo.png',
    connected: null,
    customIntegration: false,
    variables: [ {
      "type": "input",
      "name": "prospectEmail",
      "title": "Prospect email",
      "default": "",
      "tip": "Your prospect email, as set in your CRM.",
      "validation": {
        "required": false
      }
    } ]
  },
  {
    title: 'Highspot',
    internalName: 'highspot',
    logo: '../assets/images/integrations/highspot.png',
    connected: null,
    customIntegration: false,
    variables: [ {
      "type": "input",
      "name": "prospectEmail",
      "title": "Prospect email",
      "default": "",
      "tip": "Your prospect email, as set in your CRM.",
      "validation": {
        "required": false
      }
    } ]
  },
  {
    title: 'Seismic',
    internalName: 'seismic',
    logo: '../assets/images/integrations/seismic.png',
    connected: null,
    customIntegration: false,
    variables: [ {
      "type": "input",
      "name": "prospectEmail",
      "title": "Prospect email",
      "default": "",
      "tip": "Your prospect email, as set in your CRM.",
      "validation": {
        "required": false
      }
    } ]
  }
]
