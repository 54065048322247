import { environment } from "../../../../../environments/environment";
const env = environment.env;

export const generateMobileIframeHtml = (data: { cssUrl: string, page: Record<string, any> }) => { 
  const { cssUrl, page } = data
  let html = `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />

        <style type="text/css">
          body::-webkit-scrollbar{
            display: none;
          }
          body {
            scrollbar-width: none;
            -ms-overflow-style: none;
          }
        </style>
        ${
          cssUrl
            ? `<link id="sd-design-system" href="${
                cssUrl + "?" + new Date().getTime()
              }" rel="stylesheet" type="text/css">`
            : ""
        }
      </head>
      <link rel="stylesheet" href="https://www.storydoc.com/assets/static-bundle/latest/presentation-bundle.css"/>
    `;

  html += `<body class="sd-presentation">
      <script defer src="https://www.storydoc.com/assets/static-bundle/latest/presentation-bundle.js"></script>
      <div id="storyFront"></div>
      <script>
        window.onload = function () {
          document.dispatchEvent(new CustomEvent('storydocRender', {
            detail: {
              isEditor: false,
              isPreview: false,
              story: ${JSON.stringify(page)},
              element: document.getElementById('storyFront')
            }
          }));
        };
      </script>
    </body>`;
  html += "</html>";

  return html;
};