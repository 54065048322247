import { Component, Input } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { AccountsService } from "../../../../services/accounts.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToasterService } from "../../../../services/toaster.service";
import { HelpCenterService } from "../../../help-center/help.service";
import { Router } from "@angular/router";

type PayloadType = { checkoutId: string, userId: string, userEmail: string };

@Component({
  selector: "ngx-setup-external-payment",
  templateUrl: "./setup-external-payment.component.html",
  styleUrls: ["./setup-external-payment.component.scss"],
})
export class SetupExternalPaymentDialog {
  constructor(
    protected ref: NbDialogRef<SetupExternalPaymentDialog>,
    private accountsService: AccountsService,
    private toasterService: ToasterService,
    private helpCenterService: HelpCenterService,
    private router: Router
  ) { }

  @Input() payload: PayloadType;
  error: boolean = false;
  

  ngOnInit() {
    this.accountsService.setupAccountOnExternalPayment(this.payload).pipe(
      catchError((error: HttpErrorResponse): Observable<any> => {
        this.error = true;
        return throwError(error);
      })
    ).subscribe(() => {
      window.location.reload();
    })
  }

  navigateWizard() {
    this.ref.close();
    this.router.navigateByUrl("/pages/wizard");
  }

  contactUs() {
    this.ref.close();
    const data = {
      topic: 'Billing and plans',
      subject: '',
      messagePlaceholder: ''
    }
    this.helpCenterService.setFormFieldsData(data)
    this.router.navigateByUrl("/pages/help");
  }

}
