import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-discard-dialog',
  templateUrl: 'discard-dialog.component.html',
  styleUrls: ['discard-dialog.component.scss'],
})
export class DiscardDialog {

  constructor(protected ref: NbDialogRef<DiscardDialog>) { }

  @Input() body = 'Are you sure you want to <br> close this process?'
  @Input() primary = true;
  @Input() secondary = true;
  @Input() primaryText = 'Yes, close';
  @Input() secondaryText = 'Go back';

  submit(value = false) {
    this.ref.close(value);
  }

}
