import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, from, of } from 'rxjs';
import { RenderingService } from '../../services/rendering.service';
import { catchError, filter, map, switchMap, timeout } from 'rxjs/operators';
import { PagesService } from '../../services/pages.service';
import { MediaService } from '../../services/media.service';
import { environment } from '../../../environments/environment';
import { HttpEventType } from '@angular/common/http';
import uuid from 'uuid';
import * as _ from 'lodash';
import { industries } from './utils/ai-industries';
import { Industry } from './wizard.interface';
import { googleFontsCatalog } from '../design-system-dialog/fonts';
import { Page, Template } from './wizard-templates/template.interface';
import { updateGrid, updateImage, updateSlideBackground, updateSlidePreview, updateVideo } from './utils';
import { TemplatesService } from './wizard-templates/templates.service';

@Injectable({
  providedIn: 'root',
})

export class WizardFacade {
  constructor(private renderingService: RenderingService,
    private pagesService: PagesService,
    private templatesService: TemplatesService,
    private mediaService: MediaService) { }

  triggerBrandFetchAPI$ = new Subject<void>();
  brandFetchData$ = new BehaviorSubject<Record<string, any> | null>(null);
  metadataFetchLoaderStatus$ = new BehaviorSubject<boolean>(false);
  metadataRes$ = new BehaviorSubject<Record<string, any> | null>(null);
  openWizard$ = new Subject<void>();
  brandedHidden$ = new BehaviorSubject<boolean>(true);


  showHeaderRegenerate$ = new BehaviorSubject<boolean>(false);
  triggerRegenerate$ = new Subject<void>();
  
  hasBrand$() {
    return this.brandFetchData$.pipe(map(res => !!res));
  }

  triggerBrandFetchAPI() {
    this.triggerBrandFetchAPI$.next();
  }

  changeMetadataFetchLoaderStatus(status: boolean) {
    this.metadataFetchLoaderStatus$.next(status);
  }

  setMetadataRes(data: Record<string, any> | null) {
    this.metadataRes$.next(data);
  }

  setBrandFetchData(data: Record<string, any> | null) {
    this.brandFetchData$.next(data);
  }

  uploadFileToLibrary$(url: string) {
    return this.renderingService.getFileContents(url, { responseType: 'blob' })
      .pipe(switchMap(blob => this.uploadFilePost$(blob)))
  }

  uploadFilePost$(file) {
    return this.pagesService.getSignedPostPolicy(file.type.replace(/\+/, '%2B'))
      .pipe(switchMap(result => {
        return this.pagesService.uploadFilePost(result.url, file)
          .pipe(
            filter(res => res.type === HttpEventType.Response),
            switchMap(() => this.uploadMediaToDB$(file, result)),
            timeout(30000)
          )
      }))
  }

  uploadMediaToDB$(file: File, result) {
    return this.mediaService.create({
      title: file.name || uuid.v4(),
      type: file.type,
      typeGroup: file.type.split('/')[ 0 ],
      size: file.size,
      url: environment.assets + '/' + result.filename,
      folder: null
    })
  }

  findRelevantLogo$(theme: string, brandfetch: Record<string, any>|null) {
    if (!brandfetch || !brandfetch.logos) return from([ '' ]);

    if (!theme) {
      const logo = brandfetch.logos.find(item => item.type === 'logo');
      if (logo) return from([_.get(logo, 'formats[0].src', '')])
      else return from([_.get(brandfetch, 'logos[0].formats[0].src', '')])
    }

    const logos = brandfetch.logos.filter(item => item.theme === theme);

    if (!logos.length) {
      const logoItem = brandfetch.logos.find(item => item.type === 'logo');
      if (logoItem) {
        const pngLogo = logoItem.formats.find(item => item.format === 'png' && item.background === 'transparent');
        if (pngLogo) return this.updateLogoThemeColor$(pngLogo.src, theme)
        else return from([_.get(logoItem, 'formats[0].src', '')])
      }
      return from([_.get(brandfetch, 'logos[0].formats[0].src', '')])
    }
  
    
    const getLogo = logos.find(item => item.type === 'logo');
    if (getLogo) return from([_.get(getLogo, 'formats[0].src', '')])
    else  return from([_.get(logos[0], 'formats[0].src', '')])
  }


  updateLogoThemeColor$(url: string, theme: string) {
    const options = { brightness: true, invert: false };
    if(theme === 'light') options[ 'invert' ] = true;
    return this.mediaService.editImageStyleFromURL(url, options)
    .pipe(
      switchMap(res => this.uploadFileToLibrary$(res.response)),
      switchMap(res => from([ res.url ])),
      catchError((err: any) : Observable<any> => from([url]))
    )
  }

  getWebsiteMetadata$(url: string) {
    if(!url) return from([null])
    return this.mediaService.getWebsiteMetadata(url);
  }

  getSelectedIndustry(prop: string) {
    if (!prop) return null;
    const filtered = industries.filter((industry: Industry) => {
      const isInProperty = prop.toLowerCase() === industry.property.toLowerCase();

      // If it's the property, return it
      if (isInProperty) return industry;

      // Otherwise, check the synonyms
      if (!industry.synonyms) return null;
      const synonymsArray = industry.synonyms.split(',').map((item: string) => item.trim().toLowerCase());
      return synonymsArray.some(item => prop.toLowerCase() === item) ? industry : null;
    });
    return filtered[ 0 ];
  }
    
  getBrandedFont$(brandfetch: Record<string, any>|null) {
    if (!brandfetch || !brandfetch.fonts) return of(null);
    const font = brandfetch.fonts.find((font: { origin: string, name: string, type: string }) => font.origin === 'google' && font.type === 'body');
    if (!font) return of(null);

    const catalogFont = googleFontsCatalog.find(item => item.value === font.name);
    return of(catalogFont ? catalogFont.value : null);
  }
  
  getAccentColor$(brandfetch: Record<string, any>|null) {
    if (!brandfetch || !brandfetch.colors) return of(null);
    const accent = brandfetch.colors.find((color: { hex: string, type: string }) => color.type === 'accent');
    if (!accent) return of(null);
    return of(accent.hex);
  }


  mapIndustryAssets(story: Record<string, any>, industry: Record<string, string>) {
    try {
      const slides = _.cloneDeep(story.slides);
      const json = { ...industry };
      slides.forEach((slide, index) => {;
        slide.settings = updateSlideBackground(slide.settings, json, false);
        slide.combos.forEach(combo => {
          combo.sets.forEach(set => {
            set.groups.forEach(group => {
              group.forEach(element => {
                  if (element.name === 'Grid') element.data = updateGrid(element.data, json, false);
                  if (['Image', 'Logo'].includes(element.name)) element.data = updateImage(element.data, json, false);
                  if (element.name === 'Video') element.data = updateVideo(element.data, json, false);
              })
            })
          })
        })
      })
      return {...story, slides: slides}
    } catch (e) {
      return story;
    }
  }


  handleUpdatePageDesign$(template: Template) {
    const template$ = template.page.slides ? of(template.page) : this.templatesService.getSingleTemplate(template.page._id);
    return template$.pipe(
      switchMap(page => {
        template.page = page;
        return this.brandFetchData$.pipe(
          switchMap(brand => {
            if (!brand) {
              this.brandedHidden$.next(true);
              return of({ ...template, noBrand: true })
            }
            return combineLatest([
              this.findRelevantLogo$(template.logoTheme, brand),
              this.getBrandedFont$(brand),
              this.getAccentColor$(brand)
            ])
            .pipe(switchMap(([ logo, font, color ]) => {
                if(logo) template.page.slides[ 0 ] = updateSlidePreview(logo, template.page.slides[ 0 ], _);
                if (font) {
                  template.page.settings.designSystemV2 = {
                    ...template.page.settings.designSystemV2,
                    font: font,
                    titleFont: font,
                    subtitleFont: font,
                    paragraphFont: font
                  }
                }
                if (color) {
                  template.page.settings.designSystemV2 = {
                    ...template.page.settings.designSystemV2,
                    primaryColor: color,
                    themeColor4: color,
                  }
                }
                const noBrand = !logo && !color;
                this.brandedHidden$.next(noBrand);
                if (brand.industry) {
                  const page = this.mapIndustryAssets(template.page, brand.industry) as Page;
                  template = {...template, page}  
                }

                return of({ ...template, noBrand });
              })
            )
          })
        )
          .pipe(catchError(() => {
            this.brandedHidden$.next(true);
            return of({ ...template, noBrand: true })
          }))
      })
    )
  }

}
 