import { Component } from "@angular/core";
import { WorkspaceFacade } from "../workspace.facade";
import { NbDialogRef } from "@nebular/theme";

@Component({
    selector: 'ngx-workspace-expired-message',
    templateUrl: 'workspace-expired-message.component.html',
    styleUrls: [ 'workspace-expired-message.component.scss' ],
})
export class WorkspaceExpiredMessageDialogComponent {

    constructor(private workspaceFacade: WorkspaceFacade,
                protected ref: NbDialogRef<WorkspaceExpiredMessageDialogComponent>) { }


    ngOnInit() {
        this.workspaceFacade.deleteCookie(this.workspaceFacade.invitationKey);
        this.workspaceFacade.deleteCookie(this.workspaceFacade.validationKey);
        this.workspaceFacade.deleteCookie(this.workspaceFacade.successKey);
        this.workspaceFacade.track('show expired link popup');
    }
    close() {
        this.ref.close();
    }

    ngOnDestroy() {
        this.workspaceFacade.deleteCookie(this.workspaceFacade.expiredKey);
    }
}