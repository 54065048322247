import { Component } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode=isWindowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>


      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {

  isWindowMode = true;

  constructor() {
    this.checkIfWindowMode()
  }

  checkIfWindowMode() {
    this.isWindowMode = window.location.hostname.indexOf('editor-staging') === -1 && window.location.hostname.indexOf('editor.storydoc') === -1 && window.location.hostname !== 'localhost';
  }

}

// <nb-layout-footer fixed>
//   <ngx-footer></ngx-footer>
// </nb-layout-footer>

// <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
//   <ng-content select="nb-menu"></ng-content>
// </nb-sidebar>
