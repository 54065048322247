import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { from, Observable, Subscription, throwError } from 'rxjs';
import { SenderDataService } from './account.service';
import { UsersService } from '../../services/usersV2.service';
import { ToasterService } from '../../services/toaster.service';
import { AuthService } from '../../auth.service';
import { catchError, map, pairwise, startWith, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { personalData, sender } from './personal-data.interface';
import { NbDialogRef } from '@nebular/theme';
@Component({
  selector: 'ngx-account',
  templateUrl: './account.component.html',
  styleUrls: [ './account.component.scss' ]
})
export class AccountComponent implements OnInit {

  constructor(private usersService: UsersService,
    private toasterService: ToasterService,
    private auth: AuthService,
    protected ref: NbDialogRef<AccountComponent>,
    private senderDataService: SenderDataService) { }


  saving: boolean = false;
  disabled: boolean = true;
  form: FormGroup
  formSubscription: Subscription;
  personalInfoData$ = this.senderDataService.personalInfoData$;
  changedValues = {};
  isError: boolean = false;


  ngOnInit(): void {
    this.personalInfoData$
      .pipe(takeWhile(data => !data, true))
      .pipe(map(data => this.checkErrorState(data)))
      .subscribe(data => { if (data) this.initForm(data.sender); })
  }

  initForm(user): void {
    this.form = new FormGroup({
      'name': new FormControl(user.name, [ Validators.required ]),
      'picture': new FormControl(user.picture || ''),
      'calendar': new FormControl(user.calendar || '')
    })

    this.formSubscription = this.form.valueChanges.pipe(startWith(this.form.value),
      pairwise(),
      map(([prev, curr]) => Object.keys(curr).find(key => prev[key] !== curr[key]))
    )
    .subscribe(key => {
      if(this.form.get(key)) this.changedValues[ key ] = this.form.get(key).value;
      this.disabled = !this.form.valid;
    })
  }

  checkErrorState(data) {
    if (data !== undefined) return data;
    this.isError = true;
    this.toasterService.showToast('danger', 'Failed to get account information', 'Please try again in a few minutes');
    throw new Error('Failed to get sender information');
  }

  prepDataToSubmit(info: sender) {
    const data = { profile: {picture: info.picture, calendar: info.calendar} }
    if (this.changedValues['name']) data[ 'firstName' ] = info.name;
    return data
  }

  resetSubmission() {
    this.saving = false;
    this.disabled = true;
    this.changedValues = {};
    this.personalInfoData$
      .pipe(take(1))
      .subscribe((data: personalData) => this.senderDataService.updatePersonalInfoData({ ...data, sender: { ...this.form.value, email: data.sender.email } }))
  }

  submit() {
    if(!this.form.valid) return
    this.saving = true;
    const data = this.prepDataToSubmit(this.form.value);
    this.usersService.updateUserProfile(data)
    .pipe(
      switchMap(res => {
        if (!data['firstName']) return from([ res ]);
        return this.auth.getUser$().pipe(tap(user => this.auth.updateCurrentUser(user))); // update current user
      })
    )
    .pipe(
      catchError((err: any): Observable<any> => {
        this.saving = false;
        this.toasterService.showToast('danger', 'Failed to save settings', 'Please try again');
        return throwError(err);
      })
    )
    .subscribe(() => {
      this.toasterService.showToast('success', 'Settings saved successfully');
      this.resetSubmission();
      this.close(true);
    })
  }

  close(status = false) {
    this.ref.close(status);
  }
  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

