import {Pipe} from '@angular/core';
@Pipe({
    name: 'seats'
})
export class SeatsPipe {

constructor() {}

  transform(seats: string): any {
      return seats ? Number(seats) + 1 : 1;
  }
}
