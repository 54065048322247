import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { GettingStartDialogComponent } from '../../../components/getting-start-dialog/getting-start-dialog.component';
@Component({
  selector: 'ngx-getting-start-component',
  templateUrl: 'getting-start-component.component.html',
  styleUrls: ['getting-start-component.component.scss'],
})
export class GettingStartComponent {

  constructor(private dialogService: NbDialogService,
    public router: Router) { }


  items = [
    {
      condition: 'editor',
      message: 'sd-get-started-message',
      image: '/assets/images/edit-presentation-v4.jpg',
      title: '🤔 How to use Storydoc?',
      video: {
        url: 'https://www.youtube.com/embed/ZHRrFsWUFgU',
        type: 'iframe',
        ratio: '56.25'
      }
    },
    {
      condition: 'analytics',
      message: 'sd-get-started-analytics',
      image: '/assets/images/get-powerful-analytics.jpg',
      title: '🤔 How to get powerful analytics?',
      video: {
        url: 'https://www.youtube.com/embed/CfAlRxNd7h8',
        type: 'iframe',
        ratio: '56.25'
      }
    }
  ];

  open(cookies: string, video: {url: string, type: string, ratio: string}) {
    this.close(cookies);
    this.dialogService.open(GettingStartDialogComponent, { context: {url: video.url, type: video.type, ratio: video.ratio} })
  }

  close(cookies: string) {
    this.setCookie(cookies, true, 60);
  }

  setCookie(name: string, value: boolean, days: number) {
    let expires: string = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  getCookie(name: string){
    return `; ${document.cookie}`
    .split(`; ${name}=`)
    .pop()
    .split(';')
    .shift();
  }

  get isEditor() { return /\/pages\/editor\/[A-Za-z0-9]*$/.test(this.router.url); }

  get isLibrary() { return this.router.url === '/pages/editor'; }

  get isAnalytics() { return this.router.url === '/pages/analytics' }

  get isEditorMessage() { return this.getCookie('sd-get-started-message'); }

  get isAnalyticsMessage() {  return this.getCookie('sd-get-started-analytics'); }

}
