import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-success-update-plan',
  templateUrl: './success-update-plan-dialog.component.html',
  styleUrls: [ './success-update-plan-dialog.component.scss' ]
})
export class SuccessUpdatePlanDialog {
  constructor(protected ref: NbDialogRef<SuccessUpdatePlanDialog>) { }

  navigate() {
    window.location.href = '/pages/home';
  }

  close() {
    window.location.reload();
  }
}
