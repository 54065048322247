import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { from } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthService } from "../../../auth.service";
import { LoginFacade } from "../login.facade";

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ]
})
  
export class LoginComponent { 

  constructor(private authService: AuthService,
              private loginFacade: LoginFacade,
              private router: Router) { }

  touched: boolean = false;
  loading: boolean = false;
  error: boolean = false;
  view: 'login' | 'sso' | 'reset' = 'login';

  submitButtonText: string = 'Log in';
  isPasswordVisible: boolean = false;

  private hasTyped = false;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl(null, [
      Validators.required, 
      Validators.minLength(3)
    ])
  });

  ngOnInit() {
    this.isResetPassword();
    this.loginFacade.parseQueryString();
    this.loginFacade.removeMainLoader();
    this.authService.listenToAuthChanges();
    this.loginFacade.track('view login page');

    this.form.valueChanges.subscribe(() => {
      this.onFirstType();
    });
  }


  singIpWithGoogle() {
    this.handleLoginEvent('google.com');
    this.authService.signInWithGoogleProvider();
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  openSignUp() {
    this.loginFacade.track('click signup on login');
    this.router.navigateByUrl('/sign-up');
  }

  setSSO() {
    this.touched = false;
    this.view = 'sso';
    this.submitButtonText = 'Sign in with SSO';
    this.form.removeControl('password');
    this.form.controls.email.setValidators([
      Validators.required,
      Validators.email,
      Validators.pattern(new RegExp(`^[a-z0-9._%+-]+@(${this.authService.SSO_DOMAINS.join('|')})$`))
    ]);
    this.form.controls.email.updateValueAndValidity();
    this.loginFacade.track('click login with sso');
  }

  setLogIn() {
    this.touched = false;
    this.view = 'login';
    this.submitButtonText = 'Sign in';
    this.form.addControl('password', new FormControl(null, [
      Validators.required, 
      Validators.minLength(3)
    ]));
    this.form.controls.email.setValidators([
      Validators.required,
      Validators.email,
    ]);
    this.form.controls.email.updateValueAndValidity();
  }

  formSubmit() {
    if(this.view === 'sso') return this.ssoSubmit();
    this.touched = true;
    if (this.form.invalid) {
      this.loginFacade.track('failed account login', {
        failure: 'invalid form'
      });
      return
    }
    this.error = false;
    this.loading = true;
    this.handleLoginEvent('password');
    const { email, password } = this.form.value;
    if(this.view === 'reset') return this.resetPassword(password);
    from(this.authService.signInWithEmailAndPassword(email, password))
      .pipe(catchError(() => from(this.authService.signInWithOkta(email, password))))
      .pipe(catchError((error) => {
        this.loginFacade.track('failed account login', {
          failure: error.message
        });
        this.error = true;
        this.loading = false;
        throw error;
      })).subscribe();
  }

  ssoSubmit() {
    this.touched = true;
    if (this.form.invalid) return;
    this.error = false;
    this.loading = true;
    this.handleLoginEvent('saml');
    const { email } = this.form.value;
    const domain =  email.split('@')[1];
    from(this.authService.signInWithSAMLProvider(domain))
    .pipe(catchError((error) => {
      this.error = true;
      this.loading = false;
      throw error;
    })).subscribe();
  }

  handleForgotPassword() {
    this.loginFacade.track('click login forgot password');
    window.location.href = "https://www.storydoc.com/reset-password";
  }

  onFirstType() {
    if (this.hasTyped) return;
    this.hasTyped = true;
    this.loginFacade.track('click login add details');
  }

  handleLoginEvent(type: string) {
    this.loginFacade.track('click login', {
      'user sign up type': type
    });
  }



  // ========== RESET PASSWORD ==========
  isResetPassword() {
    const mode = this.authService.getParameterByName('mode');
    const actionCode = this.authService.getParameterByName('oobCode');
    const continueUrl = this.authService.getParameterByName('continueUrl');
    const lang = this.authService.getParameterByName('lang') || 'en';
    if (mode === 'resetPassword') {
      from(this.authService.verifyPasswordResetCode(actionCode)).pipe(
        catchError(() => {
          window.location.href = "https://www.storydoc.com/reset-password?text=Please%20reset%20your%20password.";
          throw new Error('Invalid reset code');
        })
      ).subscribe((email) => {
        this.view = 'reset';
        this.submitButtonText = 'Reset password';
        this.form.get('email').setValue(email);
      })
    }
  }

  resetPassword(password: string) {
    const code = this.authService.getParameterByName('oobCode');
    if (!code) return this.view = 'login';
    
    const { email } = this.form.value;
    from(this.authService.confirmPasswordReset(code, password).then(() => this.authService.signInWithEmailAndPassword(email, password)))
      .pipe(catchError((error) => {
        this.loading = false;
        this.error = true;
        throw error;
      }))
      .subscribe();
  }
  // ========== RESET PASSWORD ==========


  ngOnDestroy() {
    this.authService.unsubscribeFromAuthStateListener();

  }

}