import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { Router } from "@angular/router";
import { UpdateBillingDetailsDialogComponent } from "../../../components/update-billing-details-dialog/update-billing-details-dialog.component";
import { AuthService } from "../../../auth.service";
import { HelpCenterService } from "../../../pages/help-center/help.service";
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: "ngx-update-billing-details-toast",
  templateUrl: "./update-billing-details-toast.component.html",
  styleUrls: ["./update-billing-details-toast.component.scss"],
})
export class UpdateBillingDetailsToastComponent {
  @Input() orgInfo: any;
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  isAdministrator: boolean = false;

  constructor(
    public router: Router,
    private auth: AuthService,
    private helpCenterService: HelpCenterService,
    public analyticsService: AnalyticsService,
    private dialogService: NbDialogService
  ) {
    this.auth
      .isAdministrator$()
      .subscribe((res) => (this.isAdministrator = res));

    this.track('view update billing toast')
  }

  track(event: string, params = {}) {
    this.analyticsService.track(event, {
      'user is administrator': this.isAdministrator,
      'user status': 'past_due',
      ...params,
    });
  }

  onContactUs() {
    this.track('click contact us')
    const data = {
      topic: 'Billing and plans',
      subject: '',
      messagePlaceholder: ''
    }
    this.helpCenterService.setFormFieldsData(data)
    this.router.navigateByUrl("/pages/help");
    this.onClose.emit();
  }

  get canUpdateBilling(): boolean {
    return this.isAdministrator && typeof this.orgInfo.paymentsUpdateUrl === "string";
  }

  onUpdateBilling() {
    if (!this.canUpdateBilling) {
      this.onContactUs();
      return;
    }

    this.track('click update billing')
    this.dialogService
      .open(UpdateBillingDetailsDialogComponent, {
        context: {
          orgInfo: this.orgInfo,
          trackScreen: 'update billing toast'
        },
      })
      .onClose.subscribe((val) => {
        if (val) this.onClose.emit();
      });
  }

  close() {
    this.track('click close update billing toast')
    this.onClose.emit();
  }
}
