import {Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-getting-start-dialog',
  templateUrl: './getting-start-dialog.component.html',
  styleUrls: ['./getting-start-dialog.component.scss']
})
export class GettingStartDialogComponent {

  constructor(protected ref: NbDialogRef<GettingStartDialogComponent>,
              private sanitizer: DomSanitizer) { }

  @Input() url: string
  @Input() type: string = 'iframe';
  @Input() ratio: string = '56.25';
  sanitizedURL: SafeResourceUrl;
  @ViewChild('video', { static: true }) video: ElementRef;

  ngOnInit(): void {
    this.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.play();
  }

  close(): void {
    this.ref.close();
  }

  play() {
    if (!this.video) return;
    this.video.nativeElement.load();
    this.video.nativeElement.play();
  }
}
