import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { catchError, flatMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { RenderingService } from '../../services/rendering.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AuthService } from '../../auth.service';

import Cookies from 'js-cookie'



@Component({
  selector: 'ngx-upload-presentation-dialog',
  templateUrl: 'upload-presentation-dialog.component.html',
  styleUrls: ['upload-presentation-dialog.component.scss'],
})
export class UploadPresentationDialog {

  @Input() orgInfo;

  uploadPresentationData = {};
  sending: boolean = false;
  presentationFileInput: any = {};
  user;
  presentationSent: boolean = false;
  errorMessage: string = 'Couln\'t send your presentation';
  presentationError: boolean = false;

  constructor(public auth: AuthService,
              private analyticsService: AnalyticsService,
              protected ref: NbDialogRef<UploadPresentationDialog>,
              private renderingService: RenderingService,
              ) {}

  track(event, params = {}) {
    this.analyticsService.track(event, {
      'screen': 'upload-presentation-dialog',
      ...params
    });
  }

  ngOnInit() {
    this.auth.getUser$().subscribe(user => this.user = user);
    const isDialogShowed = Cookies.get('uploadPresentation')
    if(isDialogShowed !== 'false') Cookies.set('uploadPresentation', true, { expires: 7 })
  }

  sendPresentationToSpreadsheets() {
    this.track('click upload presentation to spreadsheet')
    this.sending = true;
    this.uploadPresentationData["professional-email"] = this.user.sub;
    this.uploadPresentationData["company-name"] = this.orgInfo.title;
    this.uploadPresentationData["org-id"] = this.orgInfo._id;

    // SUBMIT DATA TO SPREADSHEET + GOOGLE DRIVE
    const formUrl = "https://script.google.com/macros/s/AKfycbx4NATB3ax-gyTtkH51_Sg6lmajkYGh9ZldwipZqFzs7gYTswXBAiOOq3mNQNs_J5c/exec";
    const driveUrl = "https://script.google.com/macros/s/AKfycbyqspLLOXLIEPD6TbmeCpOgPC4CF9sDT0oyXvLTd4Mi1dVX4qkaEaVXyJpDkA7mji3P/exec";

    const file = this.presentationFileInput;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);

    fr.onload = (f: any) => {

      const qs = new URLSearchParams({filename: file.name, mimeType: file.type});
      this.renderingService.makePostRequest(`${driveUrl}?${qs}`, JSON.stringify([...new Int8Array(<ArrayBuffer>f.target.result)]))
      .pipe(flatMap((e: any) => {
        this.uploadPresentationData["deck-link"] = e.fileUrl

        const formData = new FormData();
        Object.keys(this.uploadPresentationData).forEach(key => formData.append(key, this.uploadPresentationData[key]));

        return this.renderingService.makePostRequest(formUrl, formData);

      }))
      .pipe(
        catchError((err: any) : Observable<any> => {
          console.log("Error:", err)
          this.track('failed upload presentation to spreadsheet');
          this.sending = false;
          this.presentationError = true;
          this.errorMessage = 'Couln\'t sent your presentation. Please, try again.';
          return throwError(err)
        })
      )
      .subscribe(response => {
        this.sending = false;
        this.presentationSent = true;
        this.track('success upload presentation to spreadsheet');
        console.log('Success!', response)
      })

    }
  }
  presentationChanged() {
    const presentationFile:any = document.getElementById('presentationPicker');
    if(!presentationFile.files[0]) return;

    this.presentationFileInput = presentationFile.files[0];
    if(!this.isValidPresentationSize(this.presentationFileInput)) {
      this.presentationError = true;
      this.errorMessage = 'Max presentation size is 10MB';
    }
  }

  isValidPresentationSize(file: { size: number; }) {
    const maxSize = 10 * 1024 * 1024;
    return file.size < maxSize ? true : false;
  }

  clearUploadInput() {
    this.presentationFileInput = {};
    this.presentationError = false;
  }
  closeSuccessDialog(event) {
    if(event) this.track('click book a meeting')
    else this.track('close book a meeting')
    this.close()
  }
  close() {
    this.ref.close();
  }
}
