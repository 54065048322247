import { Component, Input } from "@angular/core";

@Component({
  selector: 'ngx-active-coupon-label',
  templateUrl: './active-coupon-label.component.html',
  styleUrls: [ './active-coupon-label.component.scss' ],
})

export class ActiveCouponLabelComponent { 

  showLabel: boolean = false;
  @Input() noLabelOnInit: string;

  ngOnInit() {
    if(this.noLabelOnInit) return;
    this.showLabel = true;
    setTimeout(() => this.showLabel = false, 5000)
  }
  
}