import { Component } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { AssetsDeploymentService } from "../../services/assets-deployment.service";
import { ToasterService } from "../../services/toaster.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Component({
    selector: 'ngx-admin-data-publish',
    templateUrl: 'admin-data-publish-dialog.component.html',
    styleUrls: [ 'admin-data-publish-dialog.component.scss' ],
})
export class AdminDataPublishDialogComponent { 
    constructor(protected ref: NbDialogRef<AdminDataPublishDialogComponent>,
        private assetsDeploymentService: AssetsDeploymentService,
        private toasterService: ToasterService
    ) { }
    
    close() {
        this.ref.close();
    }

    genericLoading$ = new BehaviorSubject<boolean>(false);
    id: string = '';

    genericPublish() {
        if (!this.id) return;
        this.genericLoading$.next(true);
        this.assetsDeploymentService.publishNotionPage(this.id)
        .pipe(
            catchError((err: any) : Observable<any> => {
                this.toasterService.showToast('danger', 'Failed publish Notion page', '');
                this.genericLoading$.next(false);
                return throwError(err);
            })
        ).subscribe((res: { status: string }) => {
            if (res.status === 'failed') {
                this.toasterService.showToast('danger', 'Failed publish Notion page', '');
            } else {
                this.toasterService.showToast('success', 'Page published successfully');
                this.close();
            }
            this.genericLoading$.next(false);
        })
    }

}
