import { Industry } from "../wizard.interface";

export const industries: Industry[] = [
  {
    property: "Beauty and Self-care ",
    order: 43,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/b8e63fb2-b381-43b4-aa7a-dbf264dd745d",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/2dbaf384-a429-4a3c-a04f-375d3baea963",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/699de0ca-7d66-470e-a0b0-e500daa096fb",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/47bd9704-3f92-4a49-937b-8d68f0bbfe95",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/26c7d482-7de6-44b5-a366-a928305c4c00",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/ab2499c6-355b-492e-9fb5-df9127a82197",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ecdab143-7a02-4b0c-af9f-f37c885553e2",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/8b0d8db3-24c0-4dc5-a59b-5447c63705fe",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/96c2d8d3-2f3e-44f9-adbc-edfcb08d169f.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/bad7954c-2c2e-4c3d-8d27-f33e28ea4e96.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/c649057e-599e-4fb3-b4d1-55019f99a795.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/d6f21675-48f7-40a8-a4ab-73ef43d43b06.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/e842f4a6-a3eb-4f24-bbc8-db861e6fb323.mp4",
    synonyms: "Cosmetics, selfcare, self care, spa",
  },
  {
    property: "Mental Health and Psychological Wellness",
    order: 42,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/d87bc28e-fe94-419b-a686-22b51bf1f48c",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/945b37d0-f8d1-4e82-ab4e-fb552a450084",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/3bd7e420-e67e-4d81-9dc8-228e2bf087d2",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/ccddf571-b844-48c7-80d8-7ab8c9ee3a11",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/ef2eb49a-c8f3-44f2-be4b-e7d71fa04d30",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/0f2e2e66-ee52-451e-96db-2dc511ab6ecc",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/1db9d96c-0af4-4f16-b907-431c8bcd9bd8",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/8a280f20-c5eb-456a-bfd1-87b9afc65d26",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/a04b575f-cfde-4512-8017-64b74b6687d9.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/ca0e1753-22cd-4b3b-9d5d-bee20150a001.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/2299bebf-ea82-46d0-9f28-85393df66ff2.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/890238cd-47f7-49df-82ba-469d813220d5.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/4f81466d-20dd-4a36-b930-4df1d06f5a7d.mp4",
    synonyms: "",
  },
  {
    property: "Gaming and Interactive Entertainment",
    order: 41,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/71ec4764-c716-466a-bbcd-f47cd25aa05e",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/4038d4bc-7d2a-4945-b1db-119d6f3bc7fd",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/501c9abd-0a5a-443f-a8c7-8304a93889af",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/bc1356b0-9d17-4e3b-ae93-0f6d52e5674a",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/7fded268-c57d-4319-a190-6b60ee188bff",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/8aef5291-087c-49af-a5fc-1d5f00e1b5de",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/70612929-7201-4e46-b4ea-b19ed5db14e1",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/3b7c0c8c-2fdc-44d7-b334-6f909b674733",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/a1156bf5-a810-4818-bb05-77e5be144c13.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/547a0e40-0d31-4b90-80e0-495452a79773.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/7b597af7-a689-481a-a551-ced01a6ecd56.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/cd24adfc-3809-4adb-8ee1-eeff9a13bbd8.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/e07abc10-5fea-45e1-8b23-63cd9dadb2d4.mp4",
    synonyms: "",
  },
  {
    property: "Office work",
    order: 39,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/3ce91b30-4701-433f-84ec-b3e450b1d02c",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/e98747d5-8b76-4cb0-8b5b-276da4b620b7",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/e4df3351-85de-4961-9a61-377519bb61af",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/211b455f-7bfe-4bad-b8c9-3e7784ebf325",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/f99d4271-1151-4d6f-baf0-ba20bbc30d75",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/62c3485d-7822-4145-bd5d-a7ae96bd04f4",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4936dec9-4c7a-411e-aaab-ffa1e1540886",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/a2a8e25d-76a4-4822-a490-834494ca250b",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f8f11c1e-ce11-4fa8-ac7c-81b2915d58b9.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/8209e429-86b5-479a-b990-226b1fd19e18.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/264689b1-6333-4954-b666-173dff54038c.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4e79424c-7aea-40be-b859-d5daa06faf1d.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/789f773c-1144-4800-a0d2-7dece994792a.mp4",
    synonyms: "",
  },
  {
    property: "Cleaning Services and Housekeeping",
    order: 37,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ab6f55f7-6fa5-4ac7-831a-a9123dc6436a",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/cd2996ed-bd5d-4ccc-9343-e9f5ce1f7e2d",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/b2eb7557-cadf-4649-9b23-f8e26d8f9f90",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/89be9422-f433-4b37-b5c2-cf2624ed0fca",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/7d5ece9d-7370-44b8-8b5d-c625aec192c1",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/8e578886-f458-4a84-b42e-312d485f2953",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/2f3dc5ac-4811-4ab7-80e1-d09decc38aa6",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ad62c346-6868-4279-8455-43ca86d7fcf9",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/fefee589-1e54-48d4-bd20-b746381645a8.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/d4f6be4d-9cd5-4ca3-af1f-c0a54a441e67.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/e57cdf7b-f572-4745-ac7b-1a82ead7490c.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/c81811c6-e8b9-48c7-8c20-89808398aef0.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/7f592411-937b-43a0-a130-9035821aebae.mp4",
    synonyms: "Cleaning, Commercial cleaning",
  },
  {
    property: "Mindfulness and Inner balance",
    order: 40,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/98f4db9c-2334-4453-8a38-57b3a5112217",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/f4ace464-789a-4916-92e9-816d5e4447af",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/2d26d26b-6095-4bb9-b453-0570b4cc779a",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/9ac81eaf-c135-487e-9dfd-a9a740df5e21",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/dc82475e-57e3-4932-86d9-96329d367ea2",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/3344838e-2f03-4ba7-956c-194cebd4d4b3",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/259dc4da-ec28-45c2-8597-f3b08e0fa625",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/06ff7389-451f-494b-956d-71d2497122a8",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4506040e-5b45-44b8-8263-aa6407dd7e67.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/2bc15a69-056a-4db1-b02f-fc8ccbc25f7d.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/6a5b0da2-e5e9-4f2d-9f5b-a4d7c200ddb1.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/642e0889-998e-47ad-8a0b-47496d4fdf45.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/e1f18790-b2d8-4237-84ef-4aab68a697aa.mp4",
    synonyms: "Spirituality, meditation, yoga",
  },
  {
    property: "Social Media and Digital Networking",
    order: 38,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4f09c5de-cbda-46c1-ac9d-e0bc61bc4fab",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/dff5724a-bea5-4e03-ab6e-63249a7daa59",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/8ca2e02b-279c-4f97-99ed-10fabf24b52e",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/eea91649-93e1-4f8a-b5c4-c5f94bf9a519",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/0b40efbe-9c47-4a97-a061-6aaa875f97e8",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/53e00a21-93bf-4bd4-81bf-d6b4182e90e3",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/2a78252a-0416-4999-bce5-8265abf15250",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/31a1da25-819c-4776-bc11-bb2ed45250fd",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/b2fc84dc-37c7-4041-b067-9a996ed5ab1e.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/dac558c1-5b68-4ce2-b182-66a5f3cad8bf.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/0900daaa-c262-4284-b744-a814cbafa77d.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/72a5ec70-ea23-4e5b-b133-18fa3ec20f8b.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/e495b601-5b09-4f10-9b84-e86725dfa7ad.mp4",
    synonyms: "SM",
  },
  {
    property: "Musicians and Performers",
    order: 36,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ba1e2127-ad2f-4d1a-9dd8-6fe728371c5f",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/c34f9eb8-10e7-4a32-998b-ccc8a995e014",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/6d91e9f2-7d19-4f81-9562-86a568dbce8d",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/e211d3da-614a-41da-9ed0-dc9d88573697",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/13161439-8aae-4186-b545-abbe387e5ce5",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/2b94e193-c63f-445e-9f7e-c956e5790d43",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/846a6bb0-4cec-42a5-9de1-e44275bcf566",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/9817177d-0f9b-4365-aa77-b18ea591213d",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ba6a9b27-f3b9-4a06-bf4d-92d78de140b4.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/1d179b18-2609-496c-8bb6-920b6b721594.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/b040194c-3c60-4d13-a1f6-697b61f32edc.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/d1a03314-4467-485b-a0a3-ff84a0df691d.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/8aa0aea1-1de3-47f2-80c8-b2c58bc99c09.mp4",
    synonyms: "Music",
  },
  {
    property: "Food Production and Manufacturing",
    order: 27,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/0e72a6b5-4511-4c9a-81d8-18916075ba82",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/c1d40205-8722-4a1b-be45-41f8714a51c9",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/3466f608-8566-46c8-b415-b35931c3a224",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/351fd7e7-a41b-45d2-ade4-283e3d0af7e4",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/d077b80c-7073-427a-9c0f-a0efcb3d17d7",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/716f188d-c936-4d2e-9edd-f222e4217c1b",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/c402deaa-401f-4a65-9d61-87a5520553dc",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/9a1bd0f2-00ca-451d-aa80-bed46829faf6",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/6bd97db7-7bf5-4cec-af03-9a492452402b.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/de8efc2d-0819-490b-9030-3b0f627060b5.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/b1d53111-ee97-4f85-910f-caf12413044e.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/46453717-6773-4214-8851-cf22e9168481.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/4bcc6d2a-7ed3-4c3c-a02a-c6d990f25fd8.mp4",
    synonyms: "Food and Beverage",
  },
  {
    property: "Law Firms and Legal Services",
    order: 34,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/caff9c4c-167c-4111-8ef7-577184f96a9d",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/9abea93d-a752-42a1-8321-9e99010b8d8b",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/a2c731eb-ff8c-4b50-9220-ee3f86564936",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/08b97aeb-9070-4996-a109-95a71551d942",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/e18cb9ed-510e-47e2-a4f7-168046909545",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/aa0aa7cc-c294-4e25-8f92-ca18202010fe",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/2d5dfd43-acc4-46b3-851d-619266091396",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/aac7e21f-4bab-4f48-b610-71bad6a299cb",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/45a2f866-8181-43d1-a993-12b0906763f0.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/9834a73f-16d0-4dd3-8e12-2d5c9fa98ce6.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/bb3228b7-cecb-40e4-9f20-06121c7be715.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/45e5f7bb-adc5-492c-bc37-0546e5e23911.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/846c843a-1b55-4a10-85b7-1324d9ef0bb1.mp4",
    synonyms: "",
  },
  {
    property: "Home and Interior Decor",
    order: 33,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ace89d2f-4b10-405f-9aa0-755fddbe7543",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/604bfce8-1529-4b8c-be0d-70b503aff580",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/b8249de5-4d7d-4a5a-8884-a7d8042c825a",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/b6d6d2b2-6400-4000-8563-c249cf44e940",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/c71f9faa-2d73-45d1-823b-87cb41771e66",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/ab1b9653-568b-4cb7-b075-b8e3daf96b2c",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/9b6824ad-5bb3-458d-bb7c-85e063d693c9",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/d8213b4e-efb2-4373-abcc-597556f27d5e",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/d06b3a8b-ce0d-448a-a501-0f3af8667d8f.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/88b0ed9d-52fe-4d39-b92d-40cb7a7758be.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/e672295e-3f86-4890-bdfd-65dbbc8469ad.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/30fa8f5b-aa7b-4494-aa25-a9b2db99462e.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/b010c56b-073a-4d80-a43f-e042b7d71367.mp4",
    synonyms: "furniture",
  },
  {
    property: "Music Tech and Instruments",
    order: 35,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/efad71f8-f8b2-4b6f-88e3-0004bc6a0a19",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/cf238393-f167-4eac-9aea-2b3f289e3f5f",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/6c33613b-bf4d-4090-bd4b-63ff8076d93c",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/887dbb71-dada-461b-824d-2857f6a3f019",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/ebafd60e-60b5-44fc-b6ce-31f5a4ac539b",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/46ed3a0b-3464-478b-a14b-88a12ee7edbd",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f4f99107-f636-4f90-9dfc-bc536c520e6a",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/c630497b-ac4b-4f4c-99c4-9835f09a4b34",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/51f3b9e5-59ce-4e44-bbc1-543e29fe2425.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/5dd252d6-23e0-49bc-b61f-5cce5e65d344.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/0a43c616-912f-40bb-ab76-1615cccd37ea.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/009f9974-6a1d-4936-af20-42997e0f1550.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/baec4b46-cf57-4894-9031-7de8205f17e6.mp4",
    synonyms: "Music",
  },
  {
    property: "Cyber and Cyber Security",
    order: 32,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/886c60e0-7bb4-45be-98c4-a04947a784b8",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/0cb8074b-3c38-40c0-a882-2353d9165421",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/7301c3a7-7035-4e82-803c-4073e44c03cf",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/0bba9429-a364-4a94-953a-8f8c311944a8",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/65f11cc6-8776-454d-9867-8637388450ad",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/dbab8895-6618-48bd-8fe6-305576c2edd0",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4e9b24ae-5885-4642-8fa7-edfb6da816a3",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/e9711d36-5735-4ba7-bd38-0bf03e247e48",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/21483956-5d66-434c-a447-0c1a4ae4f9e1.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/23341a5e-375f-4ab0-9947-5135eeb57755.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/d4b41df0-e640-4a74-afca-492a2a3ef21d.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/1aacc2a1-9094-41ee-8cd1-57160c5c9afd.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/66c55202-b6d0-4c41-8afd-29151f7fb757.mp4",
    synonyms: "",
  },
  {
    property: "AI",
    order: 31,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/bd35c509-9f59-4892-af01-74b11cb53e18",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/02c609a7-c853-4dc0-95b5-bcee0e57a56e",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/0268e9cc-dae8-4bb3-8b88-9ae90c6b8046",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/982d9b14-0fce-498a-9cef-067b8e63ffbd",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/5fe72675-96bd-4c59-b47b-b18358155fcb",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/5f76b1d9-6d7e-45c9-8876-fc75e51b10d8",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/9655690b-64ad-446c-9e53-b8931213f4dc",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/120629b0-6b9d-47b6-b71f-dd72a00a1dfa",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/705b9e91-c7a8-4b13-a3b4-bf3a2a45bf45.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/80227c73-4d5e-45d0-8c99-3cc73b478368.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/fd280cec-14bc-4d7f-8df0-d2f55bee5827.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/63993a64-35aa-42d7-b982-131796480960.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/410e7644-9486-456b-8aaf-5b369ee5cdba.mp4",
    synonyms: "artificial intelligence ",
  },
  {
    property: "HR and Recruitment",
    order: 30,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/49e2b0c4-a581-41b8-86f7-36e5153d6f5e",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/08847d12-22c6-4d00-b0d9-eafab6381f54",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/2027884d-1dca-45dd-9f7f-3fe40d96261e",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/5a0a8e2f-8288-43b3-95cf-fa88961a7c3f",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/dc4bac84-6d5e-43ff-ba51-b20922997671",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/fae063c5-3728-4e53-81a9-93f8f4d4df7c",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/b110065e-b1ee-49d7-9054-584a06ee18d8",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/878e5219-40ce-4644-a9e9-f56ece6100df",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/fc30f1fe-803a-422a-a5ee-114884604af8.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/9952268e-fb2c-4d43-b938-a9d20d2d9016.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/dc61d357-9777-4212-9b55-c94d8d178d9a.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/8c1a8c55-86e0-4a61-9c9d-46724b8891f6.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/ae23b0b6-d0b9-414a-9a05-3ad96323c2d7.mp4",
    synonyms: "Recruitment",
  },
  {
    property: "Sports and Fitness",
    order: 29,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/30dbb4cd-3f55-4f6a-a2cf-9d2b46a64e8f",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/e54f9ad3-6d6e-46ac-84b4-7b75413ab6b3",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/34c77d89-2cf5-4deb-9933-b44a3fba13ec",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/05682355-e1a2-4842-b9fe-77a0d85a88ec",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/a6902dca-e73d-44ff-9a78-cb2dce592f97",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/2f17d589-ea53-416b-a8c9-8fae94c3fb24",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/90084ace-29be-4241-92a3-4660d6e3c2b0",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/b1e5c3ab-77fe-4d85-b7e0-e6deaea75e6c",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/6c7cddb1-ebd6-47c4-b28b-573a362251ea.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/b2ad5008-ea12-45ff-80e4-22506e3a8776.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/d52af311-3f4b-4e01-aea4-9f4390128d73.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/c65e98e2-3235-47f7-91a1-1df50f78d27d.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/3e761295-2228-438f-bb4f-3071cadcc629.mp4",
    synonyms: "",
  },
  {
    property: "Environmental and Sustainability",
    order: 28,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/54e34fdb-255e-4eec-aa6d-b04ec1b4e334",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/2c4459fb-817a-4a0d-a2da-532d3a7a6f32",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/6c0f5521-7f31-4256-9724-f4dc3f7cdef9",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/fa598961-978a-44d1-8d15-f7a04d4d59f0",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/f9ff3029-123f-4716-bc28-78527c187895",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/884c3dd4-aad4-41dc-affa-7a05d0c111ba",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/1ac55c74-92e8-40a5-a8de-c0197c7071b2",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/212ac788-af49-4f2b-9901-1a0e64f547eb",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/6e700a23-70a4-46ed-ad55-2f095039684d.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/1b8db9eb-3957-49d3-a8d4-0d4ea9a27b5e.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/fdcb3b74-09da-42b7-b924-a54731d812ca.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/34ea422e-b1e3-44f4-97fb-ae6af3f10ea4.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/09f1d05c-c72c-4198-b546-c2d648b95b26.mp4",
    synonyms: "",
  },
  {
    property: "Gastronomy and Dining",
    order: 26,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/066496a6-1d71-44bd-8f05-a5e2fd371228",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/617f9629-15a7-4773-ba67-20cd0ba09ba4",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/e2b7a877-d981-4770-a8b0-90cef092ac7e",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/de6cb001-4b80-464c-8fcb-8c039ee2e4ee",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/9c890510-ba42-4aed-92f3-4905c64e7749",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/a7888bbd-95d6-4c06-9802-4da8b542c4be",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/c12c5df0-f778-42e5-ab13-96d9f45e3f0d",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/83717a5f-f8ec-4341-bd35-4e1d2f9bf835",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/2f9201e6-b1b3-4510-8d77-7f8f2bc7b61f.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/595ef2d8-95ae-473b-9b91-89978cd51d19.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/c425b758-30bd-4912-beb4-8beca702e7f5.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f36bf8e9-8029-4365-87ef-45b89efe10d1.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/66d6b547-0e1b-48f5-89d5-8fba04a67a64.mp4",
    synonyms: "Food and Beverage",
  },
  {
    property: "Apps and Mobile Technology",
    order: 8,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/b82ca840-284a-426c-8893-900fe0307026",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/1e9b3891-5f61-484c-a067-5f3b62927a99",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/47509970-0b25-40d2-ad5a-f2ae3ca40141",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/7d9e0101-e776-42b9-aeaf-81645b33f448",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/b667def7-df22-4095-bb12-e4350d974c09",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/9f3a02d7-691d-4311-bbea-40b320c58e8f",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/280c17d7-5c84-4fe7-b92a-d423186e3d96",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/304432d9-e08e-493a-a5cc-fa45de04caad",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/912742bb-4349-42bd-9116-58d71be9e8d8.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/422dbfa0-c235-4476-8517-4a6e34e678b7.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/52b7fab7-d9d6-4ad9-b040-2c895e599d23.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/fe99ea90-16db-4774-9b05-0fabe40f6040.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/d7b52cd2-199f-44a0-a8df-e3c3e846cfc8.mp4",
    synonyms: "",
  },
  {
    property: "SaaS and Software Tools",
    order: 6,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/18ea39bc-168b-4c45-8aaf-4513c0920c73",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/d51145a4-603c-4ef8-8796-ea16b58fddb9",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/4c5f67f6-6365-4dbf-9a0b-3f33249ad4f6",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/533042bb-7709-4a18-a39b-27c8f33bae7a",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/6b3297a0-f773-455b-ba35-47dd1d44e313",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/2fdbee0e-4aee-4eb0-a1e8-804de73f3114",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/e393a04f-01c0-4459-ba09-808cb717ea2e",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/844f8d5f-d4ca-4af6-9e39-8b4243ad5639",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/43622887-ea87-4240-ad7f-8c2d9745b418.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/e185a665-8911-4542-85a3-01ad1d832b0b.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/3a5f4382-e73a-48f5-b992-5bbfd87c8f01.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/5902e2ca-981e-4cd4-b348-0eaa2cd2d238.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/28281b43-cd8d-42cc-a3ad-495b78d66a32.mp4",
    synonyms: "",
  },
  {
    property: "Digital Marketing and Advertising",
    order: 5,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/0be03e80-dd3c-4e1f-a474-eee0b800fe4d",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/7bc77aea-0ea1-4b60-9514-072b1e1d2fde",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/46eee5f2-d08e-40c2-b08d-b91b8cf8db1e",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/9a5e2bdc-cce1-4f73-9325-b0c3c15b35b3",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/023dddb2-d957-4c05-ad6b-18c47a31e6eb",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/878f9f34-556d-463c-8929-91abb475b2db",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/7220577b-26f3-415d-93b8-8097159db4f2",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/304432d9-e08e-493a-a5cc-fa45de04caad",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f8877463-5e3c-4040-b45b-f1df2519f5fa.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/7e32b673-d024-4b54-840a-ca3227375d2c.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/59896972-aed5-41f6-b51c-a46824005388.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/fc4827ab-531d-44f1-8e89-dc1ef6169b3d.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/8a442689-5379-4f9a-975a-ea46b8f2729c.mp4",
    synonyms: "",
  },
  {
    property: "Travel and Tourism",
    order: 25,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/41f6cf79-db76-450b-80d1-14f4e6de8b5e",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/6a3bdde7-f6ec-49c7-81ef-f22705da928c",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/f249e05b-60cd-4f86-87ff-6e74c03a546b",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/192cd8cd-9f73-48e6-aef3-c3808caf743b",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/f970e518-d2f6-4737-bef2-6eca5a766fb0",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/62e4817d-eda9-4d6d-bb6c-5954242cf4e8",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/97f01b11-e1ec-4cb4-8471-a9546cee74b4",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/378c49ee-ea50-4699-a24f-6ed300b0f087",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/dbf4f35a-adad-4438-8744-29931f8b1adf.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/2d612617-fe69-43a7-918d-9edf276c1acb.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/4b640662-1789-4c62-b209-79b3590a238c.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/87b6f51c-5571-4ae5-bb38-dcaeac865b34.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/20c4308e-8f8e-4e15-baa6-34c3446460e7.mp4",
    synonyms: "",
  },
  {
    property: "Health and Wellness",
    order: 24,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ef844a43-166d-4c96-b33f-9d8786e9df18",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/996a9183-c746-4000-9ee9-196fbaa22cf0",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/cc513472-2935-4fb9-b1c4-fd3522c412cb",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/cf557082-5827-4d81-91db-1ac5ab97dbd7",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/85411beb-7b42-4317-8f20-c674bc3e32b2",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/9a19a00a-fc0c-4560-b723-ea14bff108a2",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/6b364422-a78e-4a00-ab25-85abddf67641",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/e56d593b-54e0-4d01-9e75-f1caa53dd184",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/fb3c1171-6656-461c-9fe6-09b4427710d0.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/3f582a16-f9c7-49a2-ab83-7a5cee03dd82.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/c157a0f3-b189-4d84-97e0-8a61a0d6e484.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/5c763395-2745-4dc2-b13c-126912e91179.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/b8ea82a3-6d9e-43e5-8fd7-8903dc974ec5.mp4",
    synonyms: "",
  },
  {
    property: "Government and Public Sector",
    order: 23,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/0e158bde-b61a-4c01-b88b-1c78d5248f48",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/e8fb4045-b89b-4c97-b972-c8ef6d81f10c",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/a8a57234-f026-4a64-938b-aeb7d7f010ca",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/053a4a99-ec5a-48a9-bf7f-021021b24be5",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/e503af37-7946-4ffd-840d-41647348d190",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/45a180b3-60e2-43a2-9726-e9a172ad4a24",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/e39d8c32-b3d3-4485-8d2e-e07bd19b36e7",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f662e940-0dbb-4dd4-8929-4404b3765b33",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/87c35970-f5d4-4bd3-8b7d-c59e2db19778.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/0328cfe6-ea9d-4be2-ad0e-61ae915052a4.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/e3c29dda-d410-4dfd-9e95-6febce605c86.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/5ddf471b-f787-4f25-a785-0d7bd4c79317.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/c174489a-82a2-4bb1-9130-c9d933a236f0.mp4",
    synonyms: "",
  },
  {
    property: "Non-profit and Social Services",
    order: 22,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/a2df83f0-fed2-4259-934e-ab0ee3017c9a",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/55c9835a-39e4-4848-9292-896fedd8b7e6",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/007686a5-253e-47cf-b090-936177587f8c",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/4955c4cc-946a-40ad-a0bf-6947ec2bc608",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/9c74e68a-fc32-4b3a-a8a0-524ebbec5c40",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/bfa65fc8-80f5-4faa-b7c5-0568d4c81108",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/189f9588-ea8a-47a2-acad-26813898ba70",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/7b95ba93-a480-4929-8dbe-bab091d5ceff",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/105452cd-fe17-404d-9960-d22d726d5817.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/aa849a17-609d-4075-ab96-85007cdf1889.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/9571a645-6be6-4f13-8053-45b0eae6a9bc.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/593ec9d7-3546-422a-b09c-caf6b5f73325.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/192d98e5-83e8-4688-b77c-4cf4e1242c7f.mp4",
    synonyms: "NGO, Charity organization",
  },
  {
    property: "Consulting and Advisory",
    order: 4,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/0489f5e2-ff28-4d6f-b3c8-39c0d3078e88",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/dbeae17b-3bb3-4be1-a0c5-dcf85873e1ab",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/53f1fe31-63b4-49a0-adae-4fb22e59deca",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/670b0b12-0fd0-412c-8f16-51f4707883bd",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/ce403ad7-c85b-4e55-a5b2-bb052bcf120b",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/20b80f84-8d8f-4de8-be7e-0642fa4838cd",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/48ed618c-84b1-49e7-8298-4854945b90f9",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4b77525b-2d2f-4c13-a195-aee1fefe384a",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/91030b7e-bad2-4540-9de5-e89a4a21f35f.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/1d82a368-5412-4c0b-b4d7-5069922d88e0.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/c838cad1-17ad-4e71-9e48-bb436025f055.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/3a5e4edc-cf4f-4d0d-8bc2-ef718d814e75.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/2e81b537-ea6b-4449-ac7b-8f9233b1836b.mp4",
    synonyms: "Business Advisory, Business Consulting",
  },
  {
    property: "Fashion and Apparel",
    order: 21,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/bf7989f3-6bee-470e-9890-e1f80005eb4f",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/4592721f-c023-42ed-8c0d-7fc10161caf5",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/694a2b44-3be2-4bc2-b984-7be5b22d8b0c",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/b8f764f2-084c-424b-ada0-168d4ad63690",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/759f32bc-92fe-410a-9336-e2d59b932555",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/40c59b04-a1e2-4441-a9ef-d2aa95ded2a0",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/b44954d1-47a7-43de-9081-5e901c5020d1",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/8dae7d4a-d99d-46d1-81cb-adf9058221ad",
    video1_group:
      "https://storydoc.mo.cloudinary.net/prd/8011fa118d5708e6/610fe48a-03d5-4d0a-ac7a-433ac4769fdb.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/8a378782-781a-45e1-ae14-78042e4753a0.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/9e21cdd1-9853-4d8e-8308-eb63a4259e33.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/50717054-54a8-4251-bdaa-8e75d83b3b72.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/f07b2d06-ac89-4f78-ac43-b106e77d1c27.mp4",
    synonyms: "",
  },
  {
    property: "Entertainment and Media",
    order: 20,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/57eaa49f-60dd-49ab-adaf-1dafb3b30e22",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/2347b707-8962-4556-963b-37800a59cc9f",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/2de6e89d-92ae-430e-867f-d18486a4c3dd",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/387022a2-4b9a-408c-a2a1-61ea519ad7d4",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/6bc8a6d4-7ae3-41a9-8d9a-2a46fd3f055b",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/959d022d-5175-4225-80fa-9324f6daeb8f",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/b7c9614f-7673-4316-8b44-9369b044dd48",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/dac6692b-23da-4b2d-b993-3f6e8266083c",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/3a6c8f84-6285-4084-8c50-3c5353b735a6.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/7386930c-8cad-48dc-9f13-3651c61b457d.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/43856130-4363-4299-907a-860edd68aaa0.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/72070518-407f-4497-925d-0a945fb1073d.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/24e66584-ae91-4af4-aef0-eaa11af384cc.mp4",
    synonyms: "",
  },
  {
    property: "E-commerce and Retail",
    order: 9,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/c8757658-67c5-4b41-913b-ec35cd247809",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/94ff0d47-3a00-4ae4-8458-01669e85bef0",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/300dd855-839e-4f7d-b0b7-63ca30755692",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/c7e624ac-37e1-4f84-bfcf-69fdaeaa13dc",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/19da0fe6-257b-4ab2-afda-b95f08ccdcd3",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/12cb7ab9-6d24-496a-abd3-02a0c26b4010",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/2687130d-01fb-42ab-85d1-4b9add81ebe5",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/427c6a23-5f77-4067-bd26-63a282a852c6",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4a15f7c6-34a4-410d-b0b9-08bb2c8183a5.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/123baaf1-924d-4513-b625-b11f5d8baa4b.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/193591ca-78a8-4830-b792-3c2245e172d3.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/7b90f90d-7ea4-4c75-8ddf-748f8423083a.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/0d86331d-6c0c-46a5-b5dc-60604657bef4.mp4",
    synonyms: "Retail, ecommerce",
  },
  {
    property: "Telecommunications and Networking",
    order: 19,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/76cbeba7-f574-4d41-8541-35e3d1314086",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/3e30e0ce-18dd-468c-b1ff-aac426d1b017",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/afedae0c-b37e-4619-9e1a-994f8e47cb67",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/ae995924-8aad-436c-82a3-fada31b63d90",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/e6111ac3-61e0-43f8-a574-65a3ff9da1e0",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/4f862bc0-eca9-4800-89f7-b7cacd542138",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/b1919d43-2e3a-42b4-a9c8-29f9a2359651",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/79d83db0-42a0-41e9-9754-2632af17ca0b",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/e9f208e7-4fad-46d8-8494-c21d453cc66e.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/e625645c-b0a4-458d-81a9-637de6b324d1.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/ba3f6f99-66f0-4f56-92fc-06b5ad873d86.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/74c27d92-3e4f-483f-a41a-c4e8f35ab6e4.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/c99909da-d7a1-4cac-b03e-e0d9094e7cd5.mp4",
    synonyms: "",
  },
  {
    property: "Logistics and Distribution",
    order: 18,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4059298c-ed98-4c16-a5ad-f0661de6b56e",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/b35632ab-88bf-40c4-a356-d77d28712a57",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/f7b870d0-6d41-4f6c-a581-1560f6528717",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/20174a11-ae77-49dd-b52e-843e791d8344",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/13da4401-bfce-4f59-9593-2d7a5aec81c7",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/9d5f4990-ac35-4d6d-9c7a-22b07f38431c",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/70bc6e00-ee31-430d-839a-0e0bf084fb3c",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/fbcc1261-853b-43b3-b4b6-67e13df94bf4",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/a8d4e86f-3d23-44d2-af9c-5c77280239bf.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/5dfec25a-c48a-4f9e-926a-7e04a2bf6b2b.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/484a6b9d-6ba4-405f-8cdf-12d9a83bd851.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/a0e72909-12a0-40ce-a32c-0be2d2604da8.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/cc719693-9ffb-45b8-936e-c019c1fd1698.mp4",
    synonyms: "",
  },
  {
    property: "Energy and Utilities",
    order: 17,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/cdcee011-a6b0-49b6-9d41-1f8be1deb018",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/6636c3ad-ab0f-45e5-b0e6-16023a8921e5",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/ea68a418-a913-471e-9339-1399683bf29d",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/4cfefe5c-5be5-4b42-940a-28a8039a3d55",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/2ec423c8-1e8d-4aba-ace3-6de51ff533d6",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/ffaf4ba0-eed0-4b00-b303-4bb550e2fd91",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/d0cf368a-6a86-4a60-9b16-467e0f615152",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4a9c32a6-eb93-42a9-84b0-6d9b560c955f",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ed664fd2-d60a-4b57-90c9-4e8c5becfd08.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/5528ab65-4ad9-4b6d-bdfc-3a2909c655f5.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/7b1cae1a-3007-41db-a80b-da462df3087f.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/d43369df-2109-4d4c-8f01-b8bc31cfab57.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/4fa53b20-218b-4355-b0c1-fe1cf9f8b6e1.mp4",
    synonyms: "",
  },
  {
    property: "Construction and Infrastructure",
    order: 16,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f5350ea0-614e-435a-9c0a-bcd81e705010",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/7bb0a3b6-83b7-40f9-851e-455c97808f1c",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/95658a8a-dc98-4dff-bd32-6580c7a817cc",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/aa5c5e19-b5aa-4b0f-ba2e-72cc615178ee",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/da2ca463-3087-42ac-9170-0798a85c60dc",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/e0ff0da6-7321-4899-974d-6d7685584e6f",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/c9f29d84-8c3b-4a16-8b31-cd46ea46b3b4",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ce8ed8e1-2874-470f-b348-9677940efd0f",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/2386d0c8-f510-4fc2-932d-0ed090701416.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/02036371-957a-4e6e-8e8c-28c1a79cbdd9.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/957eb68a-d93a-4cc7-96ba-bdca2bd9cff2.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/81d32b97-43ac-48dc-a94f-56d688daecf2.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/225b5fb6-4881-4ad9-98f1-9d7a79ece6cd.mp4",
    synonyms: "",
  },
  {
    property: "Tourism and Hospitality",
    order: 15,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/03a35342-e8e3-4272-8149-d135805cecc0",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/5dd9a2d8-f3bf-4519-bf70-c25afb87b50e",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/9a15a6bd-c982-47c6-9f35-ed5947e0dc81",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/34de1238-0267-4464-bf08-9fc8d4ad8ce6",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/cd2a12fa-7b9a-4f9f-af06-40aa2c801c4c",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/e8ea1844-c08e-4f62-8c06-d08512ae371a",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4869de7d-3239-4d42-bf5c-79c0719a9eb4",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/5892166c-8eff-413d-a374-05b112ee11ce",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/214c7394-7187-4720-b328-4f109394feb2.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/681151b8-4536-44f5-9a78-720e1ef55893.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/d44d2f67-d48b-436f-b109-29e868b7dc5c.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/e35a64e8-4d27-4e03-97a0-b0843a9258f9.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/ced4122e-4ca7-4caa-b26d-cd14ab11a462.mp4",
    synonyms: "",
  },
  {
    property: "Manufacturing and Engineering",
    order: 13,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/258a0923-9a1f-4e17-bb07-9e77cc9bec72",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/80a3e699-9160-4d23-b3a4-707b375030a0",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/4c3becfe-2e6b-4deb-a23f-cf09e01fe4a1",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/a65b58ba-679e-4523-923b-46ce8971fdbd",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/69ccea44-7955-4db8-ae2f-220b0d83a2cb",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/856b8239-38ff-4a01-b7c9-4ad871308359",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ebecd7d7-fe5a-4347-89fb-f92b329ce396",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/18b8d0dd-fe63-430b-bb94-0f3f73e315ec",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/fb4286b1-ddfc-4de5-9444-8cf398b51fde.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/2ed82460-eee5-4e03-8b3a-b5a770dc7247.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/86fcac7b-bc80-4e37-b67f-17b2da8dae67.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/ac2fd1fe-836d-4177-930e-541dcecfaa18.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/36a876f6-b0bb-4b1f-9ab7-00d88cbf7e4b.mp4",
    synonyms: "",
  },
  {
    property: "Agriculture and Farming",
    order: 11,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/1dcd7b1f-7794-4e7d-a86d-0faab980b5ed",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/01cfbbe2-9ce1-47f0-93a5-1bb2a4c83ed4",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/d273947a-d89c-46fb-9cbb-0afbc56e8427",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/42853b75-a1bb-4016-a17f-65806b8a4dcb",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/62ee48c0-b719-43b0-90c5-b07de603977f",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/3757a4ae-e53f-4beb-98ef-22963ce6002b",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/df7330dd-6fec-4000-a8f6-3feeb3e1002d",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/fcc068bb-0fd0-4a82-97cb-7dd1fcce8d26",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/10d3d6e5-9890-4eec-bd73-a801a53b4080.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/228a6c44-c1ce-49f6-a64f-c68f20096e91.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/b1819420-cad9-48a1-8843-e92898427443.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/d63d4555-c06c-4ce9-ae3f-6edbc0862428.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/f74f7418-1354-4c17-95d7-20f96bd9fa6c.mp4",
    synonyms: "",
  },
  {
    property: "Real Estate and Property Management",
    order: 10,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f0eff90c-7e9b-4ebc-87f5-1be52abfdcf2",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/424fa952-05b5-4ce2-a309-caff595f5fbf",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/e8dd8dbd-548d-45c8-bc68-58f4796e3f16",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/baf6219d-e62c-4d11-94c9-d12a77c303d7",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/683480db-11ac-41b0-9ce0-5e2dd2679f1b",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/0e7c2964-5d7a-4e70-9083-793ea9c692b1",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/a664cdca-9259-459a-b541-98b72c2c02ff",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/1bfa8ca4-2b84-42d9-945c-26bc993103c2",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/9fed651e-9a11-402b-a9b0-2881def85622.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/1ebce84d-0fee-4316-b947-a522b22e1394.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/c6ed0499-b582-4ed6-9240-4432352c0872.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/9f8a6841-f9a8-450f-8f25-11895fe90de0.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/01c47f30-a9a3-4700-a629-bc34e586c91e.mp4",
    synonyms: "",
  },
  {
    property: "Technology and Innovation",
    order: 2,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/325bf2e6-ba84-42f8-841a-2a8c01e639b5",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/462b5fca-b999-4db2-95a8-f80e97f9ac0e",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/ce97d2f3-aec6-468c-8ba9-b70e8d1bb6fd",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/7d9e0101-e776-42b9-aeaf-81645b33f448",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/ec59b148-047e-47a1-87da-319b5f173ffa",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/b667def7-df22-4095-bb12-e4350d974c09",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/003940fa-065b-4b95-a8db-f17593d262dd",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/c754e57a-a65a-4246-83ef-be6f262e1ddf",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f8983450-9e2f-4d4a-a6fa-a39ed0732246.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/0cab983c-bdef-4e85-a0b9-e5d5392f6654.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/ba2dd56d-66fb-41d5-9c26-c486a6782ccb.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f8983450-9e2f-4d4a-a6fa-a39ed0732246.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/0279e4c8-3464-4d6a-8793-2459ddc9fb31.mp4",
    synonyms: "IT",
  },
  {
    property: "Education and Training",
    order: 12,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f90f9dbb-aff1-45e4-ab2e-e501b61306d9",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/4f32eb1c-5b59-40e1-af6a-debece632cf2",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/ea199b15-27f2-4835-8231-5c11b4da0473",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/bd936810-a84c-4c97-be63-9b1feebade46",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/3b50b163-0df9-4b1b-a232-aab070733095",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/7f29fd53-3a9f-4a94-8d0f-b5e0e9a9cc74",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/d1bab6ed-9387-45d6-bfb7-837e014faa8d",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/92943a18-8a3f-4549-86d6-b939f408cffe",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/1d8071df-85c1-4757-9697-ea7a95fe35f7.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/699cd71e-c967-4c0c-84c4-2cea9053cf21.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/3b2f96e5-0045-4a05-b4cb-d7b943e7c9d5.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/b461c55e-9879-4acc-964f-3ac6055bc8e6.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/210c5f7c-fa2e-41cd-b940-b74040a3828b.mp4",
    synonyms: "student, university, college",
  },
  {
    property: "Automotive and Transportation",
    order: 14,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/dd5fa502-3b1e-45a0-bada-fa3c4ecdefff",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/1b946141-3be2-49a5-9dc1-7820d2ec2c69",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/e7fd37d5-3dbe-4372-9d43-d67c02e1c753",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/f00b2b9f-71a4-4710-9191-09e05ff940c4",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/45bf3213-f277-4081-939e-7aba28916f8c",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/fc6d5c59-59b1-4c1b-896c-f05039fed6df",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/3a60e7ae-c0db-4558-b078-ff22511ac288",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/57fa59ab-e75c-496f-9cfd-cf927de31ade",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/6655d975-4ff6-4869-b284-a133633b1028.mp4#t=0.1",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/9080e74b-3791-4a3b-b9e0-65d908a367fb.mp4#t=0.1",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/372d2876-7d61-4691-bc11-a1d2bbb046a5.mp4#t=0.1",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/4d40318d-a315-4f96-9f92-783a4708d904.mp4#t=0.1",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/335a397c-45bc-43bd-aef3-bcb94543283d.mp4",
    synonyms: "",
  },
  {
    property: "Finance and Banking",
    order: 3,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/6ef88fd6-4026-4907-8280-78ee1ffd0ebf",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/cf968b80-3c04-4afd-8034-16b5d3ee361a",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/62fe99d5-b3a4-48c6-b080-1d9dbf99014c",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/93a32dfa-f471-4ca8-88bb-2970fdf8361e",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/0a7dd683-87a2-4ca8-b256-bb5b95af8629",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/6ee5e682-9886-4f09-866e-122395e298cc",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/22b14fa1-64c7-4f55-9d59-78fec511d374",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/086d0271-2fef-4399-96ac-e0f5c8141918",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/8ac028d1-5b59-4772-ae62-11e2de425134.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/df4bf8dd-f958-4d81-8071-a257ae5318e4.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/0a11ad2f-2100-4b95-912e-a8ff2d9bcf4e.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/220399d9-9fe0-4309-acb5-58708e002ba0.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/f9fcd5ef-3ccd-469e-882d-121aa2cb14c6.mp4",
    synonyms: "fintech",
  },
  {
    property: "Other",
    order: 1,
    image1_group: "no change",
    image2_female: "no change",
    image3_male: "no change",
    image4_atmosphere1: "no change",
    image5_atmosphere2: "no change",
    image6_atmosphere3: "no change",
    image7_group: "no change",
    image8_group: "no change",
    video1_group: "no change",
    video2_atmosphere1: "no change",
    video3_atmosphere2: "no change",
    video4_group: "no change",
    video5_atmosphere: "no change",
    synonyms: "",
  },
  {
    property: "Medical and Healthcare",
    order: 7,
    image1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/f92c4a66-9a7d-42c5-8453-1b3a586c9ae3",
    image2_female:
      "https://assets.storydoc.com/8011fa118d5708e6/1583d174-daae-4688-8db4-32dc6a4179cd",
    image3_male:
      "https://assets.storydoc.com/8011fa118d5708e6/3a435dd8-cc21-4300-92d0-3eab38c3df1a",
    image4_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/6edb297a-831e-40c7-a2f3-89c8620e905e",
    image5_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/a31c698f-87eb-4c2b-b5f3-79c91fe42a76",
    image6_atmosphere3:
      "https://assets.storydoc.com/8011fa118d5708e6/c0b56888-8072-4aec-a961-f3c412a0041f",
    image7_group:
      "https://assets.storydoc.com/8011fa118d5708e6/3319953a-0301-47d9-a5b9-eddc7bc51379",
    image8_group:
      "https://assets.storydoc.com/8011fa118d5708e6/aac465db-fe0c-4ecd-8976-c462851790b6",
    video1_group:
      "https://assets.storydoc.com/8011fa118d5708e6/07af7b14-54b2-4d01-ba00-142eb2afc104.mp4",
    video2_atmosphere1:
      "https://assets.storydoc.com/8011fa118d5708e6/819f3873-85e5-485f-b609-3f0c86062b72.mp4",
    video3_atmosphere2:
      "https://assets.storydoc.com/8011fa118d5708e6/7fb344db-9ced-41ed-9239-2b41a961da52.mp4",
    video4_group:
      "https://assets.storydoc.com/8011fa118d5708e6/7313f4a3-2a23-491d-b63d-415e6493cea7.mp4",
    video5_atmosphere:
      "https://assets.storydoc.com/8011fa118d5708e6/b565bcf5-cac4-4313-9a7b-f6ef485d1e2f.mp4",
    synonyms: "",
  },
];
