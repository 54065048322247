import { Component } from "@angular/core";
import { WorkspaceFacade } from "../workspace.facade";
import { WorkspaceService } from "../workspace.service";
import { catchError, filter, take } from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
    selector: 'ngx-workspace-email-confirmation',
    templateUrl: 'workspace-email-confirmation.component.html',
    styleUrls: [ 'workspace-email-confirmation.component.scss' ],
})
export class WorkspaceEmailConfirmationComponent {

    constructor(private workspaceFacade: WorkspaceFacade,
                private workspaceService: WorkspaceService) {}


    joinWorkspaceOverlayStatus$ = this.workspaceFacade.joinWorkspaceOverlayStatus$;
    loading: boolean = false;

    ngAfterViewInit() {
        this.joinWorkspaceOverlayStatus$.pipe(filter(res => res !== undefined), take(1)).subscribe(res => {
            if (res === 'VALIDATING') this.play();
        })
    }

    resendMigrationEmail() {
        this.loading = true;
        this.workspaceFacade.track('click resend verification email');
        this.workspaceService.resendWorkspaceMigrationEmail()
            .pipe(catchError(err => {
                this.workspaceFacade.track('failed resend verification email', {'failure message': err.message});
                this.workspaceFacade.showToast('danger', 'Failed to resend email', 'Try again later');
                this.loading = false;
                return throwError(err)
            }))
            .subscribe(() => {
                this.loading = false;
                this.workspaceFacade.track('success resend verification email');
                this.workspaceFacade.showToast('success', 'Success resend email');
            })
    }

    play() {
        const video = document.getElementById('validation-video');
        if (!video) return;
        (video as HTMLVideoElement).load();
        (video as HTMLVideoElement).play();
    }
}