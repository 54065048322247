import { Component } from "@angular/core";
import * as moment from "moment-timezone";
import { AnalyticsService } from "../../../services/analytics.service";
import { environment } from "../../../../environments/environment";
import { Subject, combineLatest } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { delay, filter, take, takeUntil } from "rxjs/operators";
import { AccountsService } from "../../../services/accounts.service";
import { PagesService } from "../../../services/pages.service";
import { WorkspaceFacade } from "../../../components/workspace/workspace.facade";
import { AuthService } from "../../../auth.service";
import { CookiesService } from "../../../services/cookies.service";
import { UploadPresentationDialog } from "../../../components/upload-presentation-dialog/upload-presentation-dialog.component";
import { domains } from "../../../services/free-emails.service";
import { NbDialogService } from "@nebular/theme";
import { ReactFacade } from "../../../facades/react.facade";

declare global {
  interface Window {
    storydocEnv: string;
  }
}

@Component({
  selector: 'ngx-header-first-time-login',
  template: '',
  styles: [``],
})
  
export class FirstTimeLoginComponent {
   
  constructor(private analyticsService: AnalyticsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private pagesService: PagesService,
              private auth: AuthService,
              private workspaceFacade: WorkspaceFacade,
              private dialogService: NbDialogService,
              private cookiesService: CookiesService,
              private reactFacade: ReactFacade,
              private accountsService: AccountsService) { }
  
  unsubscribe$ = new Subject<void>();
  pagesCount$ = this.pagesService.count();
  orgInfo$ = this.accountsService.getOrgInfo$();

  isAdminCore$ = this.auth.getAdminCore$();
  user$ = this.auth.getUser$();

  WIZARD_COOKIE_KEY = 'storydoc_wizard_67ebc42a-0835-47b9-9957-6cd8c3d02ef3';
  START_FROM_TEMPLATE_COOKIE_KEY = 'storydoc_template_1b2f942c-1d68-48b2-99df-b40aed30e76c';

  track(event:string, params = {}) {
    this.analyticsService.track(event, params);
  }

  ngOnInit() {
    window.storydocEnv = environment.env;
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      if (params.create !== undefined) this.navigateToWizard();
    })
    combineLatest([
      this.orgInfo$.pipe(take(1)),
      this.user$.pipe(filter(user => !!user), take(1)),
      this.isAdminCore$
    ])
      .pipe(take(1))
      .subscribe(([ orgInfo, user, admin ]) => {
        this.pageFlowHandler(orgInfo, admin);
        if (orgInfo.firstLogin && orgInfo._id === user.orgId) {
          this.checkWizardPayloadAndNavigate();
        }
      });
  }

  pageFlowHandler(orgInfo: Record<string, any>, adminCore: boolean = false) {
    
    const isFirstLogin = !orgInfo.firstLogin;
    if (!isFirstLogin) return;
    
    const isCreatedAfterJune15 = (new Date(orgInfo.createdAt).getTime() > new Date('2021-06-15T00:00:00.000+00:00').getTime())

    if (isFirstLogin && isCreatedAfterJune15 && !adminCore) this.handleFirstLogin(orgInfo);
    else this.handleSelectedTemplate();

    this.user$.pipe(take(1)).subscribe(user => this.openUploadPresentation(orgInfo, user))
  }

  handleFirstLogin(orgInfo: Record<string, any>) {
    this.track('success account activated');
    this.handleLoginHubspotEvents(orgInfo);

    if (!orgInfo.firstLogin) {
      this.accountsService.firstLogin().subscribe(account => {
        this.accountsService.updateOrgInfo(account);
      });
    }

    const { expiredKey, joinWorkspaceOverlayStatus$ } = this.workspaceFacade;
    if (!this.cookiesService.getCookie(expiredKey) && !joinWorkspaceOverlayStatus$.getValue()) {
      this.navigateToWizard();
    }
  }


  handleSelectedTemplate() {
    if (!this.cookiesService.getCookie('selectedTemplate')) return;
    this.auth.getUserType$().pipe(take(1)).subscribe(user => {
      if(!['user', 'creator lite'].includes(user)) return this.clickNewPage();
      this.cookiesService.deleteCookie('selectedTemplate', "/", "storydoc.com");
    })
  }

  handleLoginHubspotEvents(orgInfo: Record<string, any>) {
    const eventDate = moment().utc().startOf('day').valueOf();
    this.analyticsService.sendHubspotInternalEvent('view platform for the first time');

    const parameters = orgInfo.parameters;
    let utms = {};

    if (parameters) {
      const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'gclid'];
      utms = utmKeys.reduce((acc, key) => ({ ...acc, [ key ]: parameters[ key ] || '' }), {});
      
      if (parameters['landingPage'])  utms['landing_page'] = parameters['landingPage'];
      if (parameters['landingPageCategory'])  utms['landing_page_category'] = parameters['landingPageCategory'];
      if (parameters['adwords_timestamp']) {
        try {
          const date = moment(parameters['adwords_timestamp']).utc().startOf('day').valueOf();
          utms['first_time_clicked_ad'] = date;
        } catch(e) {
          console.error('Error parsing adwords_timestamp:', e);
        }
      }
    }

    const zone = moment.tz.guess();
    this.analyticsService.sendHubspotProperty({
      trial_status: 'active',
      view_platform_for_the_first_time: eventDate,
      last_editor_visit_date: eventDate,
      time_zone_abbreviation: moment.tz(zone).zoneAbbr(),
      time_zone_offset: moment().format('Z'),
      ...utms
    });
  }

  openUploadPresentation(orgInfo: Record<string, any>, user: Record<string, any>) {

    const hours = moment().diff(orgInfo.createdAt, "hours");
    const { website, firstPublish } = orgInfo;
    if(firstPublish || hours > 72) return //check if published or more that 3 days after first login
    const isShowed = this.cookiesService.getCookie('uploadPresentation');
    const isSameSession = sessionStorage.getItem('isSameSession')
    if((isShowed && !JSON.parse(isShowed))) return // don't show after second time

    if (hours > 24 && website && !isSameSession && !this.accountsService.isAppSumo) {
      
      const sub = user.sub;
      const domain = sub.split('@')[1];
      const isFreeDomain = domains.includes(domain);
      if (isFreeDomain) {
        this.dialogService.open(UploadPresentationDialog, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
          context: {
            orgInfo: orgInfo
          }
        })
        .onClose.subscribe(() => {
          sessionStorage.setItem('isSameSession', 'true');
          if(isShowed && JSON.parse(isShowed)) this.cookiesService.setCookie('uploadPresentation', false);
        });
      } else { 
        this.reactFacade.initializeApp$().pipe(delay(3000)).subscribe(() => {
          document.dispatchEvent(new CustomEvent('sd-open-office-hours-dialog'));
        });
      }
    }
  }

  navigateToWizard() {
    this.router.navigateByUrl('/pages/wizard');
  }

  clickNewPage() {
    this.track('click new story');
    this.navigateToWizard();
  }

  checkWizardPayloadAndNavigate() {
    const data = this.getCookie(this.WIZARD_COOKIE_KEY) || this.getCookie(this.START_FROM_TEMPLATE_COOKIE_KEY);
    if (data) this.navigateToWizard();
  }

  getCookie(name: string) {
    return `; ${document.cookie}`
        .split(`; ${name}=`)
        .pop()
        .split(';')
        .shift();
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
