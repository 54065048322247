import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatest, throwError, of, from, BehaviorSubject } from 'rxjs';
import { catchError, delay, retryWhen, mergeMap, take, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
import { AccountsService } from './accounts.service';
import { PagesService } from './pages.service';
import { UsersService } from './usersV2.service';
import { IntegrationsService } from './integrations.service';
import { v4 as uuidv4 } from 'uuid';
import { MixpanelService } from './mixpanel.service';

declare const window;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  block = false;
  loaded = false;
  counter = 0;
  eventsQueue = [];

  ERROR_CODE: number[] = [ 400, 401, 429, 0, 409, 500 ];
  DELAY: number = 3000;
  RETRY: number = 3;
  pageWizardKeys: string[] = [
    'company product',
    'website',
    'company name',
    'subtype category',
    'template title',
    'template id',
    'website flow',
    'wizard skipped',
    'branded template'
  ];



  currentStoriesCount$: BehaviorSubject<number|null> = new BehaviorSubject<number|null>(null);


  constructor(private accountsService: AccountsService,
              private pagesService: PagesService,
              private usersV2Service: UsersService,
              private integrationsService: IntegrationsService,
              private mixpanelService: MixpanelService,
              private router: Router,
              private auth: AuthService) {
              this.init();
            }

  oktaUser;

  backendEvents: Set<string> = new Set([
    'success edit save',
    'success add slide',
    'success publish',
    'open pro dialog',
    'open team dialog',
    'click share'
  ]);


  segmentEvents: Set<string> = new Set([
    'success account activated',
    'click connect integration',
    'view automations page',
    'first publish',
    'view payments popup',
    'view popup team plan',
    'view payments',
    'click buy now'
  ]);

  private SEGMENT_MAPPED_EVENTS = new Map([
    [ 'success edit save', { event: 'first edit', key: 'firstEdit' } ],
    [ 'success add slide', { event: 'first slide added', key: 'firstSlideAdded' } ],
    [ 'click share', { event: 'first click send', key: 'firstClickSend' } ],
    ['success choose slide', { event: 'first slide added', key: 'firstSlideAdded' } ],
  ])


  init() {
    this.usersV2Service.me().pipe(
      tap((user) => {
        // Set up mixpanel identity
        const uid = user.oktaId;
        const identifier = user.distinctId || uid;
        this.handleMixpanelAuthentication(identifier);

        // Set up clarity tracking if enabled
        if (window.clarity) {
          const sessionId = uuidv4();
          sessionStorage.setItem('sd-session-id', sessionId);
          window.clarity("identify", user._id, sessionId);
          window.clarity('consent');
        }

        // Handle org validation
        const orgId = user.orgId;
        if(!orgId) {
          if(this.router.url === '/welcome') return;
          return this.router.navigateByUrl('/welcome');
        }
        this.deleteCookie('orgId');

        // Set up user properties
        const email = user.email;
        const name = `${user.firstName} ${user.lastName || ''}`;
        const { profile = {} } = user;
        
        // Block employee tracking in production
        if(email.includes('@storydoc.com') && environment.env === 'production') {
          this.block = true;
        }

        // Register mixpanel properties
        this.mixpanelService.setPeople({
          '$email': email,
          'Name': name,
          'orgId': orgId
        });

        this.bulkRegister({
          'user id': uid,
          'user name': name,
          'user email': email,
          'user created': user.createdAt,
          'visit count': this.incrementor('visit count'),
          'browser window width': window.innerWidth,
          'browser window height': window.innerHeight,
          'is mobile': window.innerWidth < 1200,
          'user sign up type': this.auth.userSignUpType,
          'user has profile image': !!profile.picture,
          'user has calendar link': !!profile.calendar,
          'user profile role': profile.role,
          'user first analytics': user.firstAnalytics,
          'autosave counter': user.autosaveCount || 0,
          'engagement level': this.getEngagementLevel(user.autosaveCount || 0),
          'walkthrough skipped': user.walkthrough ? user.walkthrough.hasSkipped : false,
          'walkthrough last step': user.walkthrough ? user.walkthrough.lastStepIndex + 1 : null
        });
        
      }),
      tap(() => this.loadData())
    ).subscribe();
  }

  loadData() {
    this.loadOrgInfo();
    combineLatest([
      this.pagesService.count({status: 'Live'}),
      this.pagesService.count(),
      this.accountsService.getOrgVersionsCount()
    ])
    .pipe(
      catchError(() => of([{count: 0}, {count: 0}, {count: 0}])),
      tap(([ liveCount, allCount, versionsCount ]) => {
        this.currentStoriesCount$.next(allCount.count);
        if (versionsCount.count === 50 || versionsCount.count === 100) {
          this.updatePlanPropertiesInfo('teamTriggerTimestamp', new Date().toISOString(), 'Versions count team qualification', {source: 'create versions'});
        }
        this.bulkRegister({
          'organization active stories': liveCount.count,
          'organization stories': allCount.count,
          'organization versions': versionsCount.count
        });
      })
    ).subscribe();
    
    combineLatest([
      this.pagesService.count({onlyOwnStories: true, status: 'Live'}),
      this.pagesService.count({onlyOwnStories: true})
    ])
    .pipe(
      catchError(() => of([{count: undefined}, {count: undefined}])),
      tap(([liveCount, allCount]) => {
        this.bulkRegister({
          'user active stories': liveCount.count,
          'user stories': allCount.count
        });
      })
    ).subscribe();

     this.usersV2Service.count()
    .pipe(catchError(() => of(null)))
    .subscribe(users => {
      if (!users) return
      const seats = this.accountsService.planUsersLimit;
      const count = users.count;
      const freeSeats = seats - count;
      this.bulkRegister({
        'users count': count,
        'seats count': seats,
        'available seats count': freeSeats,
      })
      
    })


    this.auth.getUserType$().subscribe(userType => {
      this.bulkRegister({
        'user type': userType
      });
    });

  }

  loadOrgInfo() {
    combineLatest([
      this.accountsService.getOrgInfo$().pipe(take(1)),
      this.usersV2Service.getOktaUser()
    ])
    .pipe(
      catchError((err: any): Observable<any> => {
        this.loadedFinished();
        this.track('failed load organization info', {
          'failure message': err.message
        });
        return throwError(err);
      })
    )
    .subscribe(([org, oktaUser]) => {
      if(!org) {
        this.counter += 1;
        if(this.counter < 10) this.loadOrgInfo();
        this.track('failed load organization info', {
          'failure message': 'org returned empty'
        });
        return;
      }

      this.oktaUser = oktaUser;
      
      this.bulkRegister(this.buildAnalyticsData(org));
      this.loadedFinished();
      this.track('open app');
      this.validateTimestampVisit(org);
    });
  }

  private handleMixpanelAuthentication(identifier: string) {
    
    const cookieDistinctId = this.getCookie('SD_DISTINCT_ID');
    if (cookieDistinctId && cookieDistinctId !== identifier) {
      this.mixpanelService.alias(identifier, cookieDistinctId);
    }

    this.mixpanelService.identify(identifier);
    this.setCookie('SD_DISTINCT_ID', identifier, 365);
    window.mixpanel = this.mixpanelService.instance;
  }

  private buildAnalyticsData(org: any) {
    const isFreeTrial = (org.plan && org.plan === 'trial');
    const plan = environment.paddle.plans.find(plan => plan.id === org.subscriptionPlanId);
    const { customization = {}, parameters = {}, timestamps = {}, wizardPayload = {} } = org;
    const wizardEnvironment = parameters.wizardEnvironment;
    const firstPlan = this.accountsService.getPlanById(org.firstSubscriptionPlanId) || {} as Record<string, string|number>;

    const data = {
      'organization id': org._id,
      'organization name': org.title,
      'organization created': org.createdAt,
      'organization free trial': isFreeTrial,
      'organization is self serv': org.isSelfServ,
      'organization type': org.isSelfServ ? 'Self serve' : 'B2B', 
      'organization verified': !!org.verifiedAt || this.emailVerified,
      'user is self serv': org.isSelfServ,
      'first wizard website': org.website,
      'testing group': this.accountsService.testingGroup,
      'testing number': this.accountsService.testingNumber,
      'editor version': '4',
      'coupon code': org.paymentCoupon,
      'organization has profile logo': !!customization.logo,
      'organization has profile favicon': !!org.favicon,
      'organization first analytics': timestamps.firstAnalytics,
      'first wizard environment': wizardEnvironment,
      'first wizard subtype': wizardPayload.subtype,
      'first wizard subtype category': wizardPayload[ 'subtype category' ],
      'organization enabled automation': !!org.jwtTokenIat,
      'organization connected integration': org.connectedIntegrations,
      'organization subscription expired': this.accountsService.isSubscriptionExpired(org.trialEnd),
      'organization subscription cancelled': !!org.cancelAt,
      'organization last payment date': org.lastSuccessfulPayment,
      'organization renewal date': org.nextPayment,
      'organization payment country': org.paymentCountry,
      'organization first subscription plan name': firstPlan.title,
      'organization first subscription plan period': firstPlan.period,
      'organization first subscription plan date': org.firstPaidAt,
      'organization first payment amount': org.firstPaymentAmount,
      'organization last payment amount': org.lastPaymentAmount,
      'organization lifetime payment amount': org.lifetimePaymentAmount,
      'days to end free trial': org.trialEnd && isFreeTrial ? moment(org.trialEnd).diff(moment(), 'days') + 1 : null,
      'organization subscription plan name': plan && !isFreeTrial ? plan.title : null,
      'organization subscription plan period': plan && !isFreeTrial ? plan.period : null,
      'organization timezone': org.hqTimezone,
    };

    const mappedParams = Object.entries(parameters).reduce((acc, [key, value]) => {
      const parsedKey = key.replace(/([A-Z])/g, ' $1').toLowerCase().trim();
      acc[parsedKey] = value;
      return acc;
    }, {});



    Object.assign(data, mappedParams);

    return data;
  }

  


  track(event: string, payload = {}) {
    if(this.block || !event) return;
    if(!this.loaded) return this.eventsQueue.push({ event, payload });
    const mappedEvent = this.SEGMENT_MAPPED_EVENTS.get(event);
    if(mappedEvent) {
      this.handleMappedSegmentEvent(mappedEvent.event, mappedEvent.key, payload);
    }

    if (this.backendEvents.has(event)) {
      return this.sendMixpanelEvent(event, payload, false);
    }

    if(this.segmentEvents.has(event)) {
      return this.sendMixpanelEvent(event, payload, true);
    }

    return this.mixpanelService.track(event, payload);
  }

  trackStory(event, page, payload = {}) {
    if(!page) return this.track(event, payload);
    const wizard = {};
    const wizardPayload = page && page.settings && page.settings.wizard ? page.settings.wizard : null;
    if(wizardPayload && typeof(page.settings.wizard) === 'object') {
      for(let k of this.pageWizardKeys) {
        if(wizardPayload.hasOwnProperty(k)) wizard[k] = wizardPayload[k];
      }
    }
    this.track(event, {
      'story name': page.settings.title,
      'story created': page.createdAt,
      'story status': page.status,
      'editor version': '5',
      ...payload,
      ...wizard
    })
  }

  trackVersion(event, page, version, payload = {}) {
    if(!version) return this.trackStory(event, page, payload);
    const expireAt = version.isPublic ? null : version.expireAt
    this.trackStory(event, page, {
      'story version name': version.data.title,
      'story version created': version.createdAt,
      'story version expire': expireAt,
      ...payload
    })
  }

  register(key: string, value: unknown) {
    this.mixpanelService.register(key, value);
  }

  bulkRegister(properties: Record<string, unknown>) {
    this.mixpanelService.bulkRegister(this.fallbackProps(properties));
  }

  unregister(keys: string[]) {
      this.mixpanelService.unregister(keys);
  }

  incrementor(key: string) {
    const value = this.mixpanelService.get_property(key);
    return (value && typeof(value) == 'number') ? value +1 : 1;
  }

  loadedFinished() {
    this.loaded = true;
    this.eventsQueue.forEach(e => this.track(e.event, e.payload));
    this.eventsQueue = [];
  }

  sendMixpanelEvent(event: string, payload: Record<string, unknown>, segment: boolean = false) {
    if (!this.mixpanelService.instance) return;
    const props = { ...this.mixpanelService.instance.cookie.props, ...payload };
    this.accountsService.postMixpanelEvent(event, props, segment).pipe(catchError(() => from([]))).subscribe();
  }

  gtmAnalytics(eventAction: string, properties: Record<string, unknown> = {}) {
    if (!window.dataLayer) return;
    
    const payload = { ...properties };
    ['event', 'event-category', 'event-action'].forEach(key => delete payload[key]);
    
    window.dataLayer.push({
      event: 'conversion',
      'event-category': 'click', 
      'event-action': eventAction,
      ...payload
    });
  }

  get emailVerified() {
    return this.oktaUser.emailVerified === true
  }

  // ================ HUBSPOT ANALYTICS ================
  shouldRetry$(err: any, i: number): Observable<any> {
    if (!this.ERROR_CODE.includes(err.status)) return throwError(err);
    return i >= this.RETRY ? throwError(err) : of(err);
  }


  sendHubspotInternalEvent(event: string, props: { [ key: string ]: string | number } = {}) {
    if(environment.env !== 'production') return;
    this.gtmAnalytics(event, props);
    this.integrationsService.postAnalyticsEvent(event, props)
    .pipe(
      retryWhen(errors => errors.pipe(mergeMap((err, i) => this.shouldRetry$(err, i)), delay(this.DELAY))),
      catchError(err => {
        return of(err);
      })
    ).subscribe()
  }

  sendHubspotProperty(property: { [ key: string ]: string | number } = {}) {
    if (environment.env !== 'production' || Object.keys(property).length === 0) return;
    this.integrationsService.postHubspotProperty(property)
    .pipe(
      retryWhen(errors => errors.pipe(mergeMap((err, i) => this.shouldRetry$(err, i)), delay(this.DELAY))),
      catchError(err => {
        return of(err);
      })
    ).subscribe()
  }

  updatePlanPropertiesInfo(trigger: string, value: string, action: string = '', source: Record<string, any> = {}) {
    this.auth.isAdministrator$().pipe(take(1)).subscribe((isAdministrator) => {
      if (!isAdministrator) return;
      const payload = { [ trigger ]: value };
      if (trigger === 'proTriggerTimestamp') {
        payload.proTriggerSource = source.source;
      }
      if (trigger === 'teamTriggerTimestamp') {
        payload.teamTriggerSource = source.source;
      }
      this.accountsService.handlePlanProperties(payload).pipe(catchError(() => from([]))).subscribe();
      let key: string;
      if (trigger === 'proTriggerTimestamp') key = 'pro_trigger_timestamp';
      if (trigger === 'teamTriggerTimestamp') key = 'team_trigger_timestamp';
      if (!key) return;
      let data = {};
      data[ key ] = moment(new Date()).utc().startOf('day').valueOf();
      this.sendHubspotProperty(data);
      this.sendHubspotInternalEvent(action, source);
    })
  }

  fallbackProps(properties: Record<string, unknown>) {
    const result = {};
    const falseValues = [0, false];
    for (const [key, value] of Object.entries(properties)) {
      result[key] = falseValues.includes(value as number | boolean) ? value : value || undefined;
    }
    return result;
  }

  private getEngagementLevel(editCount: number): string | null {
    const levels = [
      { threshold: 10, label: 'less than 10 edits' },
      { threshold: 50, label: '10-50 edits' },
      { threshold: 100, label: '50-100 edits' },
      { threshold: 500, label: '100-500 edits' },
      { threshold: Infinity, label: '500+ edits' }
    ];

    const level = levels.find(level => editCount < level.threshold);

    return level ? level.label : null;
  }


  handleMappedSegmentEvent(event: string, key: string, params = {}) {
    const account = this.accountsService.orgInfo$.getValue();
    const { timestamps = {} } = account;
    if(timestamps[ key ]) return;
    this.sendMixpanelEvent(event, params, true);
    this.accountsService.updateOrgInfo({...account, timestamps: {...timestamps, [ key ]: moment().toISOString() }});
    this.accountsService.updateTimestamp(key)
    .pipe(catchError(() => from([])))
    .subscribe();
  }


  validateTimestampVisit(account) {
    const { timestamps = {} } = account;
    const now = moment().toISOString();
    const createdAt = moment(account.createdAt).toISOString();
    if (moment(createdAt).isBefore('2025-01-22')) return;

    // Check for 2nd day visit - only track if user visits within 2-7 days
    const dayAfterCreation = moment(createdAt).add(1, 'day').toISOString();
    const weekAfterCreation = moment(createdAt).add(1, 'week').toISOString();
    if (moment(now).isAfter(dayAfterCreation) && moment(now).isBefore(weekAfterCreation) && !timestamps['secondDayVisit']) {
      this.sendMixpanelEvent('2nd day visit', {}, true);
      timestamps[ 'secondDayVisit' ] = now;
      this.accountsService.updateTimestamp('secondDayVisit')
        .pipe(catchError(() => from([])))
        .subscribe();
    }

    // Check for 2nd week visit - only track if user visits within 7-30 days
    const monthAfterCreation = moment(createdAt).add(1, 'month').toISOString();
    if (moment(now).isAfter(weekAfterCreation) && moment(now).isBefore(monthAfterCreation) && !timestamps['secondWeekVisit']) {
      this.sendMixpanelEvent('2nd week visit', {}, true);
      timestamps['secondWeekVisit'] = now;
      this.accountsService.updateTimestamp('secondWeekVisit')
        .pipe(catchError(() => from([])))
        .subscribe();
    }

    // Check for 2nd month visit - track if user visits after 30 days
    if (moment(now).isAfter(monthAfterCreation) && !timestamps['secondMonthVisit']) {
      this.sendMixpanelEvent('2nd month visit', {}, true);
      timestamps['secondMonthVisit'] = now;
      this.accountsService.updateTimestamp('secondMonthVisit')
        .pipe(catchError(() => from([])))
        .subscribe();
    }

    if(this.currentStoriesCount$.getValue() >= 2 && !timestamps['secondTemplateCreated']) {
      this.sendMixpanelEvent('2nd template created', {}, true);
      timestamps['secondTemplateCreated'] = now;
      this.accountsService.updateTimestamp('secondTemplateCreated')
        .pipe(catchError(() => from([])))
        .subscribe();
    }

    this.accountsService.updateOrgInfo({ ...account, timestamps });
    
  }


  // ================ COOKIES ================
  setCookie(name: string, value: string|number, days?: number) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
      name + "=" + (value || "") + expires + "; path=/;domain=.storydoc.com";
  }

  deleteCookie(name: string, path = '/', domain = '.storydoc.com') {
    if (this.getCookie(name)) {
      document.cookie =
        name +
        "=" +
        (path ? ";path=" + path : "") +
        (domain ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

  getCookie(name: string) {
    return `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift();
  }
  // ================ COOKIES ================
  

  // ================ MIXPANEL ANALYTICS ================
}
