import { Injectable } from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment';
import { Industry } from '../components/wizard/wizard.interface';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class EditorV4Service {

  constructor() {
    window.environmentLink = environment.assets;
  }

  public editorV4Status$ = new Subject<any>();
  public shareTrigger$ = new Subject<any>();
  // version
  public updateVersionButton$ = new Subject<any>();
  public publishVersionRequest$ = new Subject<any>();
  public publishVersionSuccess$ = new Subject<any>();
  public updateVersionFailure$ = new Subject<any>();
  public version$ = new Subject<any>();

  public isPublish$ = new Subject<any>();
  public showPublishButtonStatus$ = new Subject<any>();
  public enableAutosave$ = new Subject<any>();
  public startPublishProcess$ = new Subject<any>();
  public finishPublishProcess$ = new Subject<any>();
  public failedPublishProcess$ = new Subject<any>();
  public failedSaveProcess$ = new Subject<any>();
  public finishShareProcess$ = new Subject<any>();
  public isOpenPreview$ = new Subject<any>();
  public orgInfo$ = new BehaviorSubject<any>(null);
  public storyData$ = new Subject<any>();
  public story$ = new Subject<Record<string, unknown>>();
  public saveStory$ = new Subject<any>();
  public versionUpdate$ = new Subject<any>();
  public isSaving$ = new Subject<boolean>();
  public isSettingsOpen$ = new Subject<any>();
  public autoSaveToggle$ = new Subject<any>();
  public isDesignSystemOpen$ = new Subject<any>();
  public finishCreateStory$ = new Subject<any>();
  public convertToEditorV4$ = new Subject<any>();
  public environment$ = new Subject<any>();
  public goBackStatus$ = new Subject<any>();
  public addSlide$ = new Subject<any>();
  public saveToMySlides$ = new Subject<any>();
  public triggerDialog$ = new Subject<any>();
  public emitMediaItem$ = new Subject<any>();
  public isStorydocAdmin$ = new BehaviorSubject<boolean>(false);

  public openContactUsForm$ = new Subject<any>();
  public analyticsEvent$ = new Subject<any>();
  public classificationsData$ = new Subject<any>();
  public paddlePlan$ = new Subject<any>();
  public senderInformation$ = new Subject<any>();

  public showToast$ = new Subject<any>();

  public walkthrough$ = new Subject<any>();
  public showRegenerate$ = new Subject<boolean>();
  public showWizardDialog$ = new Subject<void>();
  public industry$ = new BehaviorSubject<Industry | null>(null);
  public triggerAssistantTooltip$ = new BehaviorSubject<boolean>(false);

  // ========= OLD STRUCTURE =========
  public userRole$ = new BehaviorSubject<string>(null);
  public shareButtonSpinner$ = new Subject<any>();
}
