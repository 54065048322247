import { Component, Input } from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { Router } from "@angular/router";
import { UpdateBillingDetailsDialogComponent } from "../update-billing-details-dialog/update-billing-details-dialog.component";

import { AuthService } from "../../auth.service";
import { HelpCenterService } from "../../pages/help-center/help.service";
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: "ngx-trial-has-ended-dialog",
  templateUrl: "./account-is-on-hold-dialog.component.html",
  styleUrls: ["./account-is-on-hold-dialog.component.scss"],
})
export class AccountIsOnHoldDialogComponent {
  @Input() orgInfo: any;
  isAdministrator: boolean = false;

  constructor(
    protected ref: NbDialogRef<AccountIsOnHoldDialogComponent>,
    public router: Router,
    private auth: AuthService,
    private helpCenterService: HelpCenterService,
    public analyticsService: AnalyticsService,
    private dialogService: NbDialogService
  ) {
    this.auth
      .isAdministrator$()
      .subscribe((res) => (this.isAdministrator = res));

    this.track('view account is on hold dialog')
  }

  track(event: string, params = {}) {
    this.analyticsService.track(event, {
      'user is administrator': this.isAdministrator,
      'user status': 'paused',
      ...params,
    });
  }

  get canUpdateBilling() {
    return this.isAdministrator &&  typeof this.orgInfo.paymentsUpdateUrl === "string";
  }

  onUpdateBilling() {
    this.track('click update billing')
    this.dialogService.open(UpdateBillingDetailsDialogComponent, {
      context: {
        orgInfo: this.orgInfo,
        trackScreen: 'account is on hold dialog'
      },
    });
  }

 onContactUs() {
    this.track('click contact us')
    const data = {
      topic: 'Billing and plans',
      subject: '',
      messagePlaceholder: ''
    }
    this.helpCenterService.setFormFieldsData(data)
    this.router.navigateByUrl('/pages/help');
    this.ref.close();
  }
}
