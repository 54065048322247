import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, combineLatest, throwError, of, from, forkJoin, BehaviorSubject } from 'rxjs';
import { catchError, delay, retryWhen, mergeMap, take } from 'rxjs/operators';
import * as moment from 'moment';
import Cookies from 'js-cookie'


import { environment } from '../../environments/environment';
import mixpanel from 'mixpanel-browser'
import { AuthService } from '../auth.service';
import { AccountsService } from './accounts.service';
import { PagesService } from './pages.service';
import { UsersService } from './usersV2.service';
import { IntegrationsService } from './integrations.service';

declare const window;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  block = false;
  loaded = false;
  counter = 0;
  eventsQueue = [];

  ERROR_CODE: number[] = [ 400, 401, 429, 0, 409, 500 ];
  DELAY: number = 3000;
  RETRY: number = 3;
  pageWizardKeys: string[] = [
    'industry',
    'industry category',
    'company product',
    'website',
    'company name',
    'subtype select',
    'subtype classification',
    'subtype category',
    'template title',
    'template id',
    'website flow',
    'wizard skipped',
    'branded template'
  ];



  currentStoriesCount$: BehaviorSubject<number|null> = new BehaviorSubject<number|null>(null);


  constructor(private http: HttpClient,
              private accountsService: AccountsService,
              private pagesService: PagesService,
              private usersV2Service: UsersService,
              private integrationsService: IntegrationsService,
              private router: Router,
              private auth: AuthService) {

    let mixpanelSettings: any = {
      // TODO: fix api-eu
      // api_host: "https://api-eu.mixpanel.com",
      persistence: 'localStorage'
    };
    if(!environment.production) mixpanelSettings.debug = true;

    mixpanel.init(environment.mixpanel, mixpanelSettings);
    mixpanel.reset();
    this.init();
  }

  oktaUser;

  backendEvents: string[] = [
    'success autosave edit save',
    'success paying customer',
    'success upgrade customer',
    'success publish',
    'success first publish',
    'view wizard step 2',
    'success choose template',
    'success account activated',

    'click cancel subscription',
    'view cancel subscription form',
    'close cancel subscription form',
    'click keep plan',
    'click contact us from the cancel form',
    'click confirm cancellation'
  ];
  init() {
    this.usersV2Service.me().subscribe((user: any) => {
      const uid = user.oktaId;
      const identifier = user.distinctId || uid;
      mixpanel.identify(identifier);
      window.mixpanel = mixpanel;

      const orgId = user.orgId;
      if(!orgId) {
        if(this.router.url === '/welcome') return;
        return this.router.navigateByUrl('/welcome');
      } else {
        Cookies.remove('orgId');
      }

      const email = user.email;
      const name = `${user.firstName} ${user.lastName || ''}`;
      // Block all employees account from being tracked on production
      if(email.includes('@storydoc.com') && environment.env === 'production') this.block = true
      mixpanel.people.set({
        '$email': email,
        'Name': name,
        'orgId': orgId
      });
      mixpanel.register({
        'user id': uid,
        'user name': name,
        'user email': email,
        'visit count': this.incrementor('visit count')
      });
      if (user.walkthrough) {
        mixpanel.register({
          'walkthrough skipped': user.walkthrough.hasSkipped,
          'walkthrough last step': user.walkthrough.lastStepIndex
        });
      }

      if (!mixpanel.get_property('autosave counter')) {
        mixpanel.register('autosave counter', 0);
      }
      this.loadData();
    })

    window.addEventListener('dispatchTrack', (e: any) => {
      const data = e.detail
      if (!data) return
      this.track(data.event, data.params)
    })
  }

  gtmAnalytics(eventAction: string, properties: any = {}) {
    if (window.dataLayer) {
      const reserved = [ 'event', 'event-category', 'event-action'];
      const payload = { ...properties };
      reserved.forEach(key => delete payload[key]);
      window.dataLayer.push({ 'event': 'conversion', 'event-category': 'click', 'event-action': eventAction, ...payload });
    }
  }

  loadData() {
    // console.log("has_opted_out_tracking", mixpanel.has_opted_out_tracking());
    this.loadOrgInfo();

    combineLatest([
      this.pagesService.count({status: 'Live'}),
      this.pagesService.count(),
      this.accountsService.getOrgVersionsCount()
    ]).subscribe(([ liveCount, allCount, versionsCount ]) => {
      this.currentStoriesCount$.next(allCount.count);
      const versionsCountObj: any = versionsCount;
      if (versionsCountObj.count === 50 || versionsCountObj.count === 100) {
        this.updatePlanPropertiesInfo('teamTriggerTimestamp', new Date().toISOString(), 'Versions count team qualification', {source: 'create versions'});
      }
      mixpanel.register({
        'organization active stories': liveCount.count,
        'organization stories': allCount.count,
        'organization versions': versionsCountObj.count
      })
    });
    combineLatest([
      this.pagesService.count({onlyOwnStories: true, status: 'Live'}),
      this.pagesService.count({onlyOwnStories: true})
    ]).subscribe(([liveCount, allCount]) => {
      mixpanel.register({
        'user active stories': liveCount.count,
        'user stories': allCount.count
      })
    });

    combineLatest([
      this.usersV2Service.count(),
      this.accountsService.getOrgInfo$()
    ])
    .pipe(catchError(() => of(null)))
    .subscribe(res => {
      if (!res) return
      const [ usersCount, orgInfo ] = res;
      const seats = this.accountsService.planUsersLimit;
      const count = usersCount.count;
      const freeSeats = seats - count;
      mixpanel.register({
        'users count': count,
        'seats count': seats,
        'available seats count': freeSeats,
        'refactor editor': !!orgInfo.isEditorBetaTester
      })
      if (window.clarity) {
        window.clarity('consent');
      }
    })


    this.auth.getUserType$().subscribe(userType => {
      mixpanel.register({
        'user type': userType
      });
    });

  }

  get emailVerified() {
    return this.oktaUser.emailVerified === true
  }

  incrementor(key) {
    const value = mixpanel.get_property(key);
    return (value && typeof(value) == 'number') ? value +1 : 1;
  }

  // workaround to wait until org information returns,
  // TODO: consolidate all information resources to 1 low-latency call and finish loading once its loaded
  loadedFinished() {
    this.loaded = true;
    for(let e of this.eventsQueue) {
      this.track(e.event, e.payload);
    }
    this.eventsQueue = [];
  }

  loadOrgInfo() {
    combineLatest([
      this.accountsService.getOrgInfo$(),
      this.usersV2Service.getOktaUser()
    ])
    .pipe(
      catchError((err: any) : Observable<any> => {
        this.loadedFinished();
        this.track('failed load organization info', {
          'failure message': err.message
        });
        return throwError(err);
      })
    )
    .subscribe(([org, oktaUser]) => {
      if(!org) {
        this.counter += 1;
        if(this.counter < 10) this.loadOrgInfo();
        this.track('failed load organization info', {
          'failure message': 'org returned empty'
        });
        return;
      }
      this.oktaUser = oktaUser;
      const isFreeTrial = (org.plan && org.plan === 'trial');
      const plan = environment.paddle.plans.find(plan => plan.id === org.subscriptionPlanId);
      const data = {
        'organization id': org._id,
        'organization name': org.title,
        'organization created': org.createdAt,
        'organization free trial': isFreeTrial,
        'organization is self serv': org.isSelfServ,
        'organization type': org.isSelfServ ? 'Self serve' : 'B2B',
        'organization appsumo': !!org.appsumoUuid,
        'organization verified': !!org.verifiedAt || this.emailVerified,
        'user is self serv': org.isSelfServ, // keep for consistency, not correct as not on the user scope
        'website': org.website,
        'industry': org.industry,
        'started from prompt': !!org.prompt,
        'testing group': this.accountsService.testingGroup,
        'testing number': this.accountsService.testingNumber,
        'editor version': this.accountsService.isEditorV4User ? '4' : '3',
        'paymentCoupon': org.paymentCoupon
      };
      if (!!org.appsumoUuid) data[ 'appsumo user type' ] = org.appsumoPlanId;
      for(let k in org.parameters) {
        data[k] = org.parameters[k];
      }
      if(org.trialEnd && isFreeTrial) data['days to end free trial'] = moment(org.trialEnd).diff(moment(), 'days');
      if(plan && !isFreeTrial) {
        data['organization subscription plan name'] = plan.title;
        data['organization subscription plan period'] = plan.period;
      }
      mixpanel.register(data);
      this.loadedFinished();
      this.track('open app');
    });
  }

  track(event, payload = {}): Observable<any> {
    if(this.block || !event) return;
    if(!this.loaded) {
      this.eventsQueue.push({ event, payload });
      return;
    }
    if (this.backendEvents.includes(event)) this.sendMixpanelEvent(event, { ...this.baseProperties, ...payload });
    return mixpanel.track(event, {
      ...this.baseProperties,
      ...payload
    });
  }

  trackStory(event, page, payload = {}) {
    if(!page) return this.track(event, payload);
    const isFastVersioning = true;
    const wizard = {};
    const wizardPayload = page && page.settings && page.settings.wizard ? page.settings.wizard : null;
    if(wizardPayload && typeof(page.settings.wizard) === 'object') {
      for(let k of this.pageWizardKeys) {
        if(wizardPayload.hasOwnProperty(k)) wizard[k] = wizardPayload[k];
      }
    }
    this.track(event, {
      'story name': page.settings.title,
      'story created': page.createdAt,
      'story type': isFastVersioning ? 'fast versioning' : 'regular',
      'story status': page.status,
      'story components number': page.pageComponentsLength || (page.pageComponents || []).length,
      'editor version': page.slides ? '4' : '3',
      ...payload,
      ...wizard
    })
  }

  trackVersion(event, page, version, payload = {}) {
    if(!version) return this.trackStory(event, page, payload);
    const expireAt = version.isPublic ? null : version.expireAt
    this.trackStory(event, page, {
      'story version name': version.data.title,
      'story version created': version.createdAt,
      'story version expire': expireAt,
      ...payload
    })
  }

  trackComponent(event, page, component, payload = {}) {
    this.trackStory(event, page, {
      'component title': component.title,
      'component category': component.category,
      ...payload
    })
  }

  trackPageComponent(event, page, pageComponent, pageComponentIndex, version = null, payload = {}) {
    const data = {
      'slide number': pageComponentIndex+1,
      'slide title': pageComponent.libraryTitle,
      'slide category': pageComponent.libraryCategory,
      'slide type': pageComponent.libraryType,
      ...payload
    };
    if(version) this.trackVersion(event, page, version, data);
    else this.trackStory(event, page, data);

  }

  register(key, value) {
    const obj = {}
    obj[key] = value;
    mixpanel.register(obj);
  }

  unregister(keys) {
    for(let key of keys) {
      mixpanel.unregister(key);
    }
  }


  get baseProperties() {
    return {
      "dotell version": "37",
      'browser window width': window.innerWidth,
      'browser window height': window.innerHeight,
    }
  }


  shouldRetry$(err: any, i: number): Observable<any> {
    if (!this.ERROR_CODE.includes(err.status)) return throwError(err);
    return i >= this.RETRY ? throwError(err) : of(err);
  }


  sendHubspotInternalEvent(event: string, props: { [ key: string ]: string | number } = {}) {
    if(environment.env !== 'production') return;
    this.gtmAnalytics(event, props);
    this.integrationsService.postAnalyticsEvent(event, props)
    .pipe(
      retryWhen(errors => errors.pipe(mergeMap((err, i) => this.shouldRetry$(err, i)), delay(this.DELAY))),
      catchError(err => {
        this.track('Failed to send Hubspot internal event', {
          'failure message': `Code: ${err.status}. Message: ${err.message}`,
          'failure hubspot event': event,
          'failure hubspot props': JSON.stringify(props)
        });
        return of(err);
      })
    ).subscribe()
  }

  sendHubspotProperty(property: { [ key: string ]: string | number } = {}) {
    if (environment.env !== 'production' || Object.keys(property).length === 0) return;
    this.integrationsService.postHubspotProperty(property)
    .pipe(
      retryWhen(errors => errors.pipe(mergeMap((err, i) => this.shouldRetry$(err, i)), delay(this.DELAY))),
      catchError(err => {
        this.track('Failed to update Hubspot contact property', {
          'failure message': `Code: ${err.status}. Message: ${err.message}`,
          'failure hubspot property': JSON.stringify(property)
        });
        return of(err);
      })
    ).subscribe()
  }

  updatePlanPropertiesInfo(trigger: string, value: string, action: string = '', source: Record<string, any> = {}) {
    this.auth.isAdministrator$().pipe(take(1)).subscribe((isAdministrator) => {
      if (!isAdministrator) return;
      const payload = { [ trigger ]: value };
      if (trigger === 'proTriggerTimestamp') {
        payload.proTriggerSource = source.source;
      }
      if (trigger === 'teamTriggerTimestamp') {
        payload.teamTriggerSource = source.source;
      }
      this.accountsService.handlePlanProperties(payload).pipe(catchError(() => from([]))).subscribe();
      let key: string;
      if (trigger === 'proTriggerTimestamp') key = 'pro_trigger_timestamp';
      if (trigger === 'teamTriggerTimestamp') key = 'team_trigger_timestamp';
      if (!key) return;
      let data = {};
      data[ key ] = moment(new Date()).utc().startOf('day').valueOf();
      this.sendHubspotProperty(data);
      this.sendHubspotInternalEvent(action, source);
    })
  }


  sendMixpanelEvent(event: string, payload: Record<string, any> = {}) {
    if (!mixpanel) return;
    const props = {...mixpanel.cookie.props, ...payload};
    this.accountsService.postMixpanelEvent(event, props).pipe(catchError(() => from([]))).subscribe();
  }

}
