import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { ToasterService } from './services/toaster.service';
import { environment } from '../environments/environment';
import { Observable, throwError, combineLatest, from, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private auth: AuthService,
              private toasterService: ToasterService) { }

  // allowRequests: boolean = window.location.href.includes('downtime=false');

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if(!this.allowRequests) return from([null])
    if (!req.url.startsWith(environment.backend)) return next.handle(req);
    
    const adminCore = this.auth.isAdminCore();
    const orgId = this.auth.getOrgId()
    const headers: any = { };
    if (adminCore) headers.orgid = orgId;   

    return this.auth.getIdToken$()
      .pipe(mergeMap(idToken => {
      headers["Authorization"] = "Bearer " + idToken
      const tokenReq = req.clone({
        setHeaders: headers
      });
      
      return next.handle(tokenReq).pipe(
        catchError((error: HttpErrorResponse): Observable<any> => {
          if (error && error.status === 401) {
            this.toasterService.showToast('danger', 'Please login')
            this.auth.logout();
          }
          return throwError(error);
        })
      );
    }));
  }
}
