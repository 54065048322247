import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OrdersChart, OrdersChartData } from '../data/orders-chart';
import { OrderProfitChartSummary, OrdersProfitChartData } from '../data/orders-profit-chart';
import { ProfitChart, ProfitChartData } from '../data/profit-chart';

@Injectable()
export class OrdersProfitChartService extends OrdersProfitChartData {

  private summary = [
    {
      title: 'Total sessions',
      value: '36',
    },
    {
      title: 'Average reading time',
      value: '3:55',
    },
    {
      title: 'Average engagement score',
      value: '63%',
    },
    // {
    //   title: 'Today',
    //   value: '230',
    // },
  ];

  private leads = [
    {
      title: 'Total sessions',
      value: '24',
    },
    {
      title: 'Average reading time',
      value: '4:25',
    },
    {
      title: 'Average engagement score',
      value: '74%',
    },
    // {
    //   title: 'Today',
    //   value: '27',
    // },
  ];

  private deals = [
    {
      title: 'Total sessions',
      value: '11',
    },
    {
      title: 'Average reading time',
      value: '1:43',
    },
    {
      title: 'Average engagement score',
      value: '35%',
    },
    // {
    //   title: 'Today',
    //   value: '5',
    // },
  ];

  constructor(private ordersChartService: OrdersChartData,
              private profitChartService: ProfitChartData) {
    super();
  }

  getOrderProfitChartSummary(): Observable<any[]> {
    return observableOf(this.summary);
  }

  getLeadsChartSummary(): Observable<any[]> {
    return observableOf(this.leads);
  }

  getDealsChartSummary(): Observable<any[]> {
    return observableOf(this.deals);
  }

  getOrdersChartData(period: string): Observable<OrdersChart> {
    return observableOf(this.ordersChartService.getOrdersChartData(period));
  }

  getProfitChartData(period: string): Observable<ProfitChart> {
    return observableOf(this.profitChartService.getProfitChartData(period));
  }
}
