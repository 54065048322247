import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { AnalyticsService } from '../../services/analytics.service';
import { AccountsService } from '../../services/accounts.service';
import { repeat, delay, finalize, catchError, timeout, filter, takeWhile, delayWhen } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { Observable, from, EMPTY, TimeoutError, timer } from 'rxjs';

@Component({
  selector: 'ngx-check-payment-status-dialog',
  templateUrl: 'check-payment-status-dialog.component.html',
  styleUrls: ['check-payment-status-dialog.component.scss'],
})
export class CheckPaymentStatusDialogComponent {

  @Input() subscriptionAnalyticsObject;
  @Input() currentSubscriptionId;
  @Input() title: string = 'Welcome to Storydoc Pro!';
  @Input() freeSeats: number = 0;
  @Input() additionalSeats: number = 0;
  success = false;
  failure = false;
  inProgress = true;
  subscription;

  track(event, params = {}) {
    this.analyticsService.track(event, {
      ...this.subscriptionAnalyticsObject,
      ...params
    });
  }

  constructor(protected ref: NbDialogRef<CheckPaymentStatusDialogComponent>,
              private analyticsService: AnalyticsService,
              private accountsService: AccountsService) {}

ngOnInit() {
  this.subscription = this.accountsService.info(true).pipe(
    delay(2500),
    repeat(),
    delayWhen(() => timer(2500)),
    timeout(300000),
    filter(orgInfo => !!orgInfo),
    takeWhile(() => !this.success, true),
    catchError(error => {
      this.failed();
      return EMPTY;
    }),
    finalize(() => {
      if (!this.success) {
        this.failed();
      }
    })
  ).subscribe(orgInfo => {
    if (this.currentSubscriptionId) {
      return this.checkPaddleSubscriptionId(orgInfo);
    }
    
    if (orgInfo.subscriptionPlanId) {
      if(!this.inProgress) return;
      this.inProgress = false;
      this.handleSuccessfulSubscription(orgInfo);
    } else if (orgInfo.lastPaymentErrorMessage) {
      this.inProgress = false;
      this.failed();
    }
  });
}

  private handleSuccessfulSubscription(orgInfo: any) {
    const paddlePlan = environment.paddle.plans.find(plan => 
      plan.id === orgInfo.subscriptionPlanId
    );
    const data: any = {};
    
    if (paddlePlan) {
      const monthlyValue = paddlePlan.period === 'Monthly' ? 
        paddlePlan.price : 
        paddlePlan.price / 12;

      Object.assign(data, {
        plan: paddlePlan.title,
        billingPeriod: paddlePlan.period,
        monthlyValue,
        annualValue: paddlePlan.price
      });

      this.analyticsService.sendHubspotProperty({
        total_payment_amount: paddlePlan.price,
        number_of_seats: paddlePlan.seats,
        plan_billing_type: paddlePlan.period,
        plan_monthly_value: monthlyValue,
        plan_name: paddlePlan.title,
        trial_status: 'inactive',
        success_paying_customer: moment(new Date()).utc().startOf('day').valueOf()
      });
    }
    
    if (orgInfo.parameters && orgInfo.parameters.gclid) {
      data.gclid = orgInfo.parameters.gclid;
    }
    
    this.analyticsService.sendHubspotInternalEvent('success paying customer', data);
    this.updateOrgInfoWithFreeSeats();
  }

  checkPaddleSubscriptionId(orgInfo) {
    if (orgInfo.subscriptionId !== this.currentSubscriptionId) {
      this.inProgress = false;
      const paddlePlan = environment.paddle.plans.find(plan => plan.id === orgInfo.subscriptionPlanId);
      const data = {};
      if (paddlePlan) {
        data[ 'plan' ] = paddlePlan.title;
        data[ 'billingPeriod' ] = paddlePlan.period;
        data[ 'monthlyValue' ] = paddlePlan.period === 'Monthly' ? paddlePlan.price : paddlePlan.price / 12;

        this.analyticsService.sendHubspotProperty({
          total_payment_amount: paddlePlan.price,
          number_of_seats: paddlePlan.seats,
          plan_billing_type: paddlePlan.period,
          plan_monthly_value: (paddlePlan.period === 'Monthly' ? paddlePlan.price : paddlePlan.price / 12),
          plan_name: paddlePlan.title,
          trial_status: 'inactive',
          success_paying_customer: moment(new Date()).utc().startOf('day').valueOf()
        });
      }
      this.track('success upgrade customer');
      if (orgInfo.parameters && orgInfo.parameters.gclid) data[ 'gclid' ] = orgInfo.parameters.gclid;
      this.analyticsService.sendHubspotInternalEvent('success upgrade customer', data);
      this.success = true;
      this.clearSubscription();
    }
  }

  updateOrgInfoWithFreeSeats() {
    if (!this.freeSeats || !this.additionalSeats) {
      this.success = true;
      this.clearSubscription();
      return
    }
    return this.accountsService.handlePlanProperties({
      freeSeats: this.freeSeats,
      additionalSeats: this.additionalSeats,
      forceAdditionalSeats: true
    })
    .pipe(
      catchError((err: any): Observable<any> => from([null]))
    ).subscribe(account => {
      this.success = true;
      this.clearSubscription();
      if (!account) return;
      this.accountsService.updateOrgInfo(account);
    })
  }

  clearSubscription() {
    if(this.subscription) this.subscription.unsubscribe();
  }

  failed() {
    this.clearSubscription();
    this.failure = true;
    return;
  }

  close() {
    window.location.href = '/pages/home';
  }

  cancel() {
    this.ref.close(false);
  }

  ngOnDestroy() {
    this.clearSubscription();
  }

}
