import { DomSanitizer } from "@angular/platform-browser";
import { Component, OnInit, Input } from '@angular/core';
import Cropper from 'cropperjs';
import { NbDialogRef } from '@nebular/theme';
import { PagesService } from '../../services/pages.service';
import { MediaService } from '../../services/media.service';
import { RenderingService } from '../../services/rendering.service';
import { environment } from '../../../environments/environment';
import { HttpEventType } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import uuid from 'uuid';

@Component({
  selector: 'ngx-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class CropperComponent implements OnInit {
  cropper;
  uploading = false;
  loading = true;
  cropperReady = false;
  ratioH;
  ratioW;
  dataSrc;
  uploadRawToggle = false;
  progress = 0;
  progressBar = false;
  saveToFiles = false;
  cropped = false;
  changeModeFlag = true;
  invertState = false;
  ratiosArray;
  activeRatio;
  @Input() variable;
  @Input() file;
  @Input() url;
  @Input() uploadVideo = false;
  @Input() admin;

  constructor(protected ref: NbDialogRef<CropperComponent>,
              private sanitizer: DomSanitizer,
              private mediaService: MediaService,
              private renderingService: RenderingService,
              private pagesService: PagesService) { }

  ngOnInit() {
    if(this.url) {
      if(this.uploadVideo) {
        this.loading = false;
        this.saveToFiles = true;
      } else {
        this.dataSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
    } else {
      var reader = new FileReader();
      reader.onload = (e: any) => this.dataSrc = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result);
      reader.readAsDataURL(this.file); // convert to base64 string
    }
    this.ratiosArray = this.variable.validation ? this.variable.validation.ratios : [];
    if(this.ratiosArray && this.ratiosArray.length) {
      this.activeRatio = this.ratiosArray[0];
      this.ratioW = this.ratiosArray[0][0]
      this.ratioH = this.ratiosArray[0][1]
    }
  }

  initCropper(event) {
    const image = event.target;
    if(image.naturalWidth < this.variable.validation.minWidth || image.naturalHeight < this.variable.validation.minHeight) {
      this.ref.close({error: this.variable.validation.errorMessage})
    }
    if(this.isRaw) return;
    const minCropBoxWidth = this.variable.validation.minWidth,
          minCropBoxHeight = this.variable.validation.minHeight;
    const options: any = {
      autoCropArea: 1,
      minCropBoxWidth,
      minCropBoxHeight,
      crop(event) {
        // console.log(event);
      }
    }
    if(this.variable.type === "image-video" && this.ratiosArray) {
      this.ratioW = this.ratiosArray[0] ? Number(this.ratiosArray[0][0] === 0 ? 0 : this.ratiosArray[0][0] || 1) : 0;
      this.ratioH = this.ratiosArray[0] ? Number(this.ratiosArray[0][1] === 0 ? 0 : this.ratiosArray[0][1] || 1) : 0;
      options.aspectRatio = this.ratioW / this.ratioH;
    } else if(this.variable.validation.ratio) {
      this.ratioW = this.variable.validation.ratio ? Number(this.variable.validation.ratio[0] || 1) : 0;
      this.ratioH = this.variable.validation.ratio ? Number(this.variable.validation.ratio[1] || 1) : 0;
      options.aspectRatio = this.ratioW / this.ratioH;
    }
    // PATCH for uploading raw files when not touched
    const naturalRatio = image.naturalWidth / image.naturalHeight;
    if(this.url.indexOf(environment.assets) === -1 || (options.aspectRatio && options.aspectRatio !== naturalRatio)) {
      // if image ratio and variable ratio not the same - we treat it as cropped
      this.cropped = true
      this.saveToFiles = true
    } else {
      const setChanged = (event) => {
        this.saveToFiles = true
        this.cropped = true
      };
      image.addEventListener('zoom', setChanged);
      image.addEventListener('cropstart', setChanged);
    }
    options.ready = () => {
      this.loading = false;
      this.cropperReady = true;
    }
    Cropper.setDefaults({zoomOnWheel: false});
    this.cropper = new Cropper(event.target, options);
    // this.loading = false;

  }

  setRatio(ratioArray) {
    this.ratioW = ratioArray ? Number(ratioArray[0] === 0 ? 0 : ratioArray[0] || 1) : 0;
    this.ratioH = ratioArray ? Number(ratioArray[1] === 0 ? 0 : ratioArray[1]  || 1) : 0;
    this.cropper.setAspectRatio(this.ratioW / this.ratioH);
    this.activeRatio = ratioArray;
  }

  isActive(activeRatio) {
    return this.activeRatio === activeRatio;
  }

  get isRaw() {
    if(this.url) return false;
    if(this.variable.validation && this.variable.validation.uploadRaw) return true;
    return ["image/svg+xml", "image/gif", "video/mp4"].includes(this.file.type);
  }

  submitCropped() {
    if(this.uploadVideo) {
      this.loading = true;
      this.renderingService.getFileContents(this.url, {responseType: 'blob', redirect: 'follow'}).subscribe(blob => {
        this.file = blob;
        this.submitRaw();
      })
      return;
    }
    if(!this.cropped) {
      this.success(this.url.replace(environment.assets + "/", ''))
      return;
    }
    this.uploading = true;
    const image = this.cropper.getCroppedCanvas({
      maxWidth: 2000,
      maxHeight: 2000,
    }).toBlob(blob => this.uploadFilePost(blob));
  }

  submitRaw() {
    this.uploading = true;
    if(this.file.type === 'video/mp4') this.progressBar = true
    this.uploadFilePost(this.file);
  }

  uploadFile(type, data) {
    this.pagesService.getSignedUrl(type.replace(/\+/, '%2B')).subscribe(result => {
      this.pagesService.uploadFile(result.url, type, data)
      // .pipe(
      //   catchError()
      // )
      .subscribe(() => this.success(result.filename));
    });
  }

  uploadFilePost(file) {
    let contentType = file.type.replace(/\+/, '%2B');
    if(this.url.endsWith('.mp4')) contentType = "video/mp4";
    this.pagesService.getSignedPostPolicy(contentType).subscribe(result => {
      this.pagesService.uploadFilePost(result.url, file)
      .pipe(
        catchError((err: any) : Observable<any> => {
          // const errMessage = (err.error && err.error.message) ? err.error.message : 'Error getting component preview';
          // this.trackStory('failed edit component save', {
          //   'failure message': errMessage
          // });
          // this.toasterService.showToast('danger', errMessage);
          // this.loading = false;
          this.ref.close({error: 'Failed to upload media, please contact support.'})
          return throwError(err);
        })
      )
      .subscribe((res) => {
        if(res.type == HttpEventType.UploadProgress) {
          this.progress = (res.loaded / res.total) * 100;
        } else if (res.type == HttpEventType.Response) {
          // if(file.type === 'image/png') this.pagesService.compressImageRemotely(result.filename).subscribe();
          if(this.saveToFiles) {
            this.mediaService.create({
              title: file.name || uuid.v4(),
              type: contentType,
              typeGroup: contentType.split('/')[0],
              size: file.size,
              url: environment.assets + "/" + result.filename,
              folder: null
            }).pipe(
              catchError((err: any) : Observable<any> => {
                return throwError(err);
              })
            ).subscribe(mediaItem => {
              this.success(result.filename)
            })
          } else {
            this.success(result.filename)
          }
        }
      });
    });
  }

  successRaw() {
    this.ref.close({url: this.url});
  }

  success(url) {
    this.uploading = false;
    const baseUrl = environment.assets + "/";
    this.ref.close({url: baseUrl + url, filename: url});
  }

  dismiss() {
    this.ref.close();
  }

  zoomIn() {
    this.cropper.zoom(0.05)
  }
  zoomOut() {
    this.cropper.zoom(-0.05)
  }
  rotate() {
    this.cropper.rotate(45)
  }

  moveMode() {
    this.changeModeFlag = false;
    this.cropper.setDragMode('move');
  }
  cropMode() {
    this.changeModeFlag = true;
    this.cropper.setDragMode('crop');
  }

  changeModeOnDbClick() {
    this.changeModeFlag = !this.changeModeFlag;
  }

  invert() {
    this.invertState = !this.invertState;
    this.invertState ? this.cropper.scaleX(-1) : this.cropper.scaleX(1)
  }
}
