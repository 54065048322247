import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { ToasterService } from './toaster.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ComponentsService {

  constructor(private http: HttpClient,
              private toasterService: ToasterService) { }

  get(id = null): Observable<any> {
    if(id) {
      return this.http.get(environment.backend + '/components/' + id)
        .pipe(
          catchError((err: any) : Observable<any> => {
            this.toasterService.showToast('danger', 'Error getting component');
            return throwError(err)
          })
        );
    } else {
      return this.http.get(environment.backend + '/components')
        .pipe(
          catchError((err: any) : Observable<any> => {
            this.toasterService.showToast('danger', 'Error getting components');
            return throwError(err)
          })
        );
    }
  }

  getMediaItem(id) {
    return this.http.get(environment.backend + '/components/'+id)
  }

  mediaCollection() {
    return this.http.get(environment.backend + '/components/mediaCollection')
  }

  preview(id, values): Observable<any> {
    return this.http.post(environment.backend + '/components/preview/' + id, values)
      .pipe(
        catchError((err: any) : Observable<any> => {
          this.toasterService.showToast('danger', 'Error getting component preview');
          return throwError(err)
        })
      );
  }

  create(data): Observable<any> {
    return this.http.post(environment.backend + '/components', data)
      .pipe(
        catchError((err: any) : Observable<any> => {
          this.toasterService.showToast('danger', 'Error creating component');
          return throwError(err)
        })
      );

  }

  update(id, data): Observable<any> {
    return this.http.post(environment.backend + '/components/' + id, data)
      .pipe(
        catchError((err: any) : Observable<any> => {
          this.toasterService.showToast('danger', 'Error updating component');
          return throwError(err)
        })
      );
  }

  delete(id): Observable<any> {
    return this.http.delete(environment.backend + '/components/' + id)
      .pipe(
        catchError((err: any) : Observable<any> => {
          this.toasterService.showToast('danger', 'Error deleting component');
          return throwError(err)
        })
      );
  }

  createSealTheDealToken(storyId, componentId): Observable<any>  {
    return this.http.post(environment.backend + '/components/'+componentId+'/sealTheDealToken', { storyId })
  }
}


// // CREATE new component example
// {
//     "title": "API Test Component",
//     "information": {
//         "desc": "First ever component"
//     },
//     "dependenciesHTML": "<h1>Only showing once!</h1>",
//     "html": "<h1>showing for every component once!</h1>",
//     "css": "h1 { color: red; }",
//     "javascript": "alert('hello world');",
//     "variables": [{
//         "type": "input",
//         "title": "Var Title",
//         "placeholder": "Your title goes here...",
//         "default": "Default title",
//         "required": true,
//         "helpMessage": "This is the title section, fill it with your title",
//         "regex": null,
//         "errorMessage": "regex wasn't met"
//     }, {
//         "type": "toggle",
//         "title": "Toggle Title",
//         "default": true,
//         "helpMessage": "This is the toggle section, decide between on and off"
//     }],
//     "previewImage": "https://cdn.vox-cdn.com/thumbor/Ei4ydi9WDwJW-dpSW_QWMiVULNs=/0x0:2012x1341/1820x1213/filters:focal(0x0:2012x1341):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/47070706/google2.0.0.jpg"
// }
