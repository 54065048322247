import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private http: HttpClient) { }

  get(id = null): Observable<any> {
    if(id) {
      return this.http.get(environment.backend + '/pages/' + id);
    } else {
      return this.http.get(environment.backend + '/pages');
    }
  }

  getSlides(id: string): Observable<any> {
    return this.http.get(environment.backend + `/pages/${id}/slides`);
  }

  getTemplate(id = null): Observable<any> {
    let path = '/pages/template';
    if(id) path += '?id=' + id;
    return this.http.get(environment.backend + path);
  }

  getTemplates(): Observable<any> {
    return this.http.get(environment.backend + '/pages/templates');
  }

  getMany({ status, folderId = 'all' }) {
    let query = '?';
    if (status && status != '') query += 'status=' + status;
    if (folderId) query += `${(status && status != '') ? '&' : ''}folderId=${folderId}`
    return this.http.get(environment.backend + '/pages' + query);
  }

  getRecent() {
    return this.http.get(environment.backend + '/pages/recent');
  }

  getPagesByComponentId(componentId) {
    return this.http.get(environment.backend + '/pages/component/' + componentId);
  }

  count(params: any = {}): Observable<any> {
    const { status, onlyOwnStories, presetId, folder } = params;
    let query = '';
    if(status && status != '') query += '?status=' + status;
    if (onlyOwnStories) query += query ? '&onlyOwnStories=' + onlyOwnStories : '?onlyOwnStories=' + onlyOwnStories;
    if (presetId) query += (query ? '&' : '?') + 'presetId=' + presetId;
    if (folder) query += (query ? '&' : '?') + 'folder=' + folder;
    return this.http.get(environment.backend + '/pages/count' + query);
  }


  getHTML(id): Observable<any> {
    return this.http.get(environment.backend + '/pages/html/' + id);
  }

  delete(id): Observable<any> {
    return this.http.delete(environment.backend + '/pages/' + id);
  }

  checkUpdated(id, updatedAt): Observable<any> {
    return this.http.get(environment.backend + '/pages/' + id + '/checkUpdated?updatedAt=' + updatedAt);
  }

  lockStory(id, lockStoryData): Observable<any> {
    return this.http.post(environment.backend + '/pages/' + id + '/lock', lockStoryData);
  }

  updateDesignSystem(id, designSystem): Observable<any> {
    return this.http.post(environment.backend + '/pages/' + id + '/updateDesignSystem', {designSystem});
  }

  update(id, page): Observable<any> {
    return this.http.post(environment.backend + '/pages/v2/' + id, this.getSlimPage(page));
  }

  previewPage(id, page): Observable<any> {
    return this.http.post(environment.backend + '/pages/' + id + '/preview', this.getSlimPage(page));
  }

  updateStatus(id, status): Observable<any> {
    return this.http.post(environment.backend + '/pages/update-status/' + id, {status});
  }

  create(page): Observable<any> {
    return this.http.post(environment.backend + '/pages/v2', this.getSlimPage(page));
  }

  minify(pageId): Observable<any> {
    return this.http.post(environment.backend + '/pages/v2/minify/' + pageId, {});
  }

  getUpdatedPreviewURL(story: Record<string, any>, cssURL?: string, screenshot?: boolean) {
    return this.http.post(`${environment.backend}/pages/update-preview`, { story, screenshot, cssURL });
  }

  getSlimPage(page) {
    // const pageComponents = page.pageComponents || [];
    let pageComponents = null;
    if(!page.contentPointer && page.pageComponents) { // old sturcture support
      pageComponents = page.pageComponents.map(pc => ({
        componentId: pc.componentId,
        componentType: pc.componentType,
        previewImage: pc.previewImage,
        design: pc.design,
        values: pc.values,
        url: pc.url,
        lastUpdated: pc.lastUpdated,
      }))
    }
    return {
      ...page,
      rendered: null,
      components: null,
      pageComponents,
    }
  }

  publish(id, page): Observable<any> {
    return this.http.post(environment.backend + '/pages/v2/save-and-publish/' + id, this.getSlimPage(page));
  }

  getPageMetada(id = null): Observable<any> {
    return this.http.get(environment.backend + '/pages/get-page-metadata/' + id);
  }

  publishVersion(id, data, isPublic, expireAt, contentPointer = null, piwik = false): Observable<any> {
    return this.http.post(environment.backend + '/pages/publish-version/' + id, {
      data,
      isPublic,
      expireAt,
      contentPointer,
      piwik
    });
  }

  activateAPI(id) {
    return this.http.get(environment.backend + '/pages/' + id + '/token');
  }

  previewVersion(pageId, data): Observable<any> {
    return this.http.post(environment.backend + '/pages/preview-version/' + pageId, { data });
  }

  getVersion(versionId): Observable<any> {
    return this.http.get(environment.backend + '/versions/' + versionId);
  }

  updateVersionV2(id, version): Observable<any> {
    return this.http.post(environment.backend + '/versions/v2/' + id, version);
  }

  updatePublishedVersion(id, data, isSyncToMaster): Observable<any> {
    return this.http.post(environment.backend + '/versions/' + id, {
      data,
      isSyncToMaster
    });
  }

  deleteVersion(id): Observable<any> {
    return this.http.delete(environment.backend + '/versions/'+id);
  }

  deletePermanentlyVersion(id): Observable<any> {
    return this.http.delete(environment.backend + '/versions/'+id+'/permanently');
  }

  searchVersions(pageId: string, search: string, page: number, size: number): Observable<any> {
    return this.http.get(environment.backend + `/versions/search?pageId=${pageId}&search=${search}&page=${page}&size=${size}`);
  }

  getSignedUrl(type): Observable<any> {
    return this.http.get(environment.backend + '/pages/getSignedUrl?contentType='+type);
  }

  getSignedPostPolicy(type, bucket = null): Observable<any> {
    return this.http.get(environment.backend + '/pages/getSignedPostPolicy?contentType='+type);
  }

  getSignedPostPolicyFromBucket(type, bucket = null): Observable<any> {
    return this.http.get(environment.backend + '/pages/getSignedPostPolicyFromBucket?contentType='+type+'&bucket='+bucket);
  }

  compressImageRemotely(filename): Observable<any> {
    return this.http.get(environment.backend + '/pages/compressImageRemotely?filename='+filename);
  }

  getLocalAsset(url) {
    return this.http.get(url, {responseType: 'text'});
  }

  uploadFile(url, type, data): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", type);
    return this.http.put(url, data, { headers });
  }

  uploadFilePost(data, file): Observable<any> {
    const formData = new FormData();
    for(let key in data.fields) {
      formData.append(key, data.fields[key]);
    }
    formData.append('file', file);
    return this.http.post(data.url, formData, {reportProgress: true, observe: "events"})
  }

  renderSass(designSystem) {
    return this.http.post(environment.backend + '/pages/renderSass', {data: designSystem});
  }

  compileDesignSystemAndUpload(data, mode, uuid) {
    return this.http.post(environment.backend + '/pages/compileDesignSystemAndUpload', {data, mode, uuid});
  }

  componentPreview(id, values): Observable<any> {
    return this.http.post(environment.backend + '/components/preview/' + id, values);
  }

  pageComponentPreview(pageSettings, componentId, values = null): Observable<any> {
    return this.http.post(environment.backend + '/pages/preview/' + componentId, {
      pageSettings,
      values
    })
  }

  // gets the entire pageComponent object, instead of id and values (as in pageComponentPreview)
  previewPageComponent(pageSettings, pageComponent, returnRendered = false, returnFull = false): Observable<any> {
    return this.http.post(environment.backend + '/pages/previewPageComponent', {
      pageSettings,
      pageComponent,
      returnRendered,
      returnFull
    })
  }

  debugPageComponent(pageSettings, pageComponent): Observable<any> {
    return this.http.post(environment.backend + '/pages/debugPageComponent', {
      pageSettings,
      pageComponent
    })
  }

  getComponents(query = {}) {
    return this.http.get(environment.backend + '/components', {params: query});
    // .pipe(
    //   catchError((err: any) : Observable<any> => {
    //     this.showToast('danger', 'Error getting component', 'An internal error occured fetching components, please try again in few minutes.');
    //     return throwError(err)
    //   })
    // )
  }

  getComponentRendered(id, values) {
    return this.http.post(environment.backend + '/component/preview/' + id, values);
  }

  getComponent(id) {
    return this.http.get(environment.backend + '/components/' + id);
  }

  checkAvailability(text, field, id) {
    text = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    if(!this.isTextInputValid(text)) return from([{available: false}])
    // let query = '?' + field + '=' + text;
    // if(id) query += '&id=' + id;
    const body = {
      field,
      text,
      id
    }
    return this.http.post(environment.backend + '/pages/check-availability', body);
  }

  isTextInputValid(text) {
    if(!text) return false;
    try {
      new RegExp(`^${text}$`, 'i');
      return true
    } catch(err) {
      return false
    }
  }

  getHistory(id, pageToken = null) {
    const qs = pageToken ? `pageToken=${pageToken}` : ``;
    return this.http.get(`${environment.backend}/pages/${id}/history?${qs}`);
  }

  getVersions(id, batchSize, batch, storySender = null, searchTerm = null) {
    const limit = batchSize;
    const offset = batch * limit;
    const senderQuery = storySender ? `&sender=${storySender}` : '';
    const search = searchTerm ? `&searchTerm=${searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}` : '';
    return this.http.get(`${environment.backend}/pages/${id}/versions?offset=${offset}&limit=${limit}${senderQuery}${search}`);
  }

  getVersionsCount(id) {
    return this.http.get(`${environment.backend}/pages/${id}/active-versions/count`);
  }

  getActiveVersions(id) {
    return this.http.get(`${environment.backend}/pages/${id}/active-versions`);
  }

  bulkSend(storyId, params = {}){
    return this.http.post(environment.backend + `/pages/${storyId}/bulkSend`, params);
  }
}
