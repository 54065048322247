import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AnalyticsService } from "../../../services/analytics.service";

@Component({
  selector: "ngx-update-billing-details-success-toast",
  templateUrl: "./update-billing-details-success-toast.component.html",
  styleUrls: ["./update-billing-details-success-toast.component.scss"],
})
export class UpdateBillingDetailsSuccessToastComponent implements OnInit {
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  constructor(public analyticsService: AnalyticsService) {
    this.track("view update billing success toast");
  }

  track(event: string, params = {}) {
    this.analyticsService.track(event, {
      ...params,
      "user status": "",
    });
  }

  ngOnInit() {
    window.localStorage.removeItem("updatedBilling");
  }

  close() {
    this.track("click close update billing success toast");
    this.onClose.emit();
  }
}
