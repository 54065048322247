import {Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild} from "@angular/core";
import {AccountsService} from '../../../services/accounts.service';
import {AuthService} from "../../../auth.service";
import { userMenu } from "./user-menu";
import { SubscriptionDialogFacade } from "../../../pages/profile/subscription/subscription-dialog.facade";
import { SenderDataService } from "../../../pages/account/account.service";
import { map, startWith } from "rxjs/operators";
import { fromEvent } from "rxjs";

type ActionType = {
  action: string;
  title: string;
  icon: string;
  types: string[];
  isPro?: boolean;

}

@Component({
  selector: 'ngx-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})

export class UserMenuComponent {
  @ViewChild('container', { static: true }) container: ElementRef;
  @Input() isMenuVisible: boolean;
  @Output() onMenuItemClick = new EventEmitter<string>();
  @Output() onCloseMenu = new EventEmitter<void>();
  userMenu = userMenu;
  isStarterOrTrial: boolean;
  keyToShow: string;
  isMobile$ = fromEvent(window, 'resize').pipe(
    startWith(window.innerWidth < 1200),
    map(() => window.innerWidth < 1200)
  );
  userType$ = this.auth.getUserType$();
  senderData$ = this.senderDataService.personalInfoData$.pipe(
    map(data => {
      const sender = data ? data.sender : null;
      if (!sender) return null;
      const { name, email, picture } = sender;
      const initials = name.split(' ').slice(0, 2).map((n) => n[ 0 ]).join('');
      return { name, email, picture, initials };
    })
  )

  constructor(
    private subscriptionDialogFacade: SubscriptionDialogFacade,
    private accountsService: AccountsService,
    private senderDataService: SenderDataService,
    private renderer: Renderer2,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.isStarterOrTrial = this.accountsService.isStarterOrTrial;
    if(this.accountsService.isTeam) {
      this.keyToShow = 'team';
    } else if(!this.accountsService.isPaid) {
      this.keyToShow = 'trial';
    }
  }

  ngAfterViewInit() {
    this.addClickListener();
  }

  onClick(item: ActionType) {
    if (item.isPro && this.isStarterOrTrial) {
      this.subscriptionDialogFacade.upgradeToProDialog({ source: 'main navigation' });
      return;
    }
    this.onMenuItemClick.emit(item.action);
  }

  private addClickListener() {
    this.renderer.listen(document, 'click', (event: any) => {
      if (
        this.isMenuVisible
        && !event.target.closest('.user-container')
        && !this.container.nativeElement.contains(event.target as HTMLElement)
      ) {
        this.onCloseMenu.emit();
      }
    });
  }
}
