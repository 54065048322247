import { Component, Input } from "@angular/core";
import { SubscriptionFacade } from "../../../pages/profile/subscription/subscription.facade";
import { AccountsService } from "../../../services/accounts.service";
import { SubscriptionDialogFacade } from "../../../pages/profile/subscription/subscription-dialog.facade";

@Component({
  selector: "ngx-team-plan-label",
  templateUrl: "team-plan-label.component.html",
  styleUrls: ["team-plan-label.component.scss"],
})
export class TeamPlanLabelComponent {
  @Input() disableClick: boolean = false;
  @Input() source: string = '';
  @Input() icon: boolean = false;
  constructor(private subscriptionFacade: SubscriptionFacade,
              private subscriptionDialogFacade: SubscriptionDialogFacade,
              private accountsService: AccountsService) {
  }

  isTeam: boolean = false;
  ngOnInit() {
    this.isTeam = this.accountsService.isTeam;
  }
  upgradeToTeam() {
    if (this.disableClick) return;
    if (this.isTeam) return this.subscriptionFacade.handleHelpButton('team org open help center');
    this.subscriptionDialogFacade.upgradeToTeamDialog(this.source);
  }
}
