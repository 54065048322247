import { Component, Input } from "@angular/core";
import { Router } from '@angular/router';
import { NbDialogRef } from "@nebular/theme";
import { WorkspaceFacade } from "../workspace.facade";

@Component({
    selector: 'ngx-workspace-success-join',
    templateUrl: 'workspace-success-join.component.html',
    styleUrls: [ 'workspace-success-join.component.scss' ],
})
export class WorkspaceSuccessJoinDialogComponent {

    constructor(private workspaceFacade: WorkspaceFacade,
                protected ref: NbDialogRef<WorkspaceSuccessJoinDialogComponent>,
        public router: Router) { }
    
    @Input() workspace: string;

    close() {
        this.ref.close();
        this.workspaceFacade.track('congratulations popup | click go to storydoc');
    }

    navigateToHelpPage() {
        this.ref.close();
        this.router.navigateByUrl('/pages/help')
        this.workspaceFacade.track('congratulations popup | click how to videos');
    }

    ngOnDestroy() {
        this.workspaceFacade.deleteCookie(this.workspaceFacade.successKey);
    }
}