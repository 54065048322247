import { Component, EventEmitter, Output } from "@angular/core";
import { catchError } from "rxjs/operators";
import { BehaviorSubject, throwError } from "rxjs";
import { WorkspaceService } from "../workspace.service";
import { WorkspaceFacade } from "../workspace.facade";
import { ToasterService } from "../../../services/toaster.service";
import { OVERLAY_STATUS } from "../workspace.interface";

@Component({
    selector: 'ngx-workspace-join',
    templateUrl: 'workspace-join.component.html',
    styleUrls: [ 'workspace-join.component.scss' ],
})
export class WorkspaceJoinComponent {

    constructor(private workspaceService: WorkspaceService,
                private toasterService: ToasterService,
                private workspaceFacade: WorkspaceFacade) { }

    @Output() close = new EventEmitter<void>();
    sameDomainWorkspace$: BehaviorSubject<Record<string, any>> = this.workspaceFacade.sameDomainWorkspace$;
    loading: boolean = false;


    joinWorkspace() {
        this.loading = true;
        this.workspaceFacade.track('click join workspace');
        this.workspaceService.sendMigrationInvitationLink(this.sameDomainWorkspace$.value._id)
        .pipe(
            catchError(err => {
                this.loading = false;
                this.workspaceFacade.track('failed send verification email join workspace', {'failure message': err.message});
                this.toasterService.showToast('danger', 'Failed to join', 'Please contact out support');
                return throwError(err);
            })
        )
        .subscribe(() => {
            this.loading = false;
            this.workspaceFacade.track('success send verification email join workspace');
            this.workspaceFacade.joinWorkspaceOverlayStatus$.next(OVERLAY_STATUS.VERIFICATION);
        })
    }

    createOwnWorkspace() {
        this.workspaceFacade.track('click create new workspace');
        this.close.emit()
        this.workspaceFacade.joinWorkspaceOverlayStatus$.next(null); 
        this.workspaceFacade.openWizard();
    }
}