import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import { personalData, IUserPlan } from '../pages/account/personal-data.interface';


@Injectable({
  providedIn: 'root'
})
export class ReactDataService {
  public orgInfo$ = new BehaviorSubject<any>(null);
  public mediaLibrary$ = new Subject<any>();
  public liveLinkImageUrl$ = new Subject<any>();
  public userPlan$ = new BehaviorSubject<IUserPlan>(null);
  public profileData$ = new BehaviorSubject<personalData>(null);
  public userRole$ = new BehaviorSubject<string>(null);
}
