import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AiService {

  constructor(private http: HttpClient) { }

  FETCH_CLASSIFICATION_URL = 'https://www.storydoc.com/assets/json/ai-classification.json';
  FETCH_ICONS_URL = 'https://www.storydoc.com/assets/json/remixicon.json';

  showImageLimit(): Observable<any> { // admin only
    let url = environment.backend + '/ai/showImageLimit';
    return this.http.get(url);
  }

  generateImage(prompt): Observable<any> {
    let url = environment.backend + '/ai/generateImage';
    return this.http.post(url, {prompt});
  }

  uploadRemoteFile(url): Observable<any> {
    return this.http.post(environment.backend + '/ai/uploadRemoteFile', {url});
  }

  getAIPrompt(prompt: string, disableRateLimit: boolean = false): Observable<any> {
    return this.http.post(environment.backend + '/ai/template-prompt', {prompt, disableRateLimit});
  }

  getClassifications() {
    return this.http.get(this.FETCH_CLASSIFICATION_URL);
  }

  getIcons() {
    return this.http.get(this.FETCH_ICONS_URL);
  }

}
