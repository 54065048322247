import {Component, OnInit, OnChanges, ViewChild, ElementRef, Input, Output, EventEmitter, Optional} from '@angular/core';
import {NbDialogRef, NbDialogService} from '@nebular/theme';

//This autocomplete code is from NPM - 'angular-ng-autocomplete' - https://www.npmjs.com/package/angular-ng-autocomplete

@Component({
  selector: 'ngx-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class Autocomplete {

  @ViewChild('auto', { static: true }) auto;

  constructor(private dialogService: NbDialogService,
              @Optional() private ref: NbDialogRef<Autocomplete>) {
  }

  currentItem;
  @Input() dataArray = [];
  @Input() initialValue = '';
  @Input() placeholder;
  @Input() keyword = 'name';
  @Input() allowEmptyValue = false;
  @Input() clearAutocomplete;
  @Input() nbInput: boolean;
  @Output() selectedItem = new EventEmitter<any>();
  @Output() inputChanges = new EventEmitter<any>();


  ngOnInit() { }
  ngAfterViewInit() {
    this.inputStyleHandler();
  }
  ngOnChanges(changes) {
    if(changes.dataArray && this.dataArray && this.dataArray.length && !this.dataArray[0].id) {
      this.dataArray.map((dataItem, index) => {
        return dataItem.id = index;
      });
    }
    if(changes.clearAutocomplete && changes.clearAutocomplete.currentValue) {
      this.auto.clear();
      this.auto.close();
    }
  }

  inputStyleHandler() {
    if (!this.auto || !this.nbInput) return

    const nbClasses = 'ng-pristine size-medium shape-rectangle ng-star-inserted';
    const input = this.auto.searchInput.nativeElement;
    if (!input) return
    input.setAttribute('nbInput', true)
    const inputClasses = input.getAttribute('class');

    if (inputClasses) input.setAttribute('class', `${inputClasses} ${nbClasses}`)
    else input.setAttribute('class', inputClasses)
  }

  selectEvent(item) {
    // do something with selected item
    if(this.currentItem === item) return;
    this.selectedItem.emit(item);
    this.currentItem = item;
  }

  inputChanged(value: string) {
    this.inputChanges.emit(value);
  }
  
  clearInput(event) {
    if(this.allowEmptyValue) this.selectedItem.emit(null)
  }

}
