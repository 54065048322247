import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, retryWhen, take } from 'rxjs/operators';

declare const window: any;

//TODO: DELETE window.isReactAppReady. It is not used in V5.
@Injectable({
  providedIn: 'root'
})
export class ReactFacade {

  initializeApp$() {
    return of(null).pipe(
      map(() => {
        const isReady = window.isReactAppReady || window.REACT_APP_READY;
        if (isReady) return isReady;
        throw isReady;
      }),
      retryWhen(errors => errors.pipe(delay(500), take(120))),
      take(1), // take for 1 min
      catchError((err: any): Observable<any> => throwError(err))
    )
  }


}
