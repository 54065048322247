import { Injectable } from "@angular/core";
import { CookiesService } from "../../services/cookies.service";
import { AuthService } from "../../auth.service";
import { AnalyticsService } from "../../services/analytics.service";
import { BehaviorSubject, throwError } from "rxjs";
import { WorkspaceService } from "./workspace.service";
import { OVERLAY_STATUS } from "./workspace.interface";
import { ToasterService } from "../../services/toaster.service";
import { AccountsService } from "../../services/accounts.service";
import { catchError, filter, take } from "rxjs/operators";
import { WizardFacade } from "../wizard/wizard.facade";

@Injectable({
    providedIn: "root",
})
    
export class WorkspaceFacade { 

    constructor(private cookiesService: CookiesService,
                private auth: AuthService,
                private workspaceService: WorkspaceService,
                private accountsService: AccountsService,
                private wizardFacade: WizardFacade,
                private toasterService: ToasterService,
                private analyticsService: AnalyticsService) {}


    invitationKey: string = 'sd-invitation-link-data';
    validationKey: string = 'sd-verification-link-data';
    successKey: string = 'sd-success-join-message';
    expiredKey: string = 'sd-expired-invitation';
    currentUserOktaId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    hasInvitation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    sameDomainWorkspace$: BehaviorSubject<Record<string, any>> = new BehaviorSubject<Record<string, any>>(null);
    joinWorkspaceOverlayStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);



    getCookie(key?: string): string {
        const cookieKey = key || this.invitationKey;
        return this.cookiesService.getCookie(cookieKey);
    }

    setCookie(key: string, value: string|boolean): void {
        this.cookiesService.setCookie(key, value);
    }

    deleteCookie(key?: string): void {
        const cookieKey = key || this.invitationKey;
        this.cookiesService.deleteCookie(cookieKey);
    }

    getOrgInfo$() {
        return this.accountsService.getOrgInfo$().pipe(take(1))
    }


    refreshSession() {
        this.setCookie(this.successKey, true);
        if (this.auth.isCustomToken) { // Okta users must be logged in again to apply changes
            this.showToast( "success", "Success updating user", "Please log in again." );
            return setTimeout(() => this.auth.logout(), 2000);
        }
        this.auth.login();
    }

    logout() {
        this.auth.logout();
    }

    track(event: string, payload: Record<string, unknown> = {}) {
        this.analyticsService.track(event, payload)
    }

    openWizard() {
        this.wizardFacade.openWizard$.next();
    }

    getUserMigrationToken() { 
        const data = this.getCookie(this.validationKey);
        if (!data) return null;
        try {
            const parsed = JSON.parse(data);
            return parsed;
        } catch (e) {
            return null;
        }
    }

    
    validateMigrationTokenAndMigrate() {
        const data = this.getUserMigrationToken();
        if (!data) return;
        this.joinWorkspaceOverlayStatus$.next(OVERLAY_STATUS.VALIDATING);
        this.workspaceService.validateWorkspaceMigrationEmail(data[ 'verification_token' ])
        .pipe(
            filter(res => res && res.status),
            catchError((err) => {
                this.showToast( "danger", "Failed to validate email", "Please try again" );
                this.deleteCookie(this.validationKey);
                this.joinWorkspaceOverlayStatus$.next(OVERLAY_STATUS.VERIFICATION);
                return throwError(err);
            })
        )
        .subscribe(() => {
            this.deleteCookie(this.validationKey);
            this.refreshSession();
        });
    }


    readTokenData(token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }


    showToast(status: any, title: string, subtitle?: string) {
        this.toasterService.showToast(status, title, subtitle);
    }

    verifyInvitation() {
        const hasInvitation = this.getCookie('sd-invitation-link-data');
        if (!hasInvitation) return;
        try {
            const parsed = JSON.parse(hasInvitation);
            const token = parsed[ 'invitation_token' ];
            return token || null;
        } catch (e) {
            this.deleteCookie('sd-invitation-link-data');
            return;
        }
    }

    isCreatedAfter10August(createdAt: string) {
        return new Date(createdAt).getTime() > new Date('2023-08-09T09:00:00.000+00:00').getTime()
    }    

}