import { Component } from "@angular/core";

@Component({
    selector: 'ngx-downtime',
    styleUrls: [ './downtime.component.scss' ],
    templateUrl: './downtime.component.html',
})
    
export class DowntimeComponent { 

    constructor() {
    }
    hide: boolean = false;

    ngOnInit(): void { 
        if(window.location.href.includes('downtime=false')) {
            this.hide = true;
        }
    }
}