import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import mixpanel from "mixpanel-browser";

@Injectable({
  providedIn: "root",
})
export class MixpanelService {
  constructor() {
    mixpanel.init(environment.mixpanel, {
      persistence: "localStorage",
      debug: environment.env !== "production",
    });
  }

  track(eventName: string, properties?: any) {
    mixpanel.track(eventName, properties);
  }

  identify(userId: string) {
    mixpanel.identify(userId);
  }

  alias(anonymousId: string, userId: string) {
    mixpanel.alias(userId, anonymousId);
  }

  reset() {
    mixpanel.reset();
  }

  setPeople(properties: any) {
    mixpanel.people.set(properties);
  }

  register(key: string, value: unknown) {
    mixpanel.register({ [key]: value });
  }

  bulkRegister(properties: Record<string, unknown>) {
    mixpanel.register(properties);
  }

  unregister(keys: string[]) {
    for (let key of keys) {
      mixpanel.unregister(key);
    }
  }
    
  get_property(key: string) {
    return mixpanel.get_property(key);
  }

  get instance() {
    return mixpanel;
  }
}