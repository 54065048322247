import { HeaderStepProps } from "../../types";

export const headerSteps: HeaderStepProps[] = [
  {
    title: "What you want to create",
    status: "active",
    action: "type",
    requiredValue: 'type'
  },
  {
    title: "Brand name",
    status: "basic",
    action: "company",
    requiredValue: "product",
  },
  {
    title: "Company's details",
    status: "basic",
    action: "details",
    requiredValue: "product",
  },
  {
    title: "Your industry",
    status: "basic",
    action: "industry",
    requiredValue: 'industry'
  },
  {
    title: "Choose design",
    status: "basic",
    action: "design",
  },
  {
    title: "Edit",
    status: "basic",
    action: "edit",
  },
];
