import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common'
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'

interface formFieldsData {
  topic: string;
  subject: string;
  messagePlaceholder?: string;
}
interface HelpData extends formFieldsData {
  message: string;
  email: string;
  name: string;
  date: string;
  organization: string;
  plan: string;
  isEditorV4: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class HelpCenterService {
  page: any = null;

  constructor(private http: HttpClient,
              private router: Router,
              private location: Location,
              ) {
                this.router.events.subscribe((event) => {
                  if (event instanceof NavigationEnd) {
                    this.history.push(event.urlAfterRedirects)
                  }
                })
              }

  topics: string[] = [ 'Account', 'Billing and plans', 'Bugs', 'Story editing and features', 'Story sending', 'Integrations', 'Analytics', 'Services', 'Other' ];
  formFieldsData$ = new BehaviorSubject<formFieldsData | null>(null);
  private history: string[] = []


  setPage(page: any) {
    this.page = page;
  }

  sendForm(data: HelpData): Observable<any> {
    return this.http.post(environment.backend + '/help-center', data)
  }

  setFormFieldsData(data: formFieldsData) {
    this.formFieldsData$.next(data);
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) this.location.back()
    else this.router.navigateByUrl('/pages/home');

    if (this.page) {
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('openShareDialog', {
          detail: {
            pageId: this.page._id,
          }
        }));
      }, 1000);
    }
  }

  get getTopics() {
    return this.topics;
  }

}
