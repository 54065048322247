import { Component, EventEmitter, Output } from "@angular/core";
import { WorkspaceFacade } from "../workspace.facade";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, filter, map, mergeMap, take, tap } from "rxjs/operators";
import { Observable, from, of, throwError } from "rxjs";
import { WorkspaceService } from "../workspace.service";
import { UsersService } from "../../../services/usersV2.service";
import { OVERLAY_STATUS } from "../workspace.interface";
import { NbDialogService } from "@nebular/theme";
import { WorkspaceJoinLibraryComponent } from "../workspace-join-library/workspace-join-library.component";
import { WorkspaceExpiredMessageDialogComponent } from "../workspace-expired-message/workspace-expired-message.component";
import { WorkspaceSuccessJoinDialogComponent } from "../workspace-success-join/workspace-success-join.component";
import { freeDomains } from "../domains";
import { AuthService } from "../../../auth.service";
import * as moment from "moment";

@Component({
  selector: "ngx-workspace",
  templateUrl: "workspace.component.html",
  styleUrls: ["workspace.component.scss"],
})
export class WorkspaceComponent {
  constructor(
    private workspaceFacade: WorkspaceFacade,
    private workspaceService: WorkspaceService,
    private dialogService: NbDialogService,
    private usersService: UsersService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      const token = params["verification_token"];
      const invitationToken = params["invitation_token"];
      if (token) this.setValidationTokenToCookies(token);
      if (invitationToken) this.setInvitationTokenToCookies(invitationToken);
    });
  }

  hasInvitation$ = this.workspaceFacade.hasInvitation$;
  joinWorkspaceOverlayStatus$ = this.workspaceFacade.joinWorkspaceOverlayStatus$;
  orgInfo: Record<string, any>
  isSuccess: boolean = false;

  ngOnInit() {
    this.initWorkspace();
    this.joinWorkspaceDialog();
    this.validateOrgInvitation();
    this.expiredInvitationTokenDialog();
    this.refreshSession();
  }

  initWorkspace() {
    this.workspaceFacade
      .getOrgInfo$()
      .subscribe(info => {
        this.orgInfo = info;
        const expired = this.workspaceFacade.getCookie(this.workspaceFacade.expiredKey);
        if (!expired) this.joinWorkspaceDomainHandler(info);
        this.successJoinWorkspaceDialog();
      });
  }

  joinWorkspaceDomainHandler(info: Record<string, any>) {
    this.usersService.getCurrentUser$().pipe(
      tap((user) => {
        if (user && !user.firstLogin && this.workspaceFacade.isCreatedAfter10August(user.createdAt)) {
          this.userFirstLoginHandler(!!info.firstLogin);
        }
      }),
      mergeMap((user: Record<string, any>) => this.handleWorkspaceView$(user)),
      catchError((err: any): Observable<null> => this.handleWorkspaceUserError$(err.status)),
      take(1),
      filter((data) => data && data.length > 0),
      map(orgs => orgs[ 0 ])
    ).subscribe(organization => {
      if (organization._id === info._id || !!organization.cancelAt || this.isTrialEnded(organization.trialEnd)) return;
      this.workspaceFacade.deleteCookie(this.workspaceFacade.expiredKey);
      this.workspaceFacade.sameDomainWorkspace$.next(organization);
      this.workspaceFacade.joinWorkspaceOverlayStatus$.next(OVERLAY_STATUS.DOMAIN);
    });
  }

  isTrialEnded(trialEnd: string) {
    if (!trialEnd) return false;
    try {
      const now = moment(new Date());
      const end = moment(trialEnd);
      const duration = moment.duration(end.diff(now));
      const days = duration.asDays();
      return days <= 0;
    } catch (e) {
      return false;
    }
  }

  refreshSession() {
    this.usersService.refreshSession$.pipe(take(1)).subscribe(() => {
      this.workspaceFacade.setCookie(this.workspaceFacade.successKey, true);
      this.workspaceFacade.refreshSession();
    })
  }

  handleWorkspaceView$(user: Record<string, any>) {
    this.workspaceFacade.currentUserOktaId$.next(user.oktaId);

    if (this.isVerificationEmailState(user.verificationToken)) {
      this.workspaceFacade.joinWorkspaceOverlayStatus$.next(OVERLAY_STATUS.VERIFICATION);
      return of(null);
    }
    if (!this.orgInfo.firstLogin && !this.orgInfo.emailDomain && !this.isUserFreeDomain(user.email)) return this.getDomainAccount$();
    return of(null);
  }

  handleWorkspaceUserError$(status: number) {
    if (status === 500) {
      this.auth.getAdminCore$().pipe(take(1)).subscribe(admin => {
        if (admin) return;
        this.workspaceFacade.setCookie(this.workspaceFacade.successKey, true);
        this.workspaceFacade.refreshSession();
      })
    }
    return of(null);
  }

  userFirstLoginHandler(firstLogin:boolean) {
    this.usersService.firstLogin().pipe(catchError(() => from([null]))).subscribe();
    if (firstLogin && !this.workspaceFacade.getCookie(this.workspaceFacade.expiredKey)) {
      this.workspaceFacade.setCookie(this.workspaceFacade.successKey, true);
      this.successJoinWorkspaceDialog();
    }
  }

  isUserFreeDomain(email: string) {
    const domain = email.split('@')[ 1 ];
    return freeDomains.includes(domain);
  }

  isVerificationEmailState(verificationToken?: string) {
    return !!verificationToken && !this.workspaceFacade.joinWorkspaceOverlayStatus$.getValue()
  }


  getDomainAccount$() {
    return this.workspaceService.getDomainOrg().pipe(catchError(() => from([null])));
  }

  validateOrgInvitation() {
    this.workspaceFacade.hasInvitation$.next(!!this.workspaceFacade.getCookie());
  }

  discardInvitation() {
    this.workspaceFacade.hasInvitation$.next(false);
  }

  validateMigrationTokenAndMigrate() {
    this.workspaceFacade.validateMigrationTokenAndMigrate();
  }

  joinWorkspaceDialog() {
    this.joinWorkspaceOverlayStatus$.pipe(filter((res) => res !== null),take(1))
    .subscribe(() => this.dialogService.open(WorkspaceJoinLibraryComponent, { context: {} }));
  }

  expiredInvitationTokenDialog() {
    const isExpired = this.workspaceFacade.getCookie(this.workspaceFacade.expiredKey);
    if (isExpired) this.dialogService.open(WorkspaceExpiredMessageDialogComponent)
  }

  successJoinWorkspaceDialog() {
    if (this.isSuccess) return;
    const cookie = this.workspaceFacade.getCookie(this.workspaceFacade.successKey);
    if (cookie) {
      this.isSuccess = true;
      this.dialogService.open(WorkspaceSuccessJoinDialogComponent, {context: {workspace: this.orgInfo.emailDomain || this.orgInfo.title}});
    }
  }

  setValidationTokenToCookies(token: string) {
    this.router.navigate([], { queryParams: { verification_token: null } });
    this.workspaceFacade.setCookie(
      this.workspaceFacade.validationKey,
      JSON.stringify({ verification_token: token })
    );
    this.validateMigrationTokenAndMigrate();
  }

  setInvitationTokenToCookies(token: string) {
    this.router.navigate([], { queryParams: { invitation_token: null } });
    this.workspaceFacade.setCookie(
      this.workspaceFacade.invitationKey,
      JSON.stringify({ invitation_token: token })
    );
  }
}
