import { Component } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { Router } from "@angular/router";
import { NbDialogService } from "@nebular/theme";

import { BlockProUpgradeDialogComponent } from "../../pages/profile/subscription/block-pro-upgrade-dialog/block-pro-upgrade-dialog.component";
import { AuthService } from "../../auth.service";
import { AnalyticsService } from "../../services/analytics.service";
import { HelpCenterService } from "../../pages/help-center/help.service";

@Component({
  selector: "ngx-trial-has-ended-dialog",
  templateUrl: "./trial-has-ended-dialog.component.html",
  styleUrls: ["./trial-has-ended-dialog.component.scss"],
})
export class TrialHasEndedDialogComponent {
  isAdministrator: boolean = false;
  constructor(
    protected ref: NbDialogRef<TrialHasEndedDialogComponent>,
    private auth: AuthService,
    private dialogService: NbDialogService,
    public analyticsService: AnalyticsService,
    public helpCenterService: HelpCenterService,
    public router: Router
  ) {
    this.auth
      .isAdministrator$()
      .subscribe((res) => (this.isAdministrator = res));

    this.track("view trial has ended dialog");
  }

  track(event: string, params = {}) {
    this.analyticsService.track(event, {
      "user is administrator": this.isAdministrator,
      screen: "trial has ended dialog",
      ...params,
    });
  }

  subscriptionExpiredFoundContact() {
    const data = {
      topic: 'Billing and plans',
      subject: 'I have a question about Storydoc plans',
    }
    this.helpCenterService.setFormFieldsData(data)
    this.router.navigateByUrl('/pages/help');
    this.ref.close();
  }

  onSubscribeNow() {
    if (!this.isAdministrator) {
      this.track('view block subscribe dialog')
      this.dialogService
        .open(BlockProUpgradeDialogComponent, { context: {} })
        .onClose.subscribe(() => {
          this.dialogService
            .open(TrialHasEndedDialogComponent, {
              closeOnBackdropClick: false,
              closeOnEsc: false,
              context: {},
            })
            .onClose.subscribe(() => {
              this.onSubscribeNow();
            });
        });
      this.ref.close();
    } else {
      this.track('click subscribe now')
      this.router.navigateByUrl("/pages/profile/plans");
      this.ref.close();
    }
  }
}
