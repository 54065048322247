import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { AnalyticsService } from '../../services/analytics.service';
import { PagesService } from '../../services/pages.service';
import { Observable, from, of, forkJoin, throwError } from 'rxjs';
import { tap, mergeMap, flatMap, catchError } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AccountsService } from '../../services/accounts.service';
import { IntegrationsService } from '../../services/integrations.service';
import { Router } from '@angular/router';


@Component({
  selector: 'ngx-bulk-send-dialog',
  templateUrl: 'bulk-send-dialog.component.html',
  styleUrls: ['bulk-send-dialog.component.scss'],
})
export class BulkSendDialogComponent {
  @Input() title = 'Bulk Creation';
  @Input() screen;
  @Input() page;
  @Input() orgInfo;
  @Input() templateDownloaded:boolean = false;
  validCSV:boolean = false;
  loading:boolean = false; // to display 'Verifying' text. is it necessary ?
  showProgressBar:boolean = false;
  finished:boolean = false;
  columns = ['title', 'days to expire'];
  fileToUpload;
  fileUrl;
  fileName;
  errorMessage = null;
  maxRecords;
  progress = 0;
  recordsLength;
  plan;
  isPro: boolean;
  bulkSendLimit: number;
  integrationVars = [];
  integrationsLoading = false;
  fileTypes = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/csv, text/csv";

  constructor(protected ref: NbDialogRef<BulkSendDialogComponent>,
             private analyticsService: AnalyticsService,
             private pagesService: PagesService,
             private router: Router,
             private integrationsService: IntegrationsService,
             private accountsService: AccountsService) {}

  ngOnInit() {
    this.trackStory('view bulk send dialog');

    if (this.isStarterOrTrial) this.maxRecords = 10;
    else this.maxRecords = 500;
    if (this.orgInfo.allowedIntegrations) {
      this.integrationsLoading = true;
      this.integrationsService.integrationsVars()
      .pipe(
        catchError((err: any) : Observable<any> => {
          this.integrationsLoading = false;
          this.columns = this.storyColumns;
          this.trackStory('failed get integrations')
          return throwError(err);
        })
      )
      .subscribe(variables => {
        this.integrationVars = variables;
        this.columns = this.storyColumns;
        this.integrationsLoading = false;
      })
    } else {
      this.columns = this.storyColumns;
    }

    this.isPro = this.accountsService.isPro;
    this.bulkSendLimit = this.accountsService.bulkSendLimit;
  }

  trackStory(event, params = {}) {
    this.analyticsService.trackStory(event, this.page, {
      'screen': this.screen,
      ...params
    });
  }

  get isB2b() {
    return this.accountsService.isB2B;
  }

  get isStarterOrTrial() {
    return this.accountsService.isStarterOrTrial;
  }

  get isValidFormat() {
    return this.fileToUpload.type == "application/vnd.ms-excel" || this.fileToUpload.type == "text/csv" || this.fileToUpload.type == "application/csv";
  }

  get fastVersioningVars() {
    try {
      const fs = this.page.settings.fs;
      if(fs && fs['fs-vars']) {
        return JSON.parse(fs['fs-vars']);
      }
      return [];
    } catch (err) {
      return [];
    }
  }

  get storyColumns(){
    let storyParams = [ ...this.fastVersioningVars, ...this.integrationVars ];
    for (let index in storyParams) {
      if (storyParams[ index ].accountMapping) continue; // don't create column for accounts variables
      this.columns.push(storyParams[index].title)
    };
    return this.columns;
  }

  cancel() {
    this.trackStory('click close bulk send dialog');
    this.ref.close(false);
  }

  skip(){
    this.trackStory('click bulk send skip download template');
    this.templateDownloaded = true;
  }

  clear(){
    this.trackStory('click bulk send clear file');
    this.errorMessage = null;
    this.validCSV     = false;
    this.fileToUpload = undefined;
  }

  downloadTemplate(){
    this.trackStory('click bulk send download template');
    let a      = document.createElement('a');
    let blob   = new Blob([this.columns.join(',')], { type: 'text/csv' });
    let url    = window.URL.createObjectURL(blob);
    a.href     = url;
    a.download = this.page.settings.title + ' Template.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.templateDownloaded = true;
  }

  validateFileInput(files){
    this.trackStory('click bulk send validate csv');
    if(files && files.length > 0) {
      this.errorMessage = null;
      this.loading      = true; // to display 'Verifying' text. is it necessary ?
      this.validCSV     = false;
      this.fileToUpload = files.item(0);
      if(this.isValidFormat){
        let reader: FileReader = new FileReader();
        reader.onload = (e) => {
          let csv: string   = reader.result as string;
          this.errorMessage = this.validateColumns(csv);
          if(!this.errorMessage){
            this.validCSV = true;
            this.trackStory('success bulk send validate csv');
          }
          if(this.errorMessage) this.trackStory('failed bulk send validate csv', {
            'failure message': this.errorMessage
          });
          this.loading = false;
        }
        reader.onerror = (e) => {
          this.loading      = false;
          this.errorMessage = 'We’ve found some errors in your variables. \r\n Please check if everything is correct.';
          this.trackStory('failed bulk send validate csv', {
            'failure message': this.errorMessage
          });
        };
        reader.readAsText(this.fileToUpload);
      }else{
        this.loading      = false;
        this.errorMessage = 'Wrong file format';
        this.trackStory('failed bulk send validate csv', {
          'failure message': this.errorMessage
        });
      }
     } else {
      this.validCSV = false;
     }
  }

  validateColumns(csv){
    let headers = csv.split('\r\n')[0].split(/[;,]/);
    let recordsLength = csv.split('\r\n').length - 1;
    let lastRecord = csv.split('\r\n').slice(-1)[0];

    if(recordsLength > this.maxRecords){
      this.trackStory('failed bulk send validate csv', {
        'failure message': `limit failed: max is ${this.maxRecords}, file contain ${recordsLength}`
      });

      if (this.isStarterOrTrial) {
        return `The file exceeds the limit of ${this.maxRecords} lines. Please, upgrade your plan.`;
      }
      return `The file exceeds the limit of ${this.maxRecords} lines. Please upload again.`;
    }
    else if(recordsLength < 1 || (recordsLength === 1 && lastRecord === '')) return 'The file is empty. Please try again';
    else{
      let missingColumns = [];
      for (let index in this.columns) {
        if(headers.indexOf(this.columns[index]) < 0 && this.columns[index] != 'days to expire'){
          missingColumns.push(this.columns[index]);
        }
      }
      if(missingColumns.length){
        return 'Missing columns: \r\n' + missingColumns.join(', ');
      }
    }
    this.recordsLength = lastRecord === '' ? recordsLength - 1 : recordsLength;
    return null;
  }

  uploadCSV(){
    this.trackStory('click bulk send upload csv');
    this.progress        = 0;
    this.showProgressBar = true;
    this.errorMessage    = null;
    this.pagesService.getSignedPostPolicyFromBucket(this.fileToUpload.type.replace(/\+/, '%2B'), environment.files)
    .pipe(mergeMap((result: any) => {
      this.fileName = result.filename
      this.fileUrl  = 'https://storage.googleapis.com/' + environment.files + '/' + result.filename;
      return this.pagesService.uploadFilePost(result.url, this.fileToUpload);
    }))
    .pipe(
      catchError((err: any) : Observable<any> => {
        this.showProgressBar = false;
        this.errorMessage    = 'Sorry, we could not upload your file. Please try again.'
        this.trackStory('failed bulk send upload csv');
        return throwError(err);
      })
    )
    .subscribe((result: any) => {
      if(result.type == HttpEventType.UploadProgress) {
        this.progress = (result.loaded / result.total) * 100;
      } else if (result.type == HttpEventType.Response) {
        this.trackStory('success bulk send upload csv');
        this.startBulk();
      }
    });
  }

  startBulk(){
    this.trackStory('click start bulk send', {
      'versions count': this.recordsLength
    });
    this.pagesService.bulkSend(this.page._id, {fileName: this.fileName, bucket: environment.files, action:'read'})
    .pipe(
      catchError((err: any) : Observable<any> => {
        this.showProgressBar = false;
        this.errorMessage    = 'Sorry, we could not upload your file. Please try again.'
        this.trackStory('failed start bulk send');
        return throwError(err);
      })
    )
    .subscribe((result: any) => {
      this.trackStory('success start bulk send');
      this.finished = true;
    });
  }

  finish() {
    this.cancel();
    this.router.navigateByUrl('/pages/editor/'+this.page._id+'/versions');
  }
}
