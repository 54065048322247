import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { tap, mergeMap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { integrations } from '../pages/profile/integrations/integrations-list';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(private http: HttpClient) { }

  orgInfo;
  integrations: any;

  integrationsVars(): Observable<any> {
    return this.get()
    .pipe(
      map(integrations => integrations.filter(i => i.active).map(i => i.metadata.variables)),
      mergeMap(vars => {
        if(!vars.length) return from([[]])
        const variables = [].concat.apply([], vars.map(v => [ ...v ]));
        return from([variables])
      })
    )
  }

  getIntegration(integrationType) {
    return this.get()
    .pipe(
      map(integrations => integrations.filter(i => i.metadata.type === integrationType))
    )
  }

  get(force = false): Observable<any> { // admin only
    if(this.integrations && !force) return from([this.integrations]);
    return this.http.get(environment.backend + '/integrations')
      .pipe(
        tap(integrationsRes => {
          this.integrations = integrationsRes.map(integration => {
            integration.metadata = integrations.find((i: any) => i.internalName == integration.vendor)
            return integration;
          });
          return this.integrations;
        })
      );
  }

  delete(vendor): Observable<any> { // admin only
    return this.http.delete(environment.backend + '/integrations/' + vendor);
  }

  update(id, data): Observable<any> {
    return this.http.put(environment.backend + '/integrations/' + id, data);
  }

  exchangeCode(vendor, code, isSandbox = false): Observable<any> {
    return this.http.post(environment.backend + `/integrations/${vendor}/code`, { code, isSandbox });
  }

  getConnectionLink(vendor, isSandbox = false): Observable<any> {
    let url = environment.backend + `/integrations/${vendor}/connectionLink`;
    if(isSandbox) url = url + '?isSandbox=true';
    return this.http.get(url);
  }

  getContacts(vendor, searchTerm): Observable<any> {
    return this.http.post(environment.backend + `/integrations/${vendor}/contacts`, {searchTerm});
  }

  getContact(vendor, sfid): Observable<any> {
    return this.http.get(environment.backend + `/integrations/${vendor}/contact/${sfid}`);
  }

  getCustomList(vendor, searchTerm): Observable<any> {
    return this.http.post(environment.backend + `/integrations/${vendor}/custom`, {searchTerm});
  }

  getCustomData(vendor, inputs = {}): Observable<any> {
    return this.http.post(environment.backend + `/integrations/${vendor}/custom/data`, inputs);
  }

  postAnalyticsEvent(event: string, props: {[key:string]: string|number}): Observable<{event: string}> {
    return this.http.post<{event: string}>(environment.backend + `/integrations/hubspot-analytics/event`, {event, props});
  }

  postHubspotProperty(property: {[key:string]: string|number}): Observable<any> {
    return this.http.post<any>(environment.backend + `/integrations/hubspot-analytics/property`, {property});
  }
}
