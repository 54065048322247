import { Component, OnInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { PagesService } from '../../../services/pages.service';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../../../auth.service';
import { ToasterService } from '../../../services/toaster.service';
import 'rxjs/add/observable/of';
import { AccountsService } from '../../../services/accounts.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
declare const Pickr;

@Component({
  selector: 'ngx-color-picker-dialog',
  templateUrl: './color-picker-dialog.component.html',
  styleUrls: ['./color-picker-dialog.component.scss']
})
export class ColorPickerDialogComponent implements OnInit, OnDestroy {

  @Input() variableControl = null;
  @Input() defaultColors;
  @Input() dsDefault;
  @Input() variable = null;
  @Input() selectedColor;
  @Input() backgroundColor;
  @Input() color;
  @Input() isV2;

  ignoreChange = false;
  selectedCustomColor;
  initialValue;
  pickr;
  isTextEditor = false;

  constructor(public auth: AuthService,
              private pagesService: PagesService,
              private accountsService: AccountsService,
              private dialogService: NbDialogService,
              protected ref: NbDialogRef<ColorPickerDialogComponent>,
              private toasterService: ToasterService,
              private router: Router) { }


  ngOnInit() {
    this.selectedCustomColor = this.customColor(this.color);
    if(!this.variable || !this.variable.isBackground && !this.selectedColor) {
      // if doesnt allow null value - inject that default into selectedColor
      this.selectedColor = this.dsDefault;
    }
    this.initialValue = this.selectedColor;
    this.initPicker();
  }

  initPicker() {
    this.pickr = Pickr.create({
        el: '#color-picker',
        theme: 'nano', // or 'monolith', or 'nano'
        default: this.color,
        swatches: null,
        inline: true,
        showAlways: true,
        useAsButton: true,
        components: {

            // Main components
            preview: true,
            opacity: true,
            hue: true,

            // Input / output Options
            interaction: {
                hex: false,
                rgba: false,
                hsla: false,
                hsva: false,
                cmyk: false,
                input: true,
                clear: false,
                cancel: false, // mask cancel to have custom save functionality
                save: false // mask cancel to have custom save functionality
            }
        },
    });
    this.pickr
    .on('change', (color, source, instance) => {
      if(!this.ignoreChange) {
        const c = color.toHEXA().toString(0);
        this.selectedColor = this.customColor(c);
        this.selectedCustomColor = this.selectedColor;
      } else {
        this.ignoreChange = false; // reset
      }
    })
  }

  customColor(color) {
    return {
      key: 'custom',
      title: 'Custom color',
      color: color,
      value: color
    };
  }

  setColor(color = null) {
    this.selectedColor = color;
    if(this.pickr) {
      this.ignoreChange = true; // patch for false not working
      this.pickr.setColor(color ? color.color : this.dsDefault.color, false);
    }
  }

  reset() {
    if(this.variable && this.variable.isBackground) {
      this.setColor();
    } else if(this.variable && this.variable.customOnly) {
      const customColor = this.customColor(this.dsDefault.color);
      this.setColor(customColor);
      this.selectedCustomColor = customColor;
    }
    else if (this.isTextEditor) {
      let color = 'reset-color';
      this.dismiss(color);
    }
    else {
      if(this.isV2) {
        this.setColor(this.dsDefault);
      } else {
        if(this.variable.default) this.setColor(this.customColor(this.variable.default));
      }
    }
  }

  ngOnDestroy() {
    if(this.pickr) this.pickr.destroy();
  }

  dismiss(val = null) {
    this.ref.close(val);
  }

  onSubmit() {
    let val = this.selectedColor ? this.selectedColor.value : null;
    this.variableControl ? this.variableControl.setValue(val) : null;
    this.dismiss(val);
  }

}
