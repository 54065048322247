import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription, from, fromEvent, of } from "rxjs";
import { WizardFacade } from "../wizard/wizard.facade";
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, mergeMap, switchMap, take, takeUntil, tap, timeout } from "rxjs/operators";
import _ from "lodash";
import uuid from "uuid";

import { isWebsiteValid, extractDomainName, mapStoryClassification, setWizardDataToStorage, getTemplateTitle, headerSteps } from "./utils";
import { RenderingService } from "../../services/rendering.service";
import { FormControl, FormGroup } from "@angular/forms";
import { AnalyticsService } from "../../services/analytics.service";
import { MediaService } from "../../services/media.service";
import { PagesService } from "../../services/pages.service";
import { HttpEventType } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BrandFetchDataRes, HeaderStepProps } from "./types";
import { UsersService } from "../../services/usersV2.service";
import { Industry } from "../wizard/wizard.interface";
import { deleteCookie, getAiSubtypeFromUrl, getCookie, promptHeading, updateSlidePreview, userPromptHandler} from "../wizard/utils";
import { AiService } from "../../services/ai.service";
import { classifications } from "../wizard/utils/classifications";
import { industries } from "../wizard/utils/ai-industries";
import { AccountsService } from "../../services/accounts.service";
import { Router } from "@angular/router";
import { TemplatesService } from "../wizard/wizard-templates/templates.service";
import { blankTemplateJSON } from "../wizard/blank";
import { parser } from "../wizard/wizard-templates/utils";
import { SafeHtml } from "@angular/platform-browser";
import { freeDomains } from "../workspace/domains";

@Injectable({
    providedIn: 'root',
})

export class WizardV2Facade { 

    constructor(
            private renderingService: RenderingService,
            private mediaService: MediaService,
            private pagesService: PagesService,
            private usersService: UsersService,
            private aiService: AiService,
            private wizardFacade: WizardFacade,
            private router: Router,
            private accountsService: AccountsService,
            private templatesService: TemplatesService,
            private analyticsService: AnalyticsService
    ) { 
        this.screenResizeListener();
        }


    //  =========== STEPS ===========
    currentStep$ = new BehaviorSubject<number>(0);
    lastActiveStep$ = new BehaviorSubject<number>(0);
    stepsLength$ = new BehaviorSubject<number>(5);
    headerSteps: HeaderStepProps[] = headerSteps;
    headerSteps$ = new BehaviorSubject<HeaderStepProps[]>(this.headerSteps);

    stepsValidation$ = new BehaviorSubject<Record<string, boolean>>({
        company: false,
        details: false,
        type: false,
        industry: false,
        design: false
    });
    //  =========== STEPS ===========

    // =========== GENERAL WIZARD ===========
    hasTransition$ = new BehaviorSubject<boolean>(true);
    unsubscribe$ = new Subject<void>();
    wizardFlow$ = new BehaviorSubject<'default' | 'website' | 'prompt' | 'templates'>('default');
    isWizardLoading$ = new BehaviorSubject<boolean>(false);
    progressBarFinished$ = new Subject<void>();
    progressValue$ = new BehaviorSubject<number>(0);
    isNoCompanyMessage$ = new BehaviorSubject<boolean>(false);
    currentUser$ = this.usersService.currentUser$;
    activeFolder: string | null = null;
    totalStories$ = this.analyticsService.currentStoriesCount$;
    icons: any = { line: [], fill: [] } = { line: [], fill: [] };
    websiteData: Record<string, any> = {};
    regenerate: boolean = false;
    isMobile$ = new BehaviorSubject<boolean>(false);
    previewURL$ = new BehaviorSubject<string>(null);
    isAGroup = this.accountsService.isAGroup;
    isBGroup = !this.accountsService.isAGroup;
    triggerCloseMobileMessageDialog$ = new BehaviorSubject<boolean>(false);
    // =========== GENERAL WIZARD ===========

    //  =========== FORM ===========
    form: FormGroup = new FormGroup({});
    formDataKeys: string[] = [
        'website',
        'company',
        'product',
        'type',
        'industry',
        'usecase',
        'logo',
        'subtypeSelectOption',
        'subtypeCategory',
        'subtypeClassification',
        'industryClassification',
        'logoTheme',
        'template'
    ];
    formDataStream$ = new BehaviorSubject<Record<string, any>>({});
    //  =========== FORM ===========

    brandLoading$ = new BehaviorSubject<boolean>(false);
    brandfetch$ = new BehaviorSubject<BrandFetchDataRes>(null);
    isNoBrandFound$ = new BehaviorSubject<boolean>(false);
    brandLogoInitialTheme$ = new BehaviorSubject<string>('dark');

    fetchAISubscription: Subscription;
    fetchAIStatus$ = new BehaviorSubject<'pending'|'rejected'|'finished'>(null);
    resultAI$ = new BehaviorSubject<Record<string, any>>(null);
    prompt: string = null;
    story: Record<string, any> = {};
    

    
    // ========== ANALYTICS ==========
    track(event: string, params: Record<string, string|number|boolean> = {}) {
        this.analyticsService.track(event, {
            'screen': 'wizard v2',
            ...params
        });
    }

    stepAnalyticsHandler(step: HeaderStepProps) {
        if (step.status !== 'active') return;
        
        switch (step.action) {
            case 'type':
                this.track('view wizard step 2');
                this.track('view wizard - subtype');
                break;
            case 'company':
                this.track('view wizard_v2 brand name (view wizard_v2 company)');
                break;
            case 'details':
                this.track('view wizard_v2 details');
                break;
            case 'industry':
                this.track('view wizard - industry');
                break;
            case 'design':
                this.track('view wizard - template');
                this.track('view wizard_v2 templates library');
                break;
            default:
                break;
        }
    }

    // ========== ANALYTICS ==========


    //  =========== STEPS ===========
    navigateToStep(step: number, direction: 'forward' | 'backward' = 'forward') {
        if (direction === 'forward') {
            if (this.lastActiveStep$.getValue() <= step) {
                this.lastActiveStep$.next(step);
            }
        }
        this.currentStep$.next(step);

        if (direction === 'backward' && this.fetchAISubscription) {
            this.fetchAISubscription.unsubscribe();
            this.fetchAIStatus$.next('rejected');
        }
    }
    
    disableTransition() {
        this.hasTransition$.next(false)

        of(null).pipe(
            take(1),
            delay(500)
        ).subscribe(() => {
            this.hasTransition$.next(true)
        })
    }

    sortClassifications(landingPageSubtype: string, substring: string, array: { name: string, order: number, description: string, icon: SafeHtml }[]) { 
        if (!landingPageSubtype.toLowerCase().includes(substring)) return array;
        return array = array.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA.includes(substring) && !nameB.includes(substring)) return -1;
            else if (!nameA.includes(substring) && nameB.includes(substring)) return 1;
            else return 0;
        });
    }

    getLandingPageSubtype() {
        const landingPageUrl = getCookie('storydoc_landing_page');
        if(!landingPageUrl) return;
        return getAiSubtypeFromUrl(landingPageUrl);
    }


    updateStepStatus(action: string, status: boolean) {
        this.stepsValidation$.next({ ...this.stepsValidation$.getValue(), [ action ]: status });
    }
    //  =========== STEPS ===========


    //  =========== BRAND ===========
    isWebsiteValid(url: string) {
        return isWebsiteValid(url);
    }

    fetchBrandData$() {
        if (!this.isWebsiteValid(this.formData.website)) return of(null);
        return this.renderingService.brandfetchV2(extractDomainName(this.formData.website))
    }

    setBrandFetchData(data: BrandFetchDataRes) {
        this.brandfetch$.next(data);
    }

    setV1BrandFetchData(data: Record<string, any>) {
        this.wizardFacade.setBrandFetchData(data);

    }

    fetchWebsiteMetadata$() {
        if (!this.formData.website) return of(null);
        return this.mediaService.getWebsiteMetadata(`https://${extractDomainName(this.formData.website)}`).pipe(timeout(30000))
    }

    findRelevantLogo$(theme: string, brandfetch: Record<string, any>|null) {
        if (!brandfetch || !brandfetch.logos) return from([ '' ]);

        if (!theme) {
            const logo = brandfetch.logos.find(item => item.type === 'logo');
            if (logo) return from([_.get(logo, 'formats[0].src', '')])
            else return from([_.get(brandfetch, 'logos[0].formats[0].src', '')])
        }

        const logos = brandfetch.logos.filter(item => item.theme === theme);

        if (!logos.length) {
            const logoItem = brandfetch.logos.find(item => item.type === 'logo');
            if (logoItem) {
                const pngLogo = logoItem.formats.find(item => item.format === 'png' && item.background === 'transparent');
                if (pngLogo) return this.updateLogoThemeColor$(pngLogo.src, theme)
                else return from([_.get(logoItem, 'formats[0].src', '')])
            }
            return from([_.get(brandfetch, 'logos[0].formats[0].src', '')])
        }
    
        // Return the first logo with the matching theme
        const getLogo = logos.find(item => item.type === 'logo');
        if (getLogo) return from([_.get(getLogo, 'formats[0].src', '')])
        else  return from([_.get(logos[0], 'formats[0].src', '')])
    }

    updateLogoThemeColor$(url: string, theme: string) {
        // create options for image processing
        const options = { brightness: true, invert: false };
        if(theme === 'light') options[ 'invert' ] = true;
        // process image
        return this.mediaService.editImageStyleFromURL(url, options)
        .pipe(
            // upload processed image to library
            switchMap(res => this.uploadFileToLibrary$(res.response)),
            // return url of processed image
            switchMap(res => from([ res.url ])),
            // catch errors and return original url
            catchError((err: any) : Observable<any> => from([url]))
        )
    }

    uploadFileToLibrary$(url: string) {
        return this.renderingService.getFileContents(url, { responseType: 'blob' })
        .pipe(switchMap(blob => this.uploadFilePost$(blob)))
    }

    uploadFilePost$(file) {
        return this.pagesService.getSignedPostPolicy(file.type.replace(/\+/, '%2B'))
        .pipe(switchMap(result => {
            return this.pagesService.uploadFilePost(result.url, file)
            .pipe(
                filter(res => res.type === HttpEventType.Response),
                switchMap(() => this.uploadMediaToDB$(file, result)),
                timeout(30000)
            )
        }))
    }

    uploadMediaToDB$(file: File, result) {
        return this.mediaService.create({
            title: file.name || uuid.v4(),
            type: file.type,
            typeGroup: file.type.split('/')[ 0 ],
            size: file.size,
            url: environment.assets + '/' + result.filename,
            folder: null
        })
    }

    handleDataForDesignTemplate() {
        this.formDataStream$
            .pipe(
                map((formData) => formData.industryClassification),
                filter((industry) => industry),
                distinctUntilChanged((prev, next) => prev.property === next.property),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(industry => {
                const current = this.brandfetch$.getValue() || {}
                this.setV1BrandFetchData({ ...current, industry });
            });

        this.brandfetch$
            .pipe(
                filter((data) => data !== null),
                distinctUntilChanged(),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((res: BrandFetchDataRes) => {
                const industry = this.formData.industryClassification || {}
                this.setV1BrandFetchData({ ...res, industry });
            });
    }

    searchBrand$(term: string) {
        if (!term.length) return from([[]]);
        return this.renderingService.searchBrand(term)
        .pipe(
            timeout(10000),
            catchError((err: any): Observable<any> => {
                this.track("failed to search from search API", {
                    'failure message': err.message,
                })
                return from([[]]);
            })
        )
    }
    //  =========== BRAND ===========


    // =========== AI ===========
    getAIClassification() {
        if (this.fetchAIStatus$.getValue() === 'rejected') return;

        this.fetchAIStatus$.next('pending');

        this.fetchAISubscription = this.classificationRequestHandler$()
        .pipe(
            catchError((err) => {
                // handle errors gracefully
                this.track("failed to get classification from openAI", {
                    'failure message': err.message,
                });
                this.fetchAIStatus$.next('finished');
                return from([ null ])
            })
        )
        .subscribe(prompt => {
            if(!prompt) return;

            // parse the results
            try {
                this.resultAI$.next(JSON.parse(prompt.content));
            } catch (e) { }

            this.fetchAIStatus$.next('finished');
        })
    }

    classificationRequestHandler$() {
        const prompt = this.buildPrompt();
        return this.getAIPrompt$(prompt).pipe(timeout(60000))
    }

    // Private methods

    private buildPrompt() {
        const { type, company, product, industry } = this.formData;
        const prompt = promptHeading({ type, company, website: this.formData.website, product, industry }) + this.formData.subtypeClassification.prompt;
        return prompt;
    }

    private getAIPrompt$(prompt: string): Observable<any> {
        return this.aiService.getAIPrompt(prompt);
    } 
    // =========== AI ===========



    //  =========== FORM ===========
    initializeForm() {
        const data: Record<string, FormControl> = {};
        this.formDataKeys.map(key => data[ key ] = new FormControl());
        this.form = new FormGroup(data);
    }

    handleFormValueChanges() { 
        if (this.isMobile$.getValue()) this.analyticsService.register('is mobile', true);
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(changes => {
            // Register analytics events
            this.registerMixpanelData(changes);
            // Update the form data stream
            this.formDataStream$.next(changes);
        });
    }


    registerMixpanelData(data: Record<string, any> = {}) {
        if(data.usecase) this.analyticsService.register('usecase', data.usecase);
        if(data.website) this.analyticsService.register('website', data.website);
        if(data.type) this.analyticsService.register('subtype', data.type);
        if(data.company) this.analyticsService.register('wizard company name', data.company);
        if(data.subtypeSelectOption) this.analyticsService.register('subtype select', data.subtypeSelectOption);
        if(data.subtypeCategory) this.analyticsService.register('subtype category', data.subtypeCategory);
    }

    setFormValue(key: string, value: string|Industry) {
        if (!this.form.get(key)) return;
        this.form.get(key).setValue(value);
    }

    resetForm() {
        this.lastActiveStep$.next(0);
        this.form.reset();
    }

    get formData() {
        return this.form.value;
    }
    //  =========== FORM ===========





    //  =========== TEMPLATE ===========
    isWebsiteFlow() {
        // Get the selected template from the cookie
        const websiteTemplate = JSON.parse(`[${decodeURIComponent(getCookie('selectedTemplate'))}]`)[ 0 ];
        if (!websiteTemplate) return;

        // Set the wizard flow to 'website'
        this.websiteData = websiteTemplate;
        this.wizardFlow$.next('website');

        // Delete the cookie
        deleteCookie('selectedTemplate', "/", "storydoc.com");

        // Remove the 'type' step from the header steps
        this.headerSteps = this.headerSteps.filter(step => step.action !== 'type' && step.action !== 'design');
        this.stepsLength$.next(this.stepsLength$.getValue() - 2);
        this.headerSteps$.next(this.headerSteps);

        // Set the form values
        const tags = websiteTemplate.tags.map((tag: string) => tag.toLowerCase());
        const key = Object.keys(classifications).find(key => tags.includes(key.toLowerCase()));
        if (!key) return;
        const type = classifications[ key ];
        this.setFormValue('type', key);
        this.setFormValue('subtypeClassification', type);
        this.setFormValue('usecase', type.usecase);
    }

    isPromptFlow() {
        const prompt = getCookie('templatePrompt');
        if (!prompt) return;

        this.prompt = prompt;
        deleteCookie('templatePrompt', "/", "storydoc.com");

        this.wizardFlow$.next('prompt');
        this.analyticsService.register('started from prompt', true);

        this.track('generate from prompt', { 'prompt': prompt });

        this.aiService.getAIPrompt(userPromptHandler(prompt))
        .pipe(catchError((err) => {
            this.track('failed generate from prompt', {
                'prompt': prompt,
                'failure message': JSON.stringify(err)
            })
            return from([null])
        }))
        .subscribe(res => {
            if (!res) return;
            this.track('success generate from prompt', {
                'prompt': prompt,
                'ai response': res.content
            })
            this.setDataFromPrompt(res.content);
        })
    }

    setDataFromPrompt(content: string) {
        let data: Record<string, string> = {};
        const landingPageUrl = getCookie('storydoc_landing_page');
        
        try {
            data = JSON.parse(content);
            if (!data.subtype) {
                const subtype = getAiSubtypeFromUrl(landingPageUrl)
                if (subtype) data.subtype = subtype
            }

        } catch (e) {
            const subtype = getAiSubtypeFromUrl(landingPageUrl);
            if (subtype) data.subtype = subtype;
        }

        const { subtype, industry, company, website, product } = data;
        // if (subtype) {
        //     const classification = classifications[ subtype ];
        //     this.setFormValue('type', subtype);
        //     if (classification) {
        //         this.setFormValue('subtypeClassification', classification);
        //         this.setFormValue('usecase', classification.usecase);
        //     }
        // }

        if (industry) {
            const industryClassification = industries.find(item => item.property.toLowerCase() === industry.toLowerCase());
            this.setFormValue('industry', industry);
            if(industryClassification) this.setFormValue('industryClassification', industryClassification);
        }
        // if (company) this.setFormValue('company', company);
        // if (website) this.setFormValue('website', website);
        // if (product) this.setFormValue('product', product);
    }

    selectTemplate() {
        const story = this.story;
        const slides = mapStoryClassification(
            story.slides,
            this.resultAI$.getValue(),
            this.formData.industryClassification,
            this.icons,
            this.handleFailedMapStoryEvent.bind(this)
        );
        story.slides = [...slides];
        const template = story;
        const templateTitle = template.settings.title;
        template.contentPointer = null; // patch to avoid not rendering the right content
        template.settings.og.description = 'Check out my interactive Storydoc presentation';
        template.settings.favicon = 'https://www.storydoc.com/assets/images/branding/favicon.png';
        template.settings.og.image = 'https://www.storydoc.com/assets/images/branding/og-storydoc.gif';
        if (this.formData.company) template.settings.og.siteName = this.formData.company;
        template[ 'folderId' ] = this.activeFolder || null;
        const storyNumber = Number(this.totalStories$.getValue()) + 1;
        const title = `${getTemplateTitle(this.formData) || 'My Storydoc'} #${storyNumber}`;
        this.selectTemplateAnalyticsHandler(templateTitle);
        const industryClassification = this.formData.industryClassification || {};
        template.settings.wizard = this.templateWizardPayload({
            'industry category': industryClassification.category,
            'template title': templateTitle,
            'template id': template._id,
            'website flow': this.wizardFlow$.getValue() === 'website',
        })

        this.updateOrgInfoProperties$()
        .pipe(
            switchMap(() => this.renderingService.uploadCss(template, 'new')),
            switchMap((cssUrl: string) => this.updateTemplateBeforeCreate$({...template, settings: {...template.settings, cssUrl}})),
            switchMap(story => this.renderingService.createStory(title, story, null, 'Draft', null, true)),
            catchError(err => {
                this.track('failed to choose template', {
                    'failure message': err.message,
                    'template title': this.formData.template
                });
                this.router.navigateByUrl('/pages/home');
                return from([ null ]);
            })
        )
        .pipe(
            tap(() => this.progressBarFinished$.next()),
            delay(500),
            takeUntil(this.unsubscribe$),
        )
        .subscribe(story => {
            deleteCookie('enable-mobile-wizard', "/", "storydoc.com");
            this.registerMixpanelData(this.formData);
            const isWebsiteFlow = this.wizardFlow$.getValue() === 'website';
            this.track('success choose template', {
                'template title': this.formData.template,
                'custom industry': !!this.formData.industry && !!(!this.formData.industryClassification),
                'industry': this.formData.industry,
                'website flow': isWebsiteFlow,
                'industry category': industryClassification.category,
            });
            this.analyticsService.sendHubspotInternalEvent('wizard complete');
            this.analyticsService.sendHubspotProperty({
                wizard_industry: this.formData.industry,
                wizard_website: this.formData.website || '',
                selected_template_category: this.formData.category,
                selected_use_case: this.formData.usecase,
                wizard_status__finished_not_finished_: 'Finished',
                selected_template_name: this.formData.template,
                wizard_subtype: this.formData.subtypeSelectOption || '',
                wizard_subtype_category: this.formData.subtypeCategory || '',
            });
            if (this.isMobile$.getValue()) return this.previewURL$.next(story.previewUrl);
            if (this.regenerate) {
                this.track('success regenerate dialog');
                sessionStorage.removeItem("wizardData");
                window.open(`/pages/editor/${story._id}?autosave=true`, '_self');
            } else {
                this.router.navigateByUrl(`/pages/editor/${story._id}?autosave=true`);
                setWizardDataToStorage(this.formData, this.resultAI$.getValue(), this.brandfetch$.getValue());
            }
        });
    }

    updateTemplateBeforeCreate$(template: Record<string, any>) {
        const templateWithLogo$: Observable<Record<string, any>> = this.brandLogoHandler$(template);
        const templateWithPreviewUrl$: Observable<Record<string, any>> = templateWithLogo$.pipe(
            switchMap((template) => {
                if(!this.isMobile$.getValue()) return of(template) // Prevent generate new preview if not mobile
                return this.pagesService.getUpdatedPreviewURL(template._id, template, template.previewUrl, template.settings.cssUrl)
                    .pipe(
                        switchMap((res: { url: string }) => from([{...template, previewUrl: res.url}])),
                        catchError((err) => {
                            this.track('failed to update preview url', {
                                'failure message': err.message
                            });
                            return of(template)
                        })
                    )
            }),
        )
        return templateWithPreviewUrl$;
    }

    getPreviewTemplateURL$(template: Record<string, any>) {
        return this.updateTemplateBeforeCreate$(template)
            .pipe(switchMap(story => of(story.previewUrl)))
    }

    brandLogoHandler$(template: Record<string, any>) {
        const regex = /^https:\/\/www\.storydoc\.com\/assets\/wizard\/([^\/]+)-regular\.png$/;
        const formLogo = regex.test(this.formData.logo) ? this.formData.logo.replace('-regular', '') : this.formData.logo;
        const logoSource$ = this.isNoBrandFound$.getValue()
            ? (this.formData.logoTheme === 'dark'
                ? of(formLogo)
                : this.updateLogoThemeColor$(formLogo, 'light')
            )
            : this.findRelevantLogo$(this.formData.logoTheme, this.brandfetch$.getValue());
        
        return logoSource$
        .pipe(catchError(() => from([ template ])))
        .pipe(mergeMap(res => {
            if (res) template.slides[ 0 ] = updateSlidePreview(res, template.slides[ 0 ], _);
            return from([template])
        }))
    }

    updateOrgInfoProperties$() {
        const { website, industry, usecase } = this.formData;
        return this.accountsService.updateOrgInfoProps(website, industry, usecase, this.prompt);
    }

    selectTemplateAnalyticsHandler(templateTitle: string) {
        this.track('click choose template', {
            'template title': templateTitle
        });
        if (this.formData.classification && this.formData.classification.qualifiedUsecase) {
            this.analyticsService.sendHubspotInternalEvent('click qualified usecase', {usecase: this.formData.usecase});
        }
        this.track('click select usecase');
    }

    fetchIcons() {
        this.aiService
        .getIcons()
        .pipe(catchError((err) => from([{ line: [], fill: [] }])))
        .subscribe(
            (res: { line: Record<string, any>[]; fill: Record<string, any> }) =>
            (this.icons = res)
        );
    }

    getWebsiteTemplate$() {
        this.track('get template from website')
        return this.templatesService.getStorydocTemplates()
        .pipe(
            switchMap((data) => {
                // Parse the data to get the templates
                const templates = data.templates;
                // Find the template that matches the templateID
                const selected = templates.find(template => template.page._id === this.websiteData.id);
                this.setFormValue('template', selected.title);
                return this.templatesService.getSingleTemplate(this.websiteData.id)
                .pipe(switchMap(page => from([ page ])))
            }),
            catchError((err: any): Observable<any> => {
                // If the templates can't be loaded, use the blank template
                this.track('failed to load templates', {
                    'failure message': err.message
                });
                return from([ blankTemplateJSON ])
            }),
        )
    }
    
    selectTemplateHandler(template: Record<string, any>|null) {
        let story = null
        if (template) {
            story = template.page;
            this.setFormValue('logoTheme', template.logoTheme);
            this.setFormValue('template', template.title);
            this.analyticsService.register('is branded template', !!template.injected);
        }
        this.isWizardLoading$.next(true);
        let template$ = from([ story ])
        if (this.wizardFlow$.getValue() === 'website') {
            template$ = this.getWebsiteTemplate$();
        }
        if (this.wizardFlow$.getValue() === 'templates') { 
            this.fetchAIStatus$.next('finished');
        }

        if (this.fetchAIStatus$.getValue() === 'rejected') {
            this.fetchAIStatus$.next(null);
            this.getAIClassification();
        }
        this.fetchAIStatus$
            .pipe(
                filter(status => status && status === 'finished'),
                switchMap(() => template$),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((story: Record<string, any>) => {
                this.story = story;
                this.selectTemplate();
            })
    }

    handleFailedMapStoryEvent(e: Error) {
        this.track('failed map AI story', {
            'failure message': e.message
        });
    }

    templateWizardPayload(payload: Record<string, any> = {}) {
        const wizardPayload = {
            'industry': this.formData.industry,
            'company product': this.formData.product,
            'website': this.formData.website,
            'company name': this.formData.company,
            'subtype select': this.formData.subtypeSelectOption,
            'subtype category': this.formData.subtypeCategory,
            'subtype classification': this.formData.type,
            ...payload
        };
        return wizardPayload;
    }
    //  =========== TEMPLATE ===========
    // =========== GENERAL ===========

    
    resetWizard() {
        this.headerSteps$.next(this.headerSteps);
        this.lastActiveStep$.next(0);
        this.currentStep$.next(0);
        this.stepsLength$.next(5);
        this.hasTransition$.next(true);
        this.resultAI$.next(null);
        this.fetchAIStatus$.next(null);
        this.brandfetch$.next(null);
        this.isNoCompanyMessage$.next(false);
        this.formDataStream$.next({});
        this.wizardFlow$.next('default');
        this.isNoBrandFound$.next(false);
        this.progressValue$.next(0);
        this.isWizardLoading$.next(false);
        this.brandLogoInitialTheme$.next('dark');
        this.triggerCloseMobileMessageDialog$.next(false);
        this.stepsValidation$.next({
            company: false,
            details: false,
            type: false,
            industry: false,
            design: false
        });
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }


    setRegenerateData(payload: Record<string, any>) { 
        this.disableTransition();
        this.lastActiveStep$.next(4);
        this.currentStep$.next(4);
        this.resultAI$.next(payload.aiClassificationJSON);
        this.brandfetch$.next(payload.brandFetchRes);
        for(const key in payload.formData) {
            this.setFormValue(key, payload.formData[ key ]);
        }
        this.fetchAIStatus$.next('finished');
    }

    screenResizeListener(): void {
        let previousWidth = window.innerWidth;
        this.isMobile$.next(window.innerWidth <= 992);
        fromEvent(window, 'resize').pipe(debounceTime(250)).subscribe((event: Event) => {
            if (previousWidth === window.innerWidth) return;
            previousWidth = window.innerWidth;
            this.isMobile$.next(window.innerWidth <= 992);
        });
    }

    handleMainContainerZIndex(selectActive: boolean) {
        const container = document.getElementById('wizard-0d03bc66-88e2-4015-94ae-9e85d9e67a3a');
        if (!container) return;

        if (selectActive) container.style.zIndex = '9999';
        else container.style.zIndex = '2';
    }

    getUserDomain() {
        this.usersService.currentUser$.pipe(filter(user => !!user), take(1)).subscribe(user => { 
            const email = user.email;
            const domain = email.split('@')[ 1 ];
            if (freeDomains.includes(domain)) return;
            this.setFormValue('company', domain);
            this.setFormValue('website', domain);
        })
    }
    // =========== GENERAL ===========


    // =========== SETTERS ===========
    set setActiveFolder(folder: string | null) {
        this.activeFolder = folder;
    }

    set setRegenerate(regenerate: boolean) {
        this.regenerate = regenerate;
    }
    // =========== SETTERS ===========

}