
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsService } from './services/accounts.service';
import { AuthService } from './auth.service';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { WorkspaceFacade } from './components/workspace/workspace.facade';
import { getAiSubtypeFromUrl } from './components/wizard/utils';
import { LoginFacade } from './pages/login/login.facade';

@Component({
  // selector: 'ngx-setup',
  // templateUrl: './setup.component.html',
  // styleUrls: ['./setup.component.scss']
  template: `<div></div>`
})
export class SetupComponent implements OnInit {
  utms = {};
  gclid;
  cid;
  isSelectedTemplate;
  validation;
  landingPage: string;
  ipInfo: any = {};
  WIZARD_DATA_KEYS = [ 'website', 'industry', 'isMobile', 'testingNumber', 'id' ];
  createAccountUser$ = new BehaviorSubject<boolean | null>(null);
  unsubscribe$ = new Subject<void>();
  user: Record<string, any>;
  DISTINCT_ID_COOKIE_KEY = 'storydoc_distinct_id_67ebc42a-0835-47b9-9957-6cd8c3d02ef3';

  constructor(
    private accountsService: AccountsService,
    private workspaceFacade: WorkspaceFacade,
    private loginFacade: LoginFacade,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    const spinnerEl = document.getElementById('nb-global-spinner');
    if(spinnerEl) spinnerEl.style.display = 'flex';
    const cookiePrefix = 'storydoc_params_';
    document.cookie.split(';').filter(cookie => cookie.indexOf(cookiePrefix) > -1).map(cookie => {
      const [k, v] = cookie.trim().split('=');
      this.utms[k.replace(cookiePrefix, '')] = v;
    })
    const testingNumber = this.getCookie('storydoc-testing-number');
    const distinctId = this.getCookie(this.DISTINCT_ID_COOKIE_KEY);
    this.gclid = this.getCookie('storydoc_gclid');
    this.cid = this.getCookie('storydoc_cid');
    this.validation = this.getCookie('storydoc_validation');
    this.landingPage = this.getCookie('storydoc_landing_page');
    this.isSelectedTemplate = this.getCookie('selectedTemplate') ? JSON.parse(`[${decodeURIComponent(this.getCookie('selectedTemplate'))}]`)[0] : null;

    this.utms['gclid']=this.gclid; //temp
    this.utms['cid']=this.cid; //temp
    this.utms[ 'isEditorV4' ] = true;
    this.utms[ 'landingPage' ] = this.landingPage;
    this.utms[ 'landingPageCategory' ] = this.landingPageCategory;
    this.utms[ 'landingPageSubtype' ] = getAiSubtypeFromUrl(this.landingPage);
    const invitation = this.workspaceFacade.verifyInvitation();
    if (invitation) {
      this.utms[ 'invitation_token' ] = invitation;
      this.workspaceFacade.deleteCookie(this.workspaceFacade.invitationKey);
    }

    const websiteWizardData = this.getWebsiteWizardData();
    const websiteWizardDataKeys = Object.keys(websiteWizardData);
    if (websiteWizardDataKeys.length) {
      websiteWizardDataKeys.forEach(key => this.utms[ key ] = websiteWizardData[ key ]);
    }
    if (testingNumber) {
      this.utms[ 'testingNumber' ] = Number(testingNumber);
      this.deleteCookie('storydoc-testing-number');
    }

    if (distinctId) {
      this.utms[ 'id' ] = distinctId;
      this.deleteCookie(this.DISTINCT_ID_COOKIE_KEY);
    }
    this.initiateUser();

    this.createAccountHandler$().subscribe(account => {
      if (account === null) return;
      if (account.expired) this.workspaceFacade.setCookie(this.workspaceFacade.expiredKey, true);
      if (invitation && !account.expired) this.workspaceFacade.setCookie(this.workspaceFacade.successKey, true);
      this.auth.login();
    });
  }


  createAccountHandler$() {
    return this.createAccountUser$.pipe(
      filter((user: any) => !!user && !user.orgId),
      takeUntil(this.unsubscribe$),
      take(1),
      switchMap(() => this.accountsService.createAccount(this.utms)),
      catchError((error) => {
        this.loginFacade.track('failed account activated', {
          failure: error.message
        });
        return of(null)
      })
    )
  }

  initiateUser() {
    this.auth.getUser$().pipe(filter(user => !!user), take(1)).subscribe(user => {
      if(user.orgId) {
        this.deleteCookie('orgId');
        this.router.navigateByUrl('/pages/home');
        this.triggerUnsubscribe();
        return;
      }
      if(this.getCookie('orgId')) {
        this.auth.login();
        this.triggerUnsubscribe();
        return;
      }
      this.createAccountUser$.next(user);
    });
  }

  triggerUnsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  setCookie(name: string, value: boolean|string, days?: number, domain = 'storydoc.com') {
    let expires: string = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + ";domain=."+domain+";path=/";
  }

  deleteCookie(name: string, path = '/', domain = 'storydoc.com') {
    if (this.getCookie(name)) {
      document.cookie =
        name +
        "=" +
        (path ? ";path=" + path : "") +
        (domain ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

  getWebsiteWizardData() {
    const WIZARD_COOKIE_KEY = 'storydoc_wizard_67ebc42a-0835-47b9-9957-6cd8c3d02ef3';
    const data = this.getCookie(WIZARD_COOKIE_KEY);
    if (!data) return {};
    try {
      const parsed = JSON.parse(data);
      const payload = {};
      this.WIZARD_DATA_KEYS.forEach(key => { if (parsed[ key ]) payload[ key ] = parsed[ key ]; });
      return payload;
    } catch (e) {
      return {};
    }
  }


  get landingPagePathname() {
   let pathname = this.landingPage || ''

    const domainToRemove = 'storydoc.com';
    const idx = pathname.indexOf(domainToRemove);
    if (idx !== -1) {
        pathname = pathname.substring(idx + domainToRemove.length);
    }

    return pathname;
  }

  get landingPageCategory() {
    const pathname = this.landingPagePathname;
    if (pathname.startsWith('/blog')) return 'Blog'
    else if (pathname.includes('-template')) return 'Templates'
    else if (pathname.includes('-slides')) return 'Slides'
    else if (/(creator|maker|software|tool)/gi.test(pathname)) return 'Tools'
    return 'General'
  }
}
