import { Component, ElementRef, EventEmitter, Input, Output, SimpleChange, ViewChild, Renderer2 } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AccountsService } from '../../services/accounts.service';
import { ToasterService } from '../../services/toaster.service';

interface Data {
    _id: string;
    title: string;
}

@Component({
    selector: 'ngx-debounced-autocomplete',
    templateUrl: 'debounced-autocomplete.component.html',
    styleUrls: [ 'debounced-autocomplete.component.scss' ],
})
export class DebouncedAutocompleteComponent {

    @ViewChild('input', { static: true }) input: ElementRef;
    @Output() emitOrganizationID = new EventEmitter<string>()
    @Input() initialValue: Data;
    searchResults: Data[] = [];
    searchTerm: string = '';
    loading: boolean = false;
    isInputFocused: boolean = false;
    private containerElement: HTMLDivElement;

    private searchSubject = new Subject<string>();

    constructor(private renderer: Renderer2,
                private toasterService: ToasterService,
                private accountsService: AccountsService) { }

    ngOnInit() {
        this.searchSubject
            .pipe(
                debounceTime(700),
                distinctUntilChanged()
            )
            .subscribe((term: string) => this.search(term));
    }

    ngAfterViewInit() {
        this.containerElement = this.input.nativeElement.closest('.search');
        this.addClickListener();
    }

    ngOnChanges(changes: SimpleChange) {
        if (changes && changes[ 'initialValue' ] && changes[ 'initialValue' ][ 'currentValue' ]) {
            this.searchResults = [ changes[ 'initialValue' ][ 'currentValue' ] ];
            this.searchTerm = changes[ 'initialValue' ][ 'currentValue' ][ 'title' ];
        }
    }

    onSearch(): void {
        this.loading = true;
        this.searchSubject.next(this.searchTerm);
    }

    onFocus() {
        this.isInputFocused = true;
    }

    handleClickOutside() {
        this.isInputFocused = false;
    }

    search(term: string): void {
        if (!term.length) {
            this.loading = false;
            this.searchResults = [];
            return
        }
        this.accountsService.search(term)
        .pipe(
            catchError((err: any): Observable<any> => {
                this.toasterService.showToast('danger', 'Failed search organizations')
                return from([[]]);
            })
        )
            .subscribe((res: Data[]) => {
                this.searchResults = res;
                this.loading = false;
            });
    }

    private addClickListener() {
        this.renderer.listen('window', 'click', (event: Event) => {
            if (!this.containerElement.contains(event.target as HTMLElement)) {
                this.handleClickOutside();
            }
        });
    }


    emitOrganization(obj: Data) {
        this.searchTerm = obj.title;
        this.emitOrganizationID.emit(obj._id)
        this.handleClickOutside();
    }
}
