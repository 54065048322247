import { Component, Input } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import {AnalyticsService} from "../../../services/analytics.service";

@Component({
  selector: "ngx-invite-your-team",
  templateUrl: "./invite-your-team-dialog.component.html",
  styleUrls: ["./invite-your-team-dialog.component.scss"],
})
export class InviteYourTeamDialogComponent {
  constructor(
    private analyticsService: AnalyticsService,
    protected ref: NbDialogRef<InviteYourTeamDialogComponent>
  ) { }

  isOverlay: boolean = true;

  ngOnInit() {
    this.track('view invite your team pop-up');
  }

  closeOverlay() {
    this.isOverlay = false;
  }

  navigateTeamsPage() {
    window.open('/pages/profile/users', '_blank');
  }

  track(event: string, params = {}) {
    this.analyticsService.track(event, {...params});
  }

  close() {
    this.ref.close();
  }
}
