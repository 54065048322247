import { Component } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
    selector: 'ngx-block-pro-upgrade',
    templateUrl: './block-pro-upgrade-dialog.component.html',
    styleUrls: [ './block-pro-upgrade-dialog.component.scss' ]
})
export class BlockProUpgradeDialogComponent { 

    constructor(protected ref: NbDialogRef<BlockProUpgradeDialogComponent>) { }
    

    close() {
        this.ref.close();
    }
}