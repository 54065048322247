import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AssetsDeploymentService {

  constructor(private http: HttpClient) { }
  publishTemplates(): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/publish-templates`);
  }
  getStorydocTemplatesPreview(): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/preview-templates`);
  }
  publishSlides(): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/publish-slides`)
  }

  getCollectionSlides(): Observable<any> {
    return this.http.get(environment.backend + '/assets-deployment/get-collection-slides')
  }
  
  publishLinks(): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/publish-links`)
  }

  publishElements(): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/publish-elements`)
  }

  publishExamples(): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/publish-examples`)
  }

  publishStoryExamples(): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/publish-story-examples`)
  }

  getIndustries(): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/get-industries`)
  }

  getSubtypes(): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/get-subtypes`)
  }
  
  publishNotionPage(id: string): Observable<any> {
    return this.http.get(`${environment.backend}/assets-deployment/notion-deployment?id=${id}`)
  }
}

