import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { from } from "rxjs";
import { catchError, mergeMap, map } from "rxjs/operators";

import { AuthService } from "../../../auth.service";
import { LoginFacade } from "../login.facade";
import { environment } from "../../../../environments/environment";
declare const turnstile: Record<string, Function>;

@Component({
  selector: 'ngx-signup',
  templateUrl: './signup.component.html',
  styleUrls: [ './signup.component.scss' ]
})

export class SignupComponent {

  constructor(private authService: AuthService,
              private loginFacade: LoginFacade,
              private router: Router) {}


  @ViewChild('turnstile', { static: true }) turnstile: ElementRef;
  turnstileURL = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
  isPasswordVisible: boolean = false;
  touched: boolean = false;
  formSubmitError: string = null;
  formLoading: boolean = false;
  token: string = null;
  subtype: string = null;
  errorCodeMapping = {
    'auth/missing-password': 'Please enter a password',
    'auth/weak-password': 'Password must be at least 8 characters long',
    'auth/email-already-in-use': 'Email already in use',
    'auth/missing-email': 'Please enter an email',
    'auth/invalid-email': 'Please enter a valid email',
    'internal-error': 'Internal error, please try again',
  }

  private hasTyped = false;


  formTitle$ = this.loginFacade.formTitle$;
  invitationData$ = this.loginFacade.invitationData$.pipe(map((data) => {
    const { company, team } = data;
    if (!company) return null;
    return {
      company,
      team: `${team} ${Number(team) === 1 ? 'member' : 'members'}`
    }
  }));

  form: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3)
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ])
  });


  ngOnInit() {
    this.loginFacade.parseQueryString();
    this.loginFacade.removeMainLoader();
    this.authService.listenToAuthChanges();
    this.getEmailFromPaddleCheckoutCookie();
    this.setupMobileOnboardingCopy();
    this.loginFacade.track('view signup page');
    this.form.valueChanges.subscribe(() => {
      this.onFirstType();
    });
  }

  ngAfterViewInit() {
    this.loadTurnstileURL();
  }

  setFormSubmitError(errorCode: string | null) {
    if (!errorCode) return this.formSubmitError = null;
    this.formSubmitError = this.errorCodeMapping[ errorCode ] || null;
  }

  setFormLoading(isLoading: boolean) {
    this.formLoading = isLoading;
  }


  openLogin() {
    this.loginFacade.track('click log in on signup');
    this.router.navigateByUrl('/login');
  }

  singUpWithGoogle() {
    this.loginFacade.track('click signup', {
      'user sign up type': 'google.com'
    });
    this.authService.signInWithGoogleProvider();
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  formSubmit() {
    this.touched = true;
    if (this.form.invalid) return;
    this.setFormSubmitError(null);
    this.setFormLoading(true);
    this.loginFacade.track('click signup', {
      'user sign up type': 'password'
    });
    const { email, password, name } = this.form.value;
    const createUser$ = this.authService.validateEmail(email, this.token)
      .then(() => this.authService.setFirebaseUserDisplayName(name))
      .then(() => this.authService.createUserWithEmailAndPassword(email, password))

    from(createUser$)
      .pipe(
        catchError((error) => {
          const errorCode = error.code || 'internal-error';
          this.setFormLoading(false);
          this.setFormSubmitError(errorCode);
          throw error;
      }))
      .subscribe();
  }

  onFirstType() {
    if (this.hasTyped) return;
    this.hasTyped = true;
    this.loginFacade.track('click signup add details');
  }


  loadTurnstileURL() {
    const url = this.turnstileURL;
    const script = document.createElement('script');
    script.src = url;
    script.defer = true;
    document.head.appendChild(script);

    script.onload = () => {
      turnstile.render('#turnstile-container', {
        sitekey: environment.turnstileSiteKey,
        callback: (res: string) => {
          this.token = res;
          setTimeout(() => {
            if (!this.turnstile || !this.turnstile.nativeElement) return;
            this.turnstile.nativeElement.style.display = 'none';
          }, 1500);
        }
      });
    }
  }

  getCookie(name: string) {
    const cookie = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return cookie ? cookie[2] : null;
  };

  getEmailFromPaddleCheckoutCookie() {
    const userData = this.getCookie('sd_paddle_checkout');

    if (!userData || typeof userData !== 'string') return;

    try {
      const { userEmail } = JSON.parse(userData);
      if (!userEmail) return;

      this.form.patchValue({ email: userEmail });
    } catch (err) {
      console.error('Error parsing Paddle checkout cookie:', err);
    }
  }

  setupMobileOnboardingCopy() {
    const WIZARD_COOKIE_KEY = 'storydoc_wizard_67ebc42a-0835-47b9-9957-6cd8c3d02ef3';
    const data = this.getCookie(WIZARD_COOKIE_KEY);
    if (!data) return;
    try {
      const parsed = JSON.parse(data);
      const isMobile = parsed.isMobile;
      if(isMobile) {
        this.loginFacade.formTitle$.next('Your new deck is waiting for you!');
        this.subtype = parsed['subtype select']
      }
    } catch (e) {}
  }

  ngOnDestroy() {
    this.loginFacade.formTitle$.next('Welcome to Storydoc!');
    this.loginFacade.invitationData$.next({});

    this.authService.unsubscribeFromAuthStateListener();

    const script = document.querySelector(`[src="${this.turnstileURL}"]`);
    if (script) script.remove();
  }

}
