import { Component, Input } from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { UpdateBillingDetailsDialogComponent } from "../../../../components/update-billing-details-dialog/update-billing-details-dialog.component";
import { AccountsService } from "../../../../services/accounts.service";
import { take } from "rxjs/operators";
import { HelpCenterService } from "../../../help-center/help.service";
import { Router } from "@angular/router";

@Component({
  selector: "ngx-failed-upgrade-plan-message",
  templateUrl: "./failed-upgrade-plan-message.component.html",
  styleUrls: [ "./failed-upgrade-plan-message.component.scss" ],
})
export class FailedUpgradePlanMessageComponent {
  
  constructor(
    protected ref: NbDialogRef<FailedUpgradePlanMessageComponent>,
    private dialogService: NbDialogService,
    private accountsService: AccountsService,
    private helpCenterService: HelpCenterService,
    private router: Router,
  ) { }


  @Input() isPaddleError: boolean;


  isContactUs: boolean = false;
  isInternalError: boolean = false;

  ngOnInit() {
    this.isContactUs = !!sessionStorage.getItem("retryPayment");
    if (this.isContactUs) sessionStorage.removeItem("retryPayment");
    
    if(!this.isPaddleError) this.isInternalError = true;
  }

  close(status: boolean = false) {
    this.ref.close(status);
  }

  retryPayment() {
    this.close(true);
    sessionStorage.setItem("retryPayment", "true");
  }

  updateBilling() {
    this.accountsService.getOrgInfo$().pipe(take(1)).subscribe(orgInfo => {
      this.ref.close();
      if (typeof orgInfo.paymentsUpdateUrl !== "string") return this.contactUs();
      this.dialogService
        .open(UpdateBillingDetailsDialogComponent, {
          context: {
            orgInfo,
            trackScreen: 'update billing toast'
          },
        })
    });
  }


  contactUs() {
    this.close();
    const data = {
      topic: 'Billing and plans',
      subject: '',
      messagePlaceholder: ''
    }
    this.helpCenterService.setFormFieldsData(data)
    this.router.navigateByUrl("/pages/help");
  }
} 