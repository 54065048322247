import { Component } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { SubscriptionFacade } from "../../../pages/profile/subscription/subscription.facade";
import { SubscriptionDialogFacade } from "../../../pages/profile/subscription/subscription-dialog.facade";

@Component({
  selector: "ngx-early-bird-offer",
  templateUrl: "./early-bird-offer.component.html",
  styleUrls: ["./early-bird-offer.component.scss"],
})
export class EarlyBirdOfferComponent {
  constructor(
    private subscriptionFacade: SubscriptionFacade,
    private subscriptionDialogFacade: SubscriptionDialogFacade
  ) {}

  getEarlyBirdOffer() {
    this.subscriptionFacade.setCookie("sd-paddle-coupon", true, 1);
    this.subscriptionDialogFacade.upgradeToProDialog({source: 'early bird popup'})
  }

  close() {
    this.subscriptionFacade.setCookie("sd-paddle-coupon", "null", 1);
  }

  get isCouponSet() {
    return this.subscriptionFacade.getCookie("sd-paddle-coupon");
  }
}
