export const blankTemplateJSON = {
  createdAt: "2022-12-11T09:50:48.325Z",
  _id: "6395a7f8daa100000c872f27",
  settings: {
    title: "Blank ",
    canonical: "25db5f27-eb73-421c-a4d6-c283923936d7",
    seoEnabled: false,
    favicon: "https://www.storydoc.com/assets/images/branding/favicon.png",
    primaryColor: null,
    cssUrl:
      "https://assets.storydoc.com/8011fa118d5708e6/stylesheet/staging/f28a0a13-e4d1-4bb1-b3c1-e0333a7df092/layout-temp.css",
    addComponent: true,
    showComponentsV3: true,
    wysiwyg: true,
    analyticsDisable: null,
    useDefaultTemplate: null,
    engagementBarEnabled: null,
    designSystem: null,
    localWorkspace:
      "https://127.0.0.1:3100/templates/customer-components-v3.1-icon",
    jwtTokenIat: null,
    og: {
      siteName: "Blank template",
      locale: "en_US",
      description: "Check out my interactive Storydoc presentation",
      image:
        "https://www.storydoc.com/assets/images/branding/og-storydoc.gif",
    },
    fs: { "fs-vars": "[]" },
    footerCode: null,
    lockStory: {
      timer: 60,
      content:
        "<p>Hi, sorry for the inconveinience.</p>\n\n<p>Please come back in a few minutes.</p>\n",
      lock: false,
    },
    designSystemV2: {
      font: "Roboto",
      titleColor: "#12033E",
      subtitleColor: "#12033E",
      paragraphColor: "#12033E",
      hyperlinkColor: "#0177CC",
      highlightColor: "#7106EE",
      highlightTextColor: "#FFFFFF",
      primaryColor: "#12033E",
      secondaryColor: "#E7E5EB",
      themeColor3: "#1676D0",
      themeColor4: "#7106EE",
      buttonRadius: "25px",
      titleFont: "Montserrat",
      subtitleFont: "Montserrat",
      paragraphFont: "Montserrat",
      backgroundColor: "#FFFFFF",
      backgroundColor2: "#FAFAFA",
      buttonColor: "#FFFFFF",
      buttonHoverColor: "#FFFFFF",
      buttonBackgroundColor: "#7106EE",
      buttonBackgroundHoverColor: "#6E16B7",
      titleStyle: "700",
      titleFontSize: "40px",
      subtitleStyle: "600",
      subtitleFontSize: "28px",
      paragraphStyle: "400",
      paragraphFontSize: "18px",
      mobileTitleFontSize: "34px",
      mobileSubtitleFontSize: "24px",
      mobileParagraphFontSize: "16px",
      hyperlinkHoverColor: "#00406D",
      hyperlinkUnderline: null,
      rkpiFontSize: "100%",
      titleMargin: "32px",
      subtitleMargin: "12px",
      paragraphMargin: "12px",
      theme: "Urban vibes",
    },
    contentPointer: null,
  },
  slides: [
    {
      settings: {
        verticalAlignment: "center",
        horizontalAlignment: "center",
        minHeight: "screenFit",
        isCustomHeight: false,
        maxWidth: "medium",
        backgroundType: "Video",
        overlayOpacity: "70",
        mediaSrc:
          "https://assets.storydoc.com/e128f0e44fb5e9d89f8139f0d557c563/d0767e15-a444-44fb-b930-5b036b1868a1",
        gradient: "Linear",
        isHidden: false,
        analytics: "ANALYTICS EVENT NAME",
        backgroundImage:
          "https://images.unsplash.com/photo-1664575196412-ed801e8333a1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDk2Mzl8MXwxfHNlYXJjaHw4fHxiZWF1dGlmdWx8ZW58MHx8fHwxNjY1NTcxNTMy&ixlib=rb-1.2.1&q=80&w=1080&w=1200",
        backgroundVideo:
          "https://assets.storydoc.com/8011fa118d5708e6/933022d0-9fc1-49a2-a4c2-9bd59483c9f5.mp4",
        backgroundColor: "var(--theme-color-1)",
      },
      combos: [
        {
          name: "Simple",
          settings: {
            verticalAlignment: "center",
            horizontalAlignment: "center",
            backgroundColor: "transparent",
            borderColor: "none",
            borderWidth: 0,
            round: "8px",
            minHeight: "auto",
            maxWidth: "medium",
            isCustomWidth: false,
            setsPerLine: "1",
            setsSpace: "16",
          },
          sets: [
            {
              horizontalAlignment: "center",
              verticalAlignment: "start",
              groupsRatio: ["100", "100"],
              groups: [
                [
                  {
                    name: "Image",
                    data: {
                      src: "https://assets-staging.storydoc.com/ea35d7af12c7eaecb34880fb9e7b1ffb/f8aa051f-cd52-4a7c-b217-dcccd9fde36d",
                      alt: "Image",
                      isShadow: false,
                      shadowColor: "#00000025",
                      round: "8px",
                      width: "25",
                      isAddElement: false,
                      horizontalAlignment: "center",
                    },
                    uniqueKey: "f0c7e48f-e4be-4fc0-b0e6-a53af2f1bc1d",
                  },
                  {
                    name: "Separator",
                    data: {
                      borderWidth: null,
                      borderColor: null,
                      height: "20",
                      isAddElement: false,
                    },
                    uniqueKey: "e9f61e52-f552-4900-a0da-095151991eb8",
                  },
                  {
                    name: "RichText",
                    data: {
                      defaultContent:
                        "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>",
                      content:
                        '<h2><span style="color:#ffffff">Add your title here</span></h2>\n',
                      isAddElement: false,
                      horizontalAlignment: "center",
                    },
                    uniqueKey: "93b1f552-ada8-4e2e-8684-219ce2632aef",
                  },
                ],
                [],
              ],
              uniqueKey: "618ddee-33e3-443f-f254-c6f43e5c287f",
            },
          ],
          uniqueKey: "c4554d-8d57-641c-06e8-6e6f68a512f",
        },
      ],
      uniqueKey: "f2d6f0f-8fb-5c1-8d6-1a3d2a4cda3a",
    },
    {
      settings: {
        verticalAlignment: "center",
        horizontalAlignment: "center",
        minHeight: "auto",
        isCustomHeight: false,
        maxWidth: "medium",
        backgroundType: "backgroundColor",
        backgroundColor: "var(--background-main-color)",
        overlayOpacity: "100",
        mediaSrc: "",
        gradient: "Linear",
        isHidden: false,
        analytics: "ANALYTICS EVENT NAME",
      },
      combos: [
        {
          name: "SideBySide",
          settings: {
            maxWidth: "wide",
            verticalAlignment: "inherit",
            horizontalAlignment: "inherit",
            backgroundColor: "transparent",
            borderColor: "transparent",
            borderWidth: 1,
            round: "8px",
            minHeight: "auto",
            isCustomHeight: false,
            isCustomWidth: false,
            setsPerLine: "1",
            setsSpace: "16",
          },
          sets: [
            {
              horizontalAlignment: "inherit",
              verticalAlignment: "inherit",
              groupsRatio: ["30", "70"],
              groups: [
                [
                  {
                    name: "RichText",
                    data: {
                      defaultContent:
                        "<h3><strong>Insert your image title here</strong></h3><p>Add here description of the image or other content that will be presented near it. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum pellentesque molestie bibendum. Nunc sollicitudin lacus sit amet ex bibendum semper.</p>",
                      content:
                        '<h3>Who we are</h3><p>Add here a <strong><span data-highlight-active="false" data-highlight-animation="anim-bg" data-highlight-bg-color="var(--highlight-background-color)" data-highlight-text-color="var(--highlight-text-color)" style="--var-highlight-bg-color:var(--highlight-background-color); --var-highlight-text-color:var(--highlight-text-color);">short description of your company</span></strong>. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
                    },
                    uniqueKey: "a7fbdb3f-5849-4119-9521-efb5a8991dc6",
                  },
                ],
                [
                  {
                    name: "Image",
                    data: {
                      src: "https://assets.storydoc.com/8011fa118d5708e6/75c99c78-04ea-476b-bde9-e05a1a560cf1",
                      alt: "Image",
                      isShadow: true,
                      shadowColor: "#00000025",
                      round: "8px",
                      width: "100",
                      isAddElement: false,
                      linkAnalyticEvent: "imagePlaceholder.jpg",
                      updatedSrc:
                        "https://assets.storydoc.com/8011fa118d5708e6/75c99c78-04ea-476b-bde9-e05a1a560cf1",
                      cropData: null,
                      isShowMainSetting: false,
                      shape: "16-9",
                      horizontalAlignment: "center",
                    },
                    uniqueKey: "3b59e4a7-dfba-4f2d-b045-0939d4e4ede9",
                  },
                ],
              ],
              uniqueKey: "2435a-8f2d-d0b5-0ee8-18db8f53a8a",
            },
          ],
          tabs: [
            {
              name: "content",
              path: "Richtext/Richtext",
              data: {
                defaultContent:
                  "<h3><strong>Insert your image title here</strong></h3><p>Add here description of the image or other content that will be presented near it. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum pellentesque molestie bibendum. Nunc sollicitudin lacus sit amet ex bibendum semper.</p>",
                content:
                  "<h2>Who We Are?</h2><p>Drive TLV hosts, grows and fosters top Israeli startups and entrepreneurs while providing sponsoring organizations with the opportunity to participate in Israel’s cutting-edge Smart Mobility technology revolution</p>",
              },
            },
          ],
          uniqueKey: "71e14d-060-ca73-8440-acb57fba2c",
        },
      ],
      libraryTitle: "Image with text",
      libraryCategory: "Who we are",
      uniqueKey: "83b884f-0100-5520-f236-8e00badfdbd",
    },
  ],
  previewUrl:
    "https://www.storydoc.com/preview/8011fa118d5708e6/6395a7f8daa100000c872f27/1682343848340",
};
