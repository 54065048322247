import { Component, EventEmitter, Input, Output } from "@angular/core";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { WorkspaceFacade } from "../workspace.facade";
import { WorkspaceService } from "../workspace.service";
import { AccountsService } from "../../../services/accounts.service";
import { NbDialogService } from "@nebular/theme";
import { WorkspaceExpiredMessageDialogComponent } from "../workspace-expired-message/workspace-expired-message.component";

@Component({
    selector: 'ngx-workspace-invitation-message', 
    templateUrl: 'workspace-invitation-message.component.html',
    styleUrls: [ 'workspace-invitation-message.component.scss' ],
})
export class WorkspaceInvitationMessageComponent {

    constructor(private workspaceFacade: WorkspaceFacade,
                private accountsService: AccountsService,
                private dialogService: NbDialogService,
                private workspaceService: WorkspaceService) { }


    @Output() discard = new EventEmitter<void>();
    @Input() orgInfo: Record<string, unknown>;
    invitationData: Record<string, any>;
    loading: boolean = false;
    isPaid: boolean = false;
    content: string;


    ngOnInit(): void {
        const invitation = this.workspaceFacade.getCookie();
        if(!invitation) return this.discard.emit();
        const data = JSON.parse(invitation);
        this.invitationData = this.workspaceFacade.readTokenData(data[ 'invitation_token' ]);
        this.invitationData[ 'invitation_token' ] = data[ 'invitation_token' ];
        this.isPaid = this.accountsService.isPaid;
        this.content = this.isPaid
            ? `Your team is already using Storydoc on a different workspace`
            : `${this.invitationData.inviter} invited you to join their team on Storydoc`
    }


    joinWorkspace(): void {
        if (this.orgInfo._id === this.invitationData.orgId) return this.handleSameOrgInvitation();
        if (this.isPaid) return this.payingUserInvitation();
        this.workspaceFacade.track('click to join workspace');
        this.loading = true;
        this.workspaceService.acceptOrganizationInvitation(this.invitationData[ 'invitation_token' ])
        .pipe(
            catchError((err) => {
                if (err.status === 400 && err.error.status === 'expired') {
                   this.dialogService.open(WorkspaceExpiredMessageDialogComponent)
                } else this.workspaceFacade.showToast('danger', 'Failed to join workspace', 'Please try again or contact support')
                this.workspaceFacade.track('failed to join workspace');
                this.loading = false;
                this.workspaceFacade.hasInvitation$.next(false);
                return throwError(err)
            })
        ).subscribe(() => {
            this.workspaceFacade.track('success to join workspace');
            this.workspaceFacade.deleteCookie();
            // this.workspaceFacade.setCookie('sd-success-migration-toaster', true);
            this.workspaceFacade.refreshSession();
        });
    }

    payingUserInvitation(): void {
        this.loading = true;
        this.workspaceService.sendStorydocAdministratorRequestEmail({
            requestOrgId: this.invitationData.orgId,
            email: this.invitationData.email,
            name: this.invitationData.inviter
        })
        .pipe(
            catchError((err) => {
                this.loading = false;
                this.workspaceFacade.showToast('danger', 'Failed to send a request', 'Please try again');
                return throwError(err)
            })
        ).subscribe(() => {
            this.loading = false;
            this.workspaceFacade.deleteCookie();
            this.workspaceFacade.hasInvitation$.next(false);
            this.workspaceFacade.showToast('success', 'Our support team will contact the user that you want to invite');
        });
    }

    discardInvitation(): void {
        this.workspaceFacade.track('click discard workspace invitation');
        this.workspaceFacade.deleteCookie();
        this.discard.emit();
    }

    handleSameOrgInvitation(): void {
        this.workspaceFacade.deleteCookie();
        this.discard.emit();
        this.workspaceFacade.showToast('success', 'You are already a registered user of the workspace');
    }
}