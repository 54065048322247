import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { environment } from '../environments/environment';
import { AuthService } from './auth.service';

declare const firebase;
declare const OktaAuth;

@Component({
  template: `<div>{{error}}</div>`
})
export class OktaCallbackComponent implements OnInit {
  error: string;

  constructor(private okta: OktaAuthService,
              private authService: AuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    try {

      // Custom functionality for catching errors in url.
      const qp = this.activatedRoute.snapshot.queryParams;
      if(Object.keys(qp).length == 0) {
        // if calls the implicit/callback without any parameters - redirect to homepage
        this.router.navigateByUrl('/');
        return;
      } else if(qp && qp.error) {
        // if calls the implicit/callback with an error - notify and sign out
        this.errorOccured(qp);
        return;
      }

      const originalUri = sessionStorage.getItem('okta-app-url');
      if (originalUri) this.okta.setOriginalUri(originalUri);

      // Okta functionality
      const data = await this.okta.token.parseFromUrl();
      const isEditorV4 = await this.isEditorV4(data.tokens.accessToken.value);
      if (isEditorV4) {
        this.authService.listenToAuthChanges();
        this.authService.exchangeToken(data.tokens.accessToken.value).then(token => {
          this.authService.signInWithCustomToken(token);
        })  
      } else {
        window.location.href = environment.editorUrl;
      }
      
      // if is v3 user just redirect to v3

    } catch (e) {
      // if error occures - notify and sign out
      this.errorOccured(e);
    }
  }

  errorOccured(err) {
    if(err && err["name"] === 'AuthApiError' && err["xhr"] && err["xhr"]["status"] === 400) {
      // dont alert on PCKE verification failed issues
    }
    else if(err && err["name"] === 'AuthSdkError' && err["errorSummary"] === 'The JWT was issued in the future') {
      alert ('Your computer system clock is not synchronized. Please sync it and try to login again.')
    }
    else {
      alert('Error occured while logging in\nPlease contact support@storydoc.com')
    }
  }

  async isEditorV4(token) {
    try {
      const response = await fetch(environment.backend + '/accounts/info', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token,
        }
      });
      
      const data = await response.json();
      return data.parameters && data.parameters.isEditorV4;
    } catch (err) {
      return false;
    }
  }
}
