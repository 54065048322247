import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-are-you-sure-dialog',
  templateUrl: 'are-you-sure-dialog.component.html',
  styleUrls: ['are-you-sure-dialog.component.scss'],
})
export class AreYouSureDialogComponent {

  @Input() title = null;
  @Input() body;
  @Input() yes = 'Yes';
  @Input() no = 'No';
  @Input() buttons;

  constructor(protected ref: NbDialogRef<AreYouSureDialogComponent>) {}

  cancel() {
    this.ref.close(false);
  }

  submit(value = undefined) {
    if(value != undefined) {
      this.ref.close(value);
    } else {
      this.ref.close(true);
    }
  }

}
