import { Injectable } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { BehaviorSubject, Observable, Subject, from, throwError } from "rxjs";
import { catchError, switchMap, map, take, takeWhile } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { CheckPaymentStatusDialogComponent } from "../../../components/check-payment-status-dialog/check-payment-status-dialog.component";
import { AnalyticsService } from "../../../services/analytics.service";
import { SenderDataService } from "../../account/account.service";
import { AccountsService } from "../../../services/accounts.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { Plan } from "./plan.interface";
import { MediaService } from "../../../services/media.service";
import { AuthService } from "../../../auth.service";

declare const Paddle;

interface Seats { additionalSeats: number, freeSeats: number, quantity: number };

@Injectable({
  providedIn: "root",
})
export class SubscriptionFacade {
  constructor(
    private dialogService: NbDialogService,
    private senderDataService: SenderDataService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private accountsService: AccountsService,
    private mediaService: MediaService,
    private auth: AuthService
  ) {
    this.getUserEmail();
  }

  isLoadingInlinePaddle$ = new BehaviorSubject<boolean>(false);
  checkoutDetails$ = new BehaviorSubject<Record<string, any>>(null);
  isSuccessPayment$ = new Subject<boolean>();
  userEmail: string = "";
  subscriptionAnalyticsObject: Record<string, string | number>
  seats: Seats;
  isAdministrator: boolean = false;
  quantitySum: number = 0;

  track(event: string, params = {}) {
    this.analyticsService.track(event, {...params});
  }

  getSelectedPlan(period: string = "Annually", title: string = 'Pro') {
    return environment.paddle.plans.find(
      (p) => p.title === title && p.period === period && p.active
    );
  }

  getPaddleOptions(period: string, quantity: number) {
    const plan = this.getSelectedPlan(period);
    const coupon = this.getCouponCode();
    this.quantitySum = quantity + 1;
    this.subscriptionAnalyticsObject = {
      "subscription title": plan.title,
      "subscription period": plan.period,
      "subscription price": plan.price,
    };
    return {
      method: "inline",
      frameTarget: "paddle-checkout-container",
      product: plan.id,
      coupon,
      email: this.userEmail,
      allowQuantity: false,
      quantity: this.quantitySum,
      disableLogout: environment.env === "production" ? true : false,
      frameStyle: "position: static; width: 100%; height: 659px;",
      loadCallback: () => this.isLoadingInlinePaddle$.next(false),
      successCallback: () => this.isSuccessPayment$.next(true),
    };
  }

  initiatePaddle(seats: Seats, period: string = "Annually", source: string = '') {
    this.seats = seats;
    this.isLoadingInlinePaddle$.next(true);
    Paddle.Checkout.open(this.getPaddleOptions(period, this.seats.quantity));
    const timestamp = new Date().toISOString();
    this.updatePlanPropertiesInfo('proTriggerTimestamp', timestamp, 'Open pro dialog', {source});
  }

  teamDialogAnalytics(source: string) {
    this.track('open team dialog', { 'lock feature source': source });
  }

  proDialogAnalytics(source: string, promotionType?: string) {

    const payload = {
      'lock feature source': source,
      ...(promotionType && {
        'promotion type': promotionType
      })
    }

    this.track('open pro dialog', payload);
  }

  getCouponCode(isPro: boolean = true) {
    if (!isPro) return this.getCookie('sd-paddle-coupon');
    const isEarlyBird = this.accountsService.getStaticIsEarlyBirds();
    const testingGroup = this.accountsService.isAGroup ? 'testingGroupA' : 'testingGroupB';
    const earlyBirdCoupon = (environment.earlyBirds[testingGroup] && environment.earlyBirds[testingGroup].coupon) || '';
    const coupon = isEarlyBird ? earlyBirdCoupon : this.getCookie('sd-paddle-coupon');
    return coupon;
  }

  handleHelpButton(trackMessage: string, trackPayload = {}) {
    if (trackMessage) this.track(trackMessage, trackPayload);
    this.analyticsService.sendHubspotInternalEvent('clicked help', { date:  moment().format('DD/MM/YYYY hh:mm')});
    this.analyticsService.sendHubspotProperty({ clicked_help_date:  moment(new Date()).utc().startOf('day').valueOf() });
    return this.router.navigateByUrl('/pages/help');
  }

  openCheckoutPaymentDialog() {

    this.dialogService.open(CheckPaymentStatusDialogComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      context: {
        subscriptionAnalyticsObject: this.subscriptionAnalyticsObject,
        freeSeats: this.seats.freeSeats,
        additionalSeats: this.seats.quantity,
      },
    }).onClose.subscribe(() => {
      this.seats.additionalSeats = 0;
      this.seats.freeSeats = 0;
      this.seats.quantity = 0;
    })
  }

  getUserEmail() {
    this.senderDataService.personalInfoData$
      .pipe(
        takeWhile((data) => !data, true),
        catchError(() => from([null]))
      )
      .subscribe((res) => {
        if (res && res.sender) this.userEmail = res.sender.email;
      });
  }

  getOrgSeats() {
    const { additionalSeats } = this.accountsService.getAccountSeats;
    return Number(additionalSeats);
  }

  closePaymentDialog() {
    this.analyticsService.track("payment dialog closed", this.subscriptionAnalyticsObject);
  }

  updatePlanPropertiesInfo(trigger: string, value: string, action: string = '', source: Record<string, any> = {}) {
    this.auth.isAdministrator$().pipe(take(1)).subscribe((isAdministrator) => {
      if (!isAdministrator) return;
      const payload = { [ trigger ]: value };
      if (trigger === 'proTriggerTimestamp') {
        payload.proTriggerSource = source.source;
      }
      if (trigger === 'teamTriggerTimestamp') {
        payload.teamTriggerSource = source.source;
      }

      this.accountsService.handlePlanProperties(payload).pipe(catchError(() => from([]))).subscribe();
      let key: string;
      if (trigger === 'proTriggerTimestamp') key = 'pro_trigger_timestamp';
      if (trigger === 'teamTriggerTimestamp') key = 'team_trigger_timestamp';
      if (trigger === 'integrationsTriggerTimestamp') key = 'integrations_trigger_timestamp';
      if (!key) return;
      let data = {};
      if (key === 'team_trigger_timestamp') {
        data[ 'team_feature_name' ] = source.source;
      }
      data[ key ] = moment(new Date()).utc().startOf('day').valueOf();
      this.analyticsService.sendHubspotProperty(data);
      this.analyticsService.sendHubspotInternalEvent(action, source);
    })
  }

  getCookie(name: string) {
    return `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift();
  }

  setCookie(name: string, value: boolean|string, days: number) {
    let expires: string = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  deleteCookie(name: string, path: string, domain: string) {
    if (this.getCookie(name)) {
      document.cookie =
        name +
        "=" +
        (path ? ";path=" + path : "") +
        (domain ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

  getOrgInfo$() {
    return this.accountsService.getOrgInfo$().pipe(take(1));
  }

  isExpiredTrial(orgInfo: Record<string, any>) {
    const now = moment(new Date()); //todays date
    const end = moment(orgInfo.trialEnd); // another date
    const duration = moment.duration(end.diff(now));
    const days = duration.asDays();
    return days <= 0;
  }

  closeMediaDialog() {
    this.mediaService.closeMediaLibrary();
  }


  // UPGRADE STARTER TO PRO

  handleAdditionalSeats$(seats: { freeSeats: number }) {
    return this.buyAdditionalSeats$(3)
      .pipe(switchMap(() => this.updateFreeSeats$(seats.freeSeats)))
  }

  updateFreeSeats$(freeSeats: number) {
    return this.accountsService.handlePlanProperties({ freeSeats })
      .pipe(
          catchError((err: any): Observable<any> => {
              this.track('failed to add free seats while update starter to pro', {
                  'failure message': err.message
              })
              return throwError(err)
          }),
      )
  }

  buyAdditionalSeats$(seats: number) {
    return this.accountsService.buySeats(seats).pipe(
      catchError((err: any): Observable<any> => {
        this.track('failed to buy additional seats while update starter to pro', {
            'failure message': err.message
        })
        return throwError(err)
      })
    )
  }

  handleSuccessPaymentAnalytics(paddlePlan: Plan, orgInfo: Record<string, any>, type: string = 'paying') {
    const data = {};
    if (paddlePlan) {
      data["plan"] = paddlePlan.title;
      data["billingPeriod"] = paddlePlan.period;
      data["monthlyValue"] = paddlePlan.period === "Monthly" ? paddlePlan.price : paddlePlan.price / 12;

      this.analyticsService.sendHubspotProperty({
        total_payment_amount: paddlePlan.price,
        number_of_seats: paddlePlan.seats,
        plan_billing_type: paddlePlan.period,
        plan_monthly_value: paddlePlan.period === "Monthly" ? paddlePlan.price : paddlePlan.price / 12,
        plan_name: paddlePlan.title,
        trial_status: "inactive",
        success_paying_customer: moment(new Date()).utc().startOf("day").valueOf()
      });
    }
    this.track(`success ${type} customer`, { 'isAfterSuccessPaying': true, ...this.subscriptionAnalyticsObject });
    if (orgInfo.parameters && orgInfo.parameters.gclid) data[ 'gclid' ] = orgInfo.parameters.gclid;
    this.analyticsService.sendHubspotInternalEvent( `success ${type} customer`, data);
  }

  getEventProperties(period: string, title: string) {
    return {
      'plan length': period.toLowerCase(),
      'plan name': title,
      'plan number of seats': this.quantitySum + 1,
      'plan cost per seat': this.getSelectedPlan(period, title).price,
      'plan total amount': this.getSelectedPlan(period, title).price * (this.quantitySum + 1),
      'plan coupon code': this.getCouponCode(title === 'Pro')
    }
  }
}
