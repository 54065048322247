import { Injectable } from '@angular/core';
import { from, Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { take, filter, mergeMap, catchError, finalize } from 'rxjs/operators';
import { OktaAuthService } from '@okta/okta-angular';
import Cookies from 'js-cookie'
import { LoginFacade } from "./pages/login/login.facade";
declare const firebase;
declare const window;
declare const turnstile;
declare const dataLayer;

import { HttpClient } from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  SSO_DOMAINS = ['storydoc.com', 'opswat.com', 'opswatsb.onmicrosoft.com'];
  currentUser$ = new BehaviorSubject<any>({});
  private authStatusSub = new BehaviorSubject<any>(null);
  currentAuthStatus = this.authStatusSub.asObservable();
  auth: any = {};
  public idToken;
  public loading = false;
  public user: any = {};
  displayName;

  private readonly ORG_CREATION_LOCK = 'sd-org-creation-lock';


  constructor(private okta: OktaAuthService,
              private loginFacade: LoginFacade,
              private http: HttpClient) {
              if (window.currentUser) this.user = this.convertUserStructure(window.currentUser);
              firebase.auth().onAuthStateChanged(user => this.authStatusSub.next(user));
            }

    
  get isLoggedIn() {
    return !!this.firebaseUser;
  }

  isLoggedIn$() {
      return from([this.isLoggedIn]);
  }

  get redirectLink() {
    var url = window.location.origin;
    var redirectUrl = this.getParameterByName('redirectUrl') || '';
    return url + redirectUrl;
  }


  get emailValidationEndpoint() {
    return environment.emailValidationEndpoint;
  }

  
  // Firebase authentication
  get firebaseAuth() {
    // if (this.firebaseAuth) return this.firebaseAuth;
    return firebase.auth();
  }

  get firebaseUser() {
    return this.firebaseAuth.currentUser;
  }

  get userSignUpType() {
    return this.firebaseUser.providerData[0].providerId;
  }


  get isCustomToken() {
    return this.firebaseUser &&  (this.firebaseUser.providerData && !this.firebaseUser.providerData.length)
  }

  private handleOrgCreationLock(setValue: boolean): void {
    try {
      if(setValue) sessionStorage.setItem(this.ORG_CREATION_LOCK, 'true');
      else sessionStorage.removeItem(this.ORG_CREATION_LOCK);
    } catch (e) {
      console.error('Failed to set creation lock:', e);
    }
  }

  private hasCreationLock(): boolean {
    try {
      return sessionStorage.getItem(this.ORG_CREATION_LOCK) === 'true';
    } catch (e) {
      console.error('Failed to check creation lock:', e);
      return false;
    }
  }
  
  convertUserStructure(user) {
    user.sub = user.email;
    user.uid = user.user_id;
    return user;
  }

  updateUser() {
    this.loading = true;
    this.firebaseUser.getIdTokenResult().then((result) => { 
      this.user = this.convertUserStructure(result.claims);
      this.loading = false;
    })
  }

  getIdToken(force = false) {
    if(!this.firebaseUser) return Promise.resolve(null);
    return this.firebaseUser.getIdToken(force).then(token => {
      this.idToken = token;
      return token;
    })
  }

  
  getIdToken$(force = false) {
    return from(this.getIdToken(force));
  }
  

  // Admin Core functions
  get adminCore() {
    return this.user.admin;
  }
  getAdminCore() {
    return this.adminCore;
  }
  getAdminCore$() {
    return from([this.getAdminCore()]);
  }


  get isNavigator() {
    return this.user.isNavigator;
  }
  getNavigator$() {
    return from([this.isNavigator]);
  }

  // Users functions
  getCreatorLite$() {
    if (this.adminCore) {
      if (this.userType == 'user') return from([false]);
      else return from([true]);
    } else {
      return from([this.getUserType()])
    }

  }

  isOrgAdmin() { // actually behaves like a creator
    const isOrgAdmin = this.userType === 'administrator' || this.userType === 'orgAdmin' || this.userType === 'creator';
    if (this.adminCore) {
      return !(this.userType == 'user' || this.userType == 'creator lite');
    }
    return isOrgAdmin;
  }
  isOrgAdmin$(): Observable<any> { // actually behaves like a creator
    return from([this.isOrgAdmin()]);
  }

  isAdministrator() {
    if (this.adminCore) {
      if (['user', 'orgAdmin', 'creator', 'creator lite'].includes(this.userType)) return false;
      else return true;
    } else {
      return this.userType === "administrator";
    }
  }
  isAdministrator$(): Observable<any> {
    return from([this.isAdministrator()]);
  }

  isAdmin() {
    if (this.adminCore) {
      if (['user', 'orgAdmin', 'creator', 'creator lite'].includes(this.userType)) return false;
      return true;
    } else {
      return false;
    }
  }
  isAdmin$(): Observable<any> {
    return from([this.isAdmin()]);
  }

  isAdminCore(): Observable<any> {
    return this.adminCore;
  }
  isAdminCore$(): Observable<any> {
    return from([this.adminCore])
  }


  adminOrgIdKey = 'dotell-admin-orgId-2';
  adminUserTypeKey = 'dotell-admin-user-type-2';

  getUserType() {
    if (this.adminCore) {
      const userType = Cookies.get(this.adminUserTypeKey) || window.localStorage.getItem(this.adminUserTypeKey);
      return userType || "administrator";
    } else {
      return this.user.userType;
    }
  }
  getUserType$(): Observable<any> {
    return from([this.getUserType()]);
  }

  get userType() {
    return this.getUserType();
  }

  set userType(value) {
    Cookies.remove(this.adminUserTypeKey)
    Cookies.set(this.adminUserTypeKey, `${value}`);
    window.localStorage.setItem(this.adminUserTypeKey, `${value}`);
  }

  getOrgId(): any { // src/app/@theme/components/header/header.component.ts
    let userOrgId = this.user.orgId;
    
    if (this.adminCore && this.isNavigator) {
      let adminOrgId = Cookies.get(this.adminOrgIdKey) || window.localStorage.getItem(this.adminOrgIdKey);
      if (adminOrgId) userOrgId = adminOrgId;
    }
    return userOrgId;
  }
  getOrgId$(): Observable<any> { // src/app/@theme/components/header/header.component.ts
    return from([this.getOrgId()]);
  }

  setOrgId(value) {
    Cookies.remove(this.adminOrgIdKey);
    Cookies.set(this.adminOrgIdKey, value);
    window.localStorage.setItem(this.adminOrgIdKey, value);
  }

  getAnalyticsLink$(): Observable<any> {
    return from([null]); // disabled
  }

  getUser(): any {
    return this.user;
  }
  getUser$(): Observable<any> {
    return from([this.getUser()]);
  }

  updateCurrentUser(user) {
    this.currentUser$.next(user);
  }

  // ========== Firebase ==========

  async validateEmail(email: string, token: string) {
    let data;
    const disableValidation = (window.location.search.indexOf('disableValidation') > -1);
    try {
      if (!email || !token) throw new Error("Missing email or token");
      const response = await fetch(environment.emailValidationEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({ email })
      });

      data = await response.json();
      turnstile.reset();
    } catch (error) {
      turnstile.reset();
      throw { code: 'auth/invalid-email' }; // fallback to valid email
    }
    if (data && data.status === "invalid" && !disableValidation) throw { code: 'auth/invalid-email' }
    return { "status": "valid" }; // fallback to valid email
  }

  verifyPasswordResetCode(code) {
      return firebase.auth().verifyPasswordResetCode(code);
  }

  confirmPasswordReset(code: string, password: string) {
    return firebase.auth().confirmPasswordReset(code, password)
  }

  createUserWithEmailAndPassword(email, password) {
      return firebase.auth().createUserWithEmailAndPassword(email, password)
  }

  signInWithEmailAndPassword(email, password) {
      return firebase.auth().signInWithEmailAndPassword(email, password)
  }

  signInWithGoogleProvider() {
      var provider = new firebase.auth.GoogleAuthProvider();
      return firebase.auth().signInWithPopup(provider)
  }

  signInWithCustomToken(customToken) {
    return firebase.auth().signInWithCustomToken(customToken)
  }

  signInWithSAMLProvider(domain: string) {
      const provider = new firebase.auth.SAMLAuthProvider(`saml.${domain}`);
      return firebase.auth().signInWithRedirect(provider)
  }
  
  setFirebaseUserDisplayName(name) {
    return this.displayName = name;
  }

  logout() {
    sessionStorage.removeItem('liveblocks:collaboration:token');
    this.firebaseAuth.signOut();
    this.login();
  }


  login(originalUrl: string = null) {
    const { pathname, search } = window.location;
    const redirectUrl = pathname.includes('logout') ? '' : `?redirectUrl=${encodeURIComponent(pathname + search)}`;
    window.location.href = environment.loginUrl + redirectUrl ;
  }

  redirectAfterLogin() {
    window.location.href = this.redirectLink;
  }

  listenerSubscription;
  listenToAuthChanges() {
    if (this.listenerSubscription) this.unsubscribeFromAuthStateListener();
    this.listenerSubscription = this.currentAuthStatus
      .pipe(filter(user => {
        return !!user
      }))
      .pipe(take(1))
      .subscribe(user => {
        return this.onStateChanged(user)
      })
    return this.listenerSubscription;
  }

  unsubscribeFromAuthStateListener() {
   if(this.listenerSubscription) this.listenerSubscription.unsubscribe(); 
  }

  onStateChanged(user) {
    user.getIdTokenResult(true).then(idTokenResult => {
      if (idTokenResult.claims.orgId) {
          this.loginFacade.track('success account login');  
          this.redirectAfterLogin();
        } else {
          const SSO_NAME = idTokenResult.claims.firebase.sign_in_attributes && idTokenResult.claims.firebase.sign_in_attributes['http://schemas.microsoft.com/identity/claims/displayname'];
          const name = this.displayName || idTokenResult.claims.name || SSO_NAME;
          this.firebaseUser.updateProfile({ displayName: name });
          this.createOrgHandler(name).subscribe();
        }
      
    })
  }

  createOrgHandler(name) {

    if (this.hasCreationLock()) return of(null);
    this.handleOrgCreationLock(true);

    const parameters = this.loginFacade.getSetupData();
    return this.createOrg(parameters, name)
      .pipe(
        mergeMap((response: any) => {
          if (response && response.redirect) {
            this.firebaseAuth.signOut();
            window.location.href = response.redirect;
            return;
          }
          if(window.dataLayer) window.dataLayer.push({'event':'submission','event-category':'click','event-action': "cta_sign_up"});
          return this.getIdToken(true).then(() => this.redirectAfterLogin());
        }),
        catchError(err => {
          this.handleOrgCreationLock(false);
          return throwError(err);
        }),
        finalize(() => {
          this.handleOrgCreationLock(false);
        })
      );
  }

  // ========== backend ==========
  createOrg(parameters, name) {
    return this.http.post(environment.backend + '/auth/create', {parameters, name});
  }

  exchangeToken(idToken) {
      return fetch(environment.backend + '/auth/exchangeToken', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + idToken
        },
      })
      .then(response => response.json())
      .then(response => response.token);
  }
    

  // ========== Okta ==========
  signInWithOkta(email, password) {
      return this.okta.signInWithCredentials({ username:email, password })
          .then((transaction) => {
              if (transaction.status === 'SUCCESS') {
                this.okta.token.getWithRedirect({
                  sessionToken: transaction.sessionToken,
                  responseType: 'id_token'
                });
              } else {
                  console.log('We cannot handle the ' + transaction.status + ' status');
              }
          });
  }

  get userTypeMapping() {
    return {
      'DoTell employees': 'adminCore',
      'Navigator': 'isNavigator',
      'Creator lite': 'creator lite',
      'Org admin': 'creator',
      'Administrator': 'administrator',
      'User': 'user',
      'Commenter': 'commenter',
      'Viewer': 'viewer',
    }
  }

  getTypeName(oktaName) {
    return this.userTypeMapping[oktaName] || 'user';
  }

  
  // ========== utils ==========
  getParameterByName(name) {
      name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
      var regexS = "[\\?&]"+name+"=([^&#]*)";
      var regex = new RegExp( regexS );
      var results = regex.exec( window.location.href );
      if( results == null )
          return "";
      else
          return decodeURIComponent(results[1].replace(/\+/g, " "));
  }


}