import { Component, Input } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { SubscriptionFacade } from "../subscription.facade";

declare const Paddle;

@Component({
  selector: "ngx-upgrade-to-team",
  templateUrl: "./upgrade-to-team-dialog.component.html",
  styleUrls: ["./upgrade-to-team-dialog.component.scss"],
})
export class UpgradeToTeamDialogComponent {
  
  constructor(
    protected ref: NbDialogRef<UpgradeToTeamDialogComponent>,
    private subscriptionFacade: SubscriptionFacade,
  ) { }
  
  @Input() title: string = 'This feature is available <br> in a <strong>Team plan</strong>'
  @Input() source: string = ''

  isOverlay: boolean = true;
  planInclude: string[] = [
    "Tailor-made templates created by our studio",
    "CRM integration",
    "Custom subdomain",
    "Professional team setup, onboarding, and training",
    "Designated Storydoc expert",
    "Team roles & permissions",
    "SSO Support (SAML)",
  ];
  companies: string[] = [ 'nice', 'meta', 'rtl', 'spot', 'yotpo', 'healthy' ]
  badges = [ 'badge-1.png', 'badge-2.png', 'badge-3.png', 'badge-4.png', 'badge-5.png', 'badge-6.png']

  ngOnInit() {
    const timestamp = new Date().toISOString();
    this.subscriptionFacade.updatePlanPropertiesInfo('teamTriggerTimestamp', timestamp, 'Open team dialog', {source: this.source});
    this.subscriptionFacade.teamDialogAnalytics(this.source);
    this.subscriptionFacade.track('view popup team plan', {
      'plan name': 'Team',
    });
  }
  closeOverlay() {
    this.isOverlay = false;
    this.subscriptionFacade.track('click book a team plan call', {
      'plan name': 'Team',
    });
  }

  navigatePlansPage() {
    window.open('/pages/profile/plans', '_blank');
  }

  close() {
    this.ref.close();
  }
}
