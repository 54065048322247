export const updateSlidePreview = (logo: string, slide, _) => {

  const groups = _.get(slide, 'combos[0].sets[0].groups', []);
  let isImage = false;
  groups.forEach(group => {
    if (isImage) return;
    for (const item of group) {
      const image = ['Image', 'Logo'].includes(item.name);
      if (image) {
        isImage = true;
        item.data.updatedSrc = logo;
        item.data.src = logo;
        return;
      }
    }
  });
  return { ...slide }
}

export const updateNormalizedSlidePreview = (logo: string, slide, _) => {

  const groups = _.get(slide, 'children[0].children[0].children', []);
  let isImage = false;
  groups.forEach(group => {
    if (isImage) return;
    for (const item of group.children) {
      const image = ['Image', 'Logo'].includes(item.settings.name);
      if (image) {
        isImage = true;
        item.settings.updatedSrc = logo;
        item.settings.src = logo;
        return;
      }
    }
  });
  return { ...slide }
}
