import { Component, Input } from "@angular/core";
import { SubscriptionDialogFacade } from "../../../pages/profile/subscription/subscription-dialog.facade";

@Component({
  selector: "ngx-go-team-button",
  templateUrl: "go-team-button.component.html",
  styleUrls: ["go-team-button.component.scss"],
})
export class GoTeamButtonComponent {
  constructor(private subscriptionDialogFacade: SubscriptionDialogFacade) {}

  @Input() style: Record<string, string>;
  @Input() title: string = "Get more";
  @Input() source: string = '';
  @Input() primary: boolean = false;

  upgradeToTeam() {
    this.subscriptionDialogFacade.upgradeToTeamDialog(this.source);
  }
}
