import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { AccountsService } from '../../services/accounts.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'ngx-delete-account-dialog',
  templateUrl: 'delete-account-dialog.component.html',
  styleUrls: ['delete-account-dialog.component.scss'],
})
export class DeleteAccountDialog {

  constructor(protected ref: NbDialogRef<DeleteAccountDialog>,
    private accountsService: AccountsService,
     private analyticsService: AnalyticsService
  ) {}

  @Input() body = 'Are you sure you want to <br> close this process?'
  @Input() primary = true;
  @Input() secondary = true;
  @Input() primaryText = 'Yes';
  @Input() secondaryText = 'Go back';

  loaderGif = 'deleting';
  isDeleting = false;
  deleteMode = false;

  submit() {
    this.isDeleting = true;
    this.deleteMode = true;
    this.accountsService.deleteAccount().pipe(
      catchError((err: any): Observable<any> => {
        this.isDeleting = false;
        this.loaderGif = 'failed-delete';
        this.analyticsService.track('failed delete account', { error: err });
        return throwError(err);
      }),
    ).subscribe(() => {
      this.loaderGif = 'success-delete';
      this.isDeleting = false;
    })
  }

  close(reload = false) {
    if(this.isDeleting) return;
    this.ref.close();
    if(reload && this.loaderGif !== 'failed-delete') window.location.reload();
  }

}
