import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { repeat, delay, finalize, catchError } from "rxjs/operators";
import { NbDialogRef } from "@nebular/theme";
import { Router } from "@angular/router";
import { AccountsService } from "../../services/accounts.service";
import { AnalyticsService } from "../../services/analytics.service";
import { ToasterService } from "../../services/toaster.service";

@Component({
  selector: "ngx-update-billing-details-dialog",
  templateUrl: "./update-billing-details-dialog.component.html",
  styleUrls: ["./update-billing-details-dialog.component.scss"],
})
export class UpdateBillingDetailsDialogComponent implements OnInit, OnDestroy {
  @Input() orgInfo: any;
  @Input() trackScreen: any;
  @ViewChild("paddleIframe", { static: true })
  iframe: ElementRef<HTMLIFrameElement>;

  isLoading: boolean = false;
  success: boolean = false;
  subscription: any;

  constructor(
    protected ref: NbDialogRef<UpdateBillingDetailsDialogComponent>,
    public router: Router,
    private analyticsService: AnalyticsService,
    private toasterService: ToasterService,
    private accountsService: AccountsService
  ) { }

  handlePaddleEvents(event: any) {
    if (event.data.action === "complete") {
      this.startChecking();
    }
  }

  track(event: string, params: any = {}) {
    this.analyticsService.track(event, {
      screen: this.trackScreen,
      ...params,
    });
  }

  startChecking() {
    this.isLoading = true;
    this.subscription = this.accountsService
      .info(true)
      .pipe(delay(2500))
      .pipe(repeat(120))
      .pipe(
        finalize(() => {
          this.isLoading = false;
          if (!this.success) {
            this.track("failed to update billing details", {
              "failure message": "5 minutes passed with no update",
            });
            this.toasterService.showToast('danger', 'Billing details update has failed');
            this.close();
          }
        })
      )
      .subscribe((org) => {
        if (org.subscriptionPlanId) {
          if (org.subscriptionStatus !== "past_due") {
            window.localStorage.setItem(
              "updatedBilling",
              org.subscriptionStatus === "active" ? "true" : "false"
            );
            this.success = true;
            this.track("success updaing billing details", {
              'user status': org.subscriptionStatus
            });
            window.location.reload(true);
          }
        } else if (org.lastPaymentErrorMessage) {
          this.track("failed to update billing details", {
            "failure message": `lastPaymentErrorMessage: ${org.lastPaymentErrorMessage}`,
          });
          this.toasterService.showToast('danger', 'Billing details update has failed');
          this.close();
        }
      });
  }

  ngOnInit() {
    window.addEventListener(
      "message",
      this.handlePaddleEvents.bind(this),
      false
    );

    const f = this.iframe.nativeElement;
    if (f && this.orgInfo.paymentsUpdateUrl) {
      f.src = this.orgInfo.paymentsUpdateUrl;
    }
  }

  clearSubscription() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  close(value = false) {
    this.ref.close(value);
  }

  ngOnDestroy() {
    window.removeEventListener("message", this.handlePaddleEvents.bind(this));
    this.clearSubscription();
  }
}
