export const extractDomainName = (url: string) => {
  var domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/g;
  var match = domainRegex.exec(url);
  var domain = match && match[1];

  if (domain && domain.startsWith("www.")) {
    domain = domain.substring(4);
  }

  return domain;
};
