import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() { 
    this.isCouponApplied$.next(!!this.getCookie('sd-paddle-coupon'));
  }

  isCouponApplied$ = new BehaviorSubject<boolean>(false);

  getCookie(name: string) {
    return `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift();
  }

  setCookie(name: string, value: boolean|string, days?: number, domain = 'storydoc.com') {
    let expires: string = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + ";domain=."+domain+";path=/";
  }

  deleteCookie(name: string, path = '/', domain = 'storydoc.com') {
    if (this.getCookie(name)) {
      document.cookie =
        name +
        "=" +
        (path ? ";path=" + path : "") +
        (domain ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

}
