import { parseHTMLContent, updateAnimatedNumbers, updateButton, updateChart, updateCollapsibleText, updateGrid, updateIcon, updateImage, updateSingleTabs, updateSlideBackground, updateTabs, updateVideo } from "../../../wizard/utils";
import { Industry } from "../../../wizard/wizard.interface";

export const mapStoryClassification = (
    slidesToMap: Record<string, any>[],
    json: Record<string, any>,
    industryClassification: Industry | Record<string, never> = {},
    icons: any = { line: [], fill: [] },
    failCallback: (e: Error) => void = () => {}
) => {
  try {
    const slides = [...slidesToMap];
    json = { ...json, ...industryClassification };
    slides.forEach((slide, index) => {
      slide.settings = updateSlideBackground(slide.settings, json);
      slide.combos.forEach((combo) => {
        if (combo.name === "Tabs")
          combo.settings = updateTabs(combo.settings, json);
        combo.sets.forEach((set) => {
          set.groups.forEach((group) => {
            group.forEach((element) => {
              if (element.name === "RichText")
                element.data.content = parseHTMLContent(
                  element.data.content,
                  json
                );
              if (element.name === "CollapsibleText")
                element.data = updateCollapsibleText(element.data, json);
              if (element.name === "Icon")
                element.data = updateIcon(element.data, json, icons);
              if (element.name === "Grid")
                element.data = updateGrid(element.data, json);
              if (["Image", "Logo"].includes(element.name))
                element.data = updateImage(element.data, json);
              if (element.name === "Video")
                element.data = updateVideo(element.data, json);
            });
          });
        });
      });
    });
    return slides;
  } catch (e) {
    failCallback(e)
    return slidesToMap;
  }
};

export const mapNormalizedStoryClassification = (
    slidesToMap: Record<string, any>[],
    json: Record<string, any>,
    industryClassification: Industry | Record<string, never> = {},
    icons: any = { line: [], fill: [] },
    failCallback: (e: Error) => void = () => {}
) => {
  try {
    const slides = [...slidesToMap];
    json = { ...json, ...industryClassification };
    slides.forEach((slide) => {
      slide.settings = updateSlideBackground(slide.settings, json);
      slide.children.forEach((combo) => {
        combo.children.forEach((set) => {
          if(combo.settings.name === "Tabs") set.settings = updateSingleTabs(set.settings, json);
          set.children.forEach((group) => {
            group.children.forEach((element) => {
              const name = element.settings.name;
              if (name === "RichText") element.settings.content = parseHTMLContent(element.settings.content, json);
              if (name === "CollapsibleText") element.settings = updateCollapsibleText(element.settings, json);
              if (name === "Icon") element.settings = updateIcon(element.settings, json, icons);
              if (name === "Grid") element.settings = updateGrid(element.settings, json);
              if (["Image", "Logo"].includes(name))  element.settings = updateImage(element.settings, json);
              if (name === "Video") element.settings = updateVideo(element.settings, json);
              if (name === "AnimatedNumbers") element.settings = updateAnimatedNumbers(element.settings, json);
              if (name === "Button") element.settings = updateButton(element.settings, json);
              if(name === 'Chart') element.settings = updateChart(element.settings, json);
            });
          });
        });
      });
    });
    return slides;
  } catch (e) {
    failCallback(e)
    return slidesToMap;
  }
};
