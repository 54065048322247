import { Component } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { Router } from "@angular/router";
import { NbDialogService } from "@nebular/theme";

import { BlockProUpgradeDialogComponent } from "../../pages/profile/subscription/block-pro-upgrade-dialog/block-pro-upgrade-dialog.component";

import { AuthService } from "../../auth.service";
import { HelpCenterService } from "../../pages/help-center/help.service";
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: "ngx-plan-has-been-cancelled-dialog",
  templateUrl: "plan-has-been-cancelled-dialog.component.html",
  styleUrls: ["plan-has-been-cancelled-dialog.component.scss"],
})
export class PlanHasBeenCancelledDialogComponent {
  isAdministrator: boolean = false;
  constructor(
    protected ref: NbDialogRef<PlanHasBeenCancelledDialogComponent>,
    private auth: AuthService,
    private dialogService: NbDialogService,
    private helpCenterService: HelpCenterService,
    public analyticsService: AnalyticsService,
    public router: Router
  ) {
    this.auth
      .isAdministrator$()
      .subscribe((res) => (this.isAdministrator = res));

    this.track('view plan has been cancelled dialog')
  }

  track(event: string, params = {}) {
    this.analyticsService.track(event, {
      'user is administrator': this.isAdministrator,
      'user status': 'cancelled',
      'screen': 'plan has been cancelled dialog',
      ...params,
    });
  }

  onSubscribeNow() {
    if (!this.isAdministrator) {
      this.track('view block subscribe dialog')
      this.dialogService
        .open(BlockProUpgradeDialogComponent, { context: {} })
        .onClose.subscribe(() => {
          this.dialogService
            .open(PlanHasBeenCancelledDialogComponent, {
              closeOnBackdropClick: false,
              closeOnEsc: false,
              context: {},
            })
            .onClose.subscribe((action) => {
              if (action === 'subscribe') this.onSubscribeNow();
            });
        });
      this.ref.close();
    } else {
      this.track('click subscribe now')
      this.router.navigateByUrl("/pages/profile/plans");
      this.ref.close('subscribe');
    }
  }

  onContactUs() {
    this.track('click contact us')
    const data = {
      topic: 'Billing and plans',
      subject: '',
      messagePlaceholder: ''
    }
    this.helpCenterService.setFormFieldsData(data)
    this.router.navigateByUrl("/pages/help");
    this.ref.close('contact');
  }
}
