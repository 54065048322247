import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IDomainOrg } from "./workspace.interface";

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService { 

    constructor(private http: HttpClient) { }

    createInvitationLink(): Observable<{link: string, account: Record<string, any>}> {
        return this.http.get<{link: string, account: Record<string, any>}>(environment.backend + '/accounts/create-invitation-link');
    }
    getInvitationLink(): Observable<{link: string}> {
        return this.http.get<{link: string}>(environment.backend + '/accounts/get-invitation-link');
    }
    deleteInvitationLink(): Observable<{ account: Record<string, any>}> {
        return this.http.get<{account: Record<string, any>}>(environment.backend + '/accounts/delete-invitation-link');
    }

    getDomainOrg(): Observable<IDomainOrg> {
        return this.http.get<IDomainOrg>(environment.backend + '/accounts/domain-organization');
    }

    sendMigrationInvitationLink(requestedId: string): Observable<void> {
        return this.http.post<void>(environment.backend + `/accounts/create-migration-link-and-send`, {requestedId});
    }

    validateWorkspaceMigrationEmail(token: string): Observable<{status: boolean}> {
        return this.http.post<{status: boolean}>(environment.backend + `/accounts/validate-migration-link`, {token});
    }

    pullOrCreateUser(user: Record<string, unknown>): Observable<Record<string, unknown>> {
        return this.http.post<Record<string, unknown>>(environment.backend + '/v2/users/pull-or-create-user', user);
    }

    pullAvailableUser(user: Record<string, unknown>): Observable<Record<string, unknown>> {
        return this.http.post<Record<string, unknown>>(environment.backend + '/v2/users/pull-available-user', user);
    }

    sendMigrationEmailRequest(requestEmail: string): Observable<void> {
        return this.http.post<void>(environment.backend + `/v2/users/request-migration-email`, {requestEmail});
    }

    resendWorkspaceMigrationEmail(): Observable<void> {
        return this.http.get<void>(environment.backend + `/v2/users/resend-migration-link`);
    }

    sendStorydocAdministratorRequestEmail(data: { requestOrgId: string, email: string, name: string }): Observable<void> {
        return this.http.post<void>(environment.backend + `/v2/users/invitation-migration-email`, data);
    }
    
    acceptOrganizationInvitation(token: string): Observable<void> {
        return this.http.post<void>(environment.backend + '/v2/users/accept-organization-invitation', {token});
    }
    
    sendTrialUserInvitation(email: string, firstName: string): Observable<void> {
        return this.http.post<void>(environment.backend + '/v2/users/trial-user-invitation', {email, firstName});
    }
}