import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Template } from './template.interface';
import { environment } from '../../../../environments/environment';
import { shareReplay, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private http: HttpClient) { }

  FETCH_URL: string = "https://www.storydoc.com/assets/json/templates.min.json";
  selectTemplate$ = new Subject<Template|null|undefined>();
  singleTemplate$ = new Subject<Template|null|undefined>();
  templatesPreview$ = new BehaviorSubject<boolean>(null);
  sandboxEnv$ = new Subject<boolean>();
  private cacheMap = {};
  private cacheTemplates;

  getStorydocTemplates(): Observable<any> {
    if (!this.cacheTemplates) {
      this.cacheTemplates = this.http.get(this.FETCH_URL).pipe(tap((data) => {
        this.cacheTemplates = of(data);
      }))
    }
    return this.cacheTemplates;
  }

  getSingleTemplate(id: string): Observable<any> {
    if (!this.cacheMap[id]) {
      this.cacheMap[id] = this.http.get(`https://www.storydoc.com/assets/json/templates/${id}.json`).pipe(
        tap((data) => {
          this.cacheMap[id] = of(data);
        })
      );
    }

    return this.cacheMap[id];
  }

  fetchTemplateFromGCP(storyUrl: string): Observable<any> {
    return this.http.get(storyUrl);
  }

  updateSelectedTemplate(template: Template|null) {
    this.selectTemplate$.next(template)
  }
  updateSingleTemplate(template: Template|null) {
    this.singleTemplate$.next(template)
  }

  sandboxEnvUpdate(status: boolean) {
    this.sandboxEnv$.next(status);
  }
}

