import { Component, OnInit, Input } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { NavigationEnd, Router } from "@angular/router";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, filter, take, takeUntil } from "rxjs/operators";
import { AnalyticsService } from '../../services/analytics.service';
import { WizardV2Facade } from "../wizard-v2/wizard-v2.facade";

@Component({
  selector: "ngx-mobile-message-dialog",
  templateUrl: "./mobile-message-dialog.component.html",
  styleUrls: ["./mobile-message-dialog.component.scss"],
})
export class MobileMessageDialog implements OnInit {
  @Input() isFullscreen: boolean = true;
  constructor(
    protected ref: NbDialogRef<MobileMessageDialog>,
    private analyticsService: AnalyticsService,
    private wizardV2Facade: WizardV2Facade,
    public router: Router
  ) { }

  unsubscribe$ = new Subject<void>();
  invalidMobilePath = [ '/pages/wizard', '/pages/analytics', '/pages/integrations/hubspot-iframe' ];

  ngOnInit(): void {
    this.analyticsService.track('view mobile message dialog')
    this.screenResizeListener();
    this.closeDialogListener();
    this.urlChangeListener();
  }

  ngAfterViewInit() {
    if (this.isHideMobileDialog(window.location.pathname)) {
      this.onClose();
    }
  }

  screenResizeListener(): void {
    fromEvent(window, "resize")
      .pipe(debounceTime(250))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: Event) => {
        if (window.innerWidth >= 1200 || this.isHideMobileDialog(window.location.pathname)) {
          document.body.style.overflow = "initial";
          this.onClose();
        }
      });
  }

  handleGoToAnalytics() {
    if (window.location.pathname === "/pages/analytics") return this.onClose();

    const u = new URL(window.location.href);
    u.pathname = "/pages/analytics";
    window.location.replace(u.toString());

    this.analyticsService.track('click go to analytics from mobile message dialog')
  }

  closeDialogListener() { 
    this.wizardV2Facade.triggerCloseMobileMessageDialog$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value) this.onClose();
      });
  }

  onClose() {
    this.ref.close();
  }

  urlChangeListener() {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((event: NavigationEnd) => {
      if (!this.isHideMobileDialog(event.url)) return;
      this.onClose();
    });
  }

  isHideMobileDialog(url: string): boolean {
    return this.invalidMobilePath.includes(url);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
