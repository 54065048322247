import { Component } from "@angular/core";
import { SubscriptionFacade } from "../subscription.facade";
import { AnalyticsService } from "../../../../services/analytics.service";
import { AccountsService } from "../../../../services/accounts.service";
import { catchError, delay, finalize, repeat, switchMap } from "rxjs/operators";
import { ToasterService } from "../../../../services/toaster.service";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { Observable, Subscription, from, throwError } from "rxjs";
import { FailedUpgradePlanMessageComponent } from "../failed-upgrade-plan-message/failed-upgrade-plan-message.components";
import { SuccessUpdatePlanDialog } from "../success-update-plan-dialog/success-update-plan-dialog.component";

@Component({
    selector: "ngx-upgrade-starter-to-pro",
    templateUrl: "./upgrade-starter-to-pro-dialog.component.html",
    styleUrls: [ "./upgrade-starter-to-pro-dialog.component.scss" ],
})
export class UpgradeStarterToProDialogComponent { 

    constructor(
        protected ref: NbDialogRef<UpgradeStarterToProDialogComponent>,
        private subscriptionFacade: SubscriptionFacade,
        private analyticsService: AnalyticsService,
        private toasterService: ToasterService,
        private accountsService: AccountsService,
        private dialogService: NbDialogService
    ) { }

    loading = false;
    addAdditionalSeats = false;
    isAnnualPayment = false;
    failure = false;
    success = false;
    proPlanPrice: number;
    totalPrice: number;
    totalUsersCount: number;
    proPlanMonthlyPrice: number;
    orgInfo: Record<string, any>;
    subscription: Subscription;
    planInclude: string[] = [
        "Unlimited templates",
        "Deck personalization with dynamic variables",
        "Custom branding. Storydoc badge removed",
        "Create presentations in bulk",
        "Analytics integrations",
        "Professional brand editor",
        "Unlimited logo finder queries",
    ];
    subscriptionAnalyticsObject: {
        'subscription title': string,
        'subscription period': string,
        'subscription price': number,
        'is starter to pro': true
    }
    seats: { freeSeats: number, quantity: number };

    track(event: string, params = {}) {
        this.analyticsService.track(event, {
            screen: "subscription-page",
            ...params,
        });
    }

    ngOnInit() {
        this.accountsService.getOrgInfo$().subscribe(res => this.orgInfo = res);
        this.handlePeriodPaymentValue();
    }


    setPaymentTime(period: 'Annually'|'Monthly') {
        if ((period === 'Annually' && this.isAnnualPayment) || (period === 'Monthly' && !this.isAnnualPayment)) return;
        this.isAnnualPayment = period === 'Annually';
        if (!this.isAnnualPayment && this.addAdditionalSeats) this.addAdditionalSeats = false;
        this.handlePeriodPaymentValue();
    }

    setAddAdditionalSeats() {
        if (!this.isAnnualPayment) return;
        this.addAdditionalSeats = !this.addAdditionalSeats;
        this.handlePeriodPaymentValue();
    }

    getPaymentPeriod(): string {
        return this.isAnnualPayment ? "Annually" : "Monthly";
    }

    handlePeriodPaymentValue() {
        this.seats = this.calculatedSeatsHandler();
        this.totalUsersCount = this.seats.quantity + this.seats.freeSeats;
        const period = this.getPaymentPeriod();
        const plan = this.subscriptionFacade.getSelectedPlan(period);
        this.proPlanMonthlyPrice = this.subscriptionFacade.getSelectedPlan("Monthly").price;
        this.proPlanPrice = this.isAnnualPayment ? plan.price / 12 : plan.price;
        this.totalPrice = plan.price * this.seats.quantity
        if (this.addAdditionalSeats) {
            const totalSeats = this.seats.quantity;;
            this.proPlanMonthlyPrice = plan.price / 12;
            this.totalPrice = plan.price * totalSeats;
            this.proPlanPrice = this.isAnnualPayment ? (this.totalPrice / (totalSeats + 2) / 12) : plan.price;
        }
    }

    calculatedSeatsHandler() {
        const orgSeats = this.subscriptionFacade.getOrgSeats() + 1;
        let seats = {
            freeSeats: 0,
            quantity: orgSeats
        }
        if (this.addAdditionalSeats && this.isAnnualPayment) {
            seats = {
                freeSeats: 2,
                quantity: orgSeats + 3
            }
        }

        return seats;
    }


    upgrade() {
        this.loading = true;
        const plan = this.subscriptionFacade.getSelectedPlan(this.getPaymentPeriod(), 'Pro');
        this.subscriptionAnalyticsObject = {
            'subscription title': plan.title,
            'subscription period': plan.period,
            'subscription price': plan.price,
            'is starter to pro': true
        };
        this.track("click update subscription", this.subscriptionAnalyticsObject);
        this.subscription = this.accountsService.updatePlan(plan.id)
        .pipe(
            catchError((err: any): Observable<any> => this.handleUpgradeError$(err)),
            switchMap(() => this.checkPaymentStatus$()),
        )
        .subscribe(orgInfo => {
            if ( orgInfo.subscriptionPlanId && orgInfo.subscriptionPlanId === plan.id ) {
                this.subscriptionFacade.handleSuccessPaymentAnalytics(plan, orgInfo, 'upgrade');
                this.success = true;
                this.clearSubscription();
                this.handleAdditionalSeats();
                this.track("success upgrade customer", {
                    ...this.subscriptionAnalyticsObject,
                    'upgrade subscription plan': true,
                    'upgrade subscription plan type': 'starter to pro'
                });
            } else if ( orgInfo.lastPaymentErrorMessage &&  orgInfo.lastPaymentErrorMessage !== this.orgInfo["lastPaymentErrorMessage"]) {
                this.failed();
                this.track("failed upgrade customer", {
                    "failure message": `lastPaymentErrorMessage: ${orgInfo.lastPaymentErrorMessage}`,
                    ...this.subscriptionAnalyticsObject
                });
            }
        })
    }

    checkPaymentStatus$() {
        return this.accountsService.info(true).pipe(
            delay(2500),
            repeat(120),
            finalize(() => {
                if (!this.success) {
                    this.failed();
                    this.track("failed paying customer", {
                        "failure message": "5 minutes passed with no update",
                        ...this.subscriptionAnalyticsObject
                    });
                }
            })
        );
    }

    clearSubscription() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    failed() {
        this.clearSubscription();
        this.toasterService.showToast("danger", "Error occurred setting up your account, we will take care of that for you.");
        this.failure = true;
        this.close();
    }

    handleUpgradeError$(err: Error) {
        const error = (err as any).error;
        const isPaddleError = error && error.data === 'paddle';
        this.track("failed to update subscription", {
            "failure message": err.message,
            ...this.subscriptionAnalyticsObject
        });
        this.loading = false;
        this.close();
        this.failureMessageHandler(isPaddleError);
        return throwError(err);
    }

    handleAdditionalSeats() {
        if (!this.addAdditionalSeats) {
            this.loading = false;
            this.close(true);
            return;
        }
        this.track("start update subscription seats", this.subscriptionAnalyticsObject);
        return this.subscriptionFacade.handleAdditionalSeats$(this.seats)
        .pipe(catchError((err: any): Observable<any> => {
            this.toasterService.showToast('danger', 'Failed add additional seats', 'Please contact support');
            this.track("failed update subscription seats", {...this.subscriptionAnalyticsObject, 'failure message': err.message});
            return from([null])
        }))
        .subscribe(() => {
            this.track("success update subscription seats", this.subscriptionAnalyticsObject);
            this.loading = false;
            this.close(true);
        })
    }
    
    close(status = false) {
        this.ref.close(status);
    }



    failureMessageHandler(isPaddleError) {
         this.dialogService.open(FailedUpgradePlanMessageComponent, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
            context: { isPaddleError }
        }).onClose.subscribe((reopen: boolean) => {
            if (!reopen) return;
            this.dialogService.open(UpgradeStarterToProDialogComponent, {
                closeOnBackdropClick: false,
                closeOnEsc: false,
            }).onClose.pipe(switchMap(res => {
                if (!res) return from([ null ]);
                return this.dialogService.open(SuccessUpdatePlanDialog, {
                    closeOnBackdropClick: false,
                    closeOnEsc: false,
                    context: {}
                }).onClose
            })).subscribe()
        })
    }

}