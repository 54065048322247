export const accountVariables = [
  { "_id": "sd_0", "plan": "trial", "variable": {"tab": "Content", "group": "Account", "accountMapping": "sender.picture", "type": "image-video", "name": "sender_picture", "title": "Sender picture", "default": "https://assets.storydoc.com/5d7d8922e00d9265/37a739ee-ccf6-4aed-9e4d-4a82e0469ace", "tip": "Account picture", "validation": { "required": false }} },
  { "_id": "sd_1", "plan": "trial", "variable": {"tab": "Content", "group": "Account", "accountMapping": "sender.name", "type": "input", "name": "sender_name", "title": "Sender name", "default": '', "tip": "Account name", "validation": { "required": false }} },
  { "_id": "sd_2", "plan": "trial", "variable": {"tab": "Content", "group": "Account", "accountMapping": "sender.email", "type": "input", "name": "sender_email", "title": "Sender email", "default": '', "tip": "Account email", "validation": { "required": false }} },
  { "_id": "sd_3", "plan": "trial", "variable": {"tab": "Content", "group": "Account", "accountMapping": "sender.calendar", "type": "input", "name": "sender_calendar", "title": "Sender calendar link", "default": '', "tip": "Account calendar link", "validation": { "required": false } }},
  { "_id": "sd_4", "plan": "trial", "variable": {"tab": "Content", "group": "Account", "accountMapping": "sender.role", "type": "input", "name": "sender_role", "title": "Sender role", "default": '', "tip": "Sender role", "validation": { "required": false } }},
  { "_id": "sd_5", "plan": "trial", "variable": {"tab": "Content", "group": "Account", "accountMapping": "org.logo", "type": "image-video", "name": "organization_logo", "title": "Your company's logo", "default": "https://assets.storydoc.com/5d7d8922e00d9265/37a739ee-ccf6-4aed-9e4d-4a82e0469ace", "tip": "Organization logo", "validation": { "required": false }} },
  { "_id": "sd_6", "plan": "trial", "variable": {"tab": "Content", "group": "Account", "accountMapping": "org.title", "type": "input", "name": "organization_name", "title": "Your company's name", "default": '', "tip": "Organization name", "validation": { "required": false }} },
  { "_id": "sd_7", "plan": "trial", "variable": {"tab": "Content", "group": "Account", "accountMapping": "org.website", "type": "input", "name": "organization_website", "title": "Your company's website", "default": '', "tip": "Organization website", "validation": { "required": false }} },
]
