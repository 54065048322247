import { Component } from "@angular/core";
import { AccountsService } from "../../../services/accounts.service";

@Component({
  selector: 'ngx-pro-icon',
  templateUrl: 'pro-icon.component.html',
  styleUrls: [ 'pro-icon.component.scss' ],
})
    
export class ProIconComponent { 

  constructor(private accountsService: AccountsService) { }
  isAGroup: boolean = this.accountsService.isAGroup;
}