import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) { }

  latestBrandFetchResults$ = new BehaviorSubject<any>([]);
  suggestedBrandFetchResults$ = new BehaviorSubject<any>([]);
  libraryCurrentState$ = new BehaviorSubject<any>(null);
  scrollObserver$ = new Subject<any>();
  closeMediaLibrary$ = new Subject<void>();

  list(pageToken = null): Observable<any> { // admin only
    let url = environment.backend + '/media';
    if(pageToken) url += `?pageToken=${pageToken}`;
    return this.http.get(url);
  }

  unsplash(query = {}): Observable<any> {
    let url = environment.backend + '/media/unsplash';
    return this.http.post(url, query);
  }

  pexels(query = {}): Observable<any> {
    let url = environment.backend + '/media/pexels';
    return this.http.post(url, query);
  }

  unsplashDownload(downloadUrl): Observable<any> {
    let url = environment.backend + '/media/unsplash/download';
    return this.http.post(url, {url: downloadUrl});
  }

  convertApiToken(): Observable<any> {
    return this.http.get(environment.backend + '/media/convertApiToken');
  }

  generateOpenAI(prompt: string, disableRateLimit: boolean = false): Observable<any> {
    return this.http.post(environment.backend + '/media/openai', {prompt, disableRateLimit});
  }

  editImageStyleFromURL(url: string, options: {brightness: boolean, invert: boolean}): Observable<any> {
    if(!url) return
    return this.http.post(environment.backend + '/media/image-style', {url, options});
  }

  // CRUD
  get(params) {
    let url = environment.backend + '/media/myFiles';
    // for(let k in params) {
    //   const sign = url.indexOf('?') > -1 ? '&' : '?';
    //   url += sign + k + '=' + params[k];
    // }
    return this.http.post(url, params);
  }

  getFolders(params) {
    let url = environment.backend + '/media/myFiles/folders';
    for(let k in params) {
      const sign = url.indexOf('?') > -1 ? '&' : '?';
      url += sign + k + '=' + params[k];
    }
    return this.http.get(url);
  }

  create(media): Observable<any> {
    return this.http.post(environment.backend + '/media', media)
  }

  update(id, media): Observable<any> {
    return this.http.post(environment.backend + '/media/' + id, media)
  }

  updateMany(params, media): Observable<any> {
    return this.http.post(environment.backend + '/media/updateMany', {params,media});
  }

  delete(id): Observable<any> {
    return this.http.delete(environment.backend + '/media/' + id);
  }

  getWebsiteMetadata(url: string) {
    return this.http.post(`${environment.backend}/media/og-metadata`, {url});
  }

  brandSearch(domain: string) {
    return this.http.get(`https://api.brandfetch.io/v2/search/${domain}`);
  }
  

  updateLatestResults(results) {
    this.latestBrandFetchResults$.next(results)
  }
  updateSuggestedResults(results) {
    this.suggestedBrandFetchResults$.next(results)
  }

  updateMediaLibraryState(state) {
    this.libraryCurrentState$.next(state)
  }

  updateScroll(state) {
    this.scrollObserver$.next(state)
  }

  closeMediaLibrary() {
    this.closeMediaLibrary$.next()
  }
}
