import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient,
              private authService: AuthService) { }


  currentUser$ = new BehaviorSubject<Record<string, any>>(undefined);
  refreshSession$ = new Subject<void>();

  list(): Observable<any> {
    return this.http.get(environment.backend + '/v2/users');
  }

  me(): Observable<any> {
    return this.http.get(environment.backend + '/v2/users/me').pipe(
      tap(user => this.currentUser$.next(user)),
      catchError(err => {
        if (err.status ===  500) this.authService.logout()
        return throwError(err);
      })
    )
  }

  getCurrentUser$() {
    return this.currentUser$.pipe(filter(user => user !== undefined), take(1));
  }

  getOktaUser() {
    return this.http.get(environment.backend + '/v2/users/okta-me');
  }

  count(): Observable<any> {
    return this.http.get(environment.backend + '/v2/users/count');
  }

  firstLogin(): Observable<any> {
    return this.http.post(environment.backend + '/v2/users/firstLogin', {});
  }

  changeRole(newRole, oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/changeRole`, {newRole});
  }

  resetPassword(oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/resetPassword`, {});
  }

  reactivateUser(oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/reactivateUser`, {});
  }

  updateUser(user, oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}`, user);
  }
  updateUserOktaOrgID(user, oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/updateOrgId`, user);
  }

  sendUserOrgRequest(requestedOrgId, oktaId, user = {}): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/sendUserOrgRequest`, {requestedOrgId, user});
  }
  deleteUserOrgRequest(oktaId, user = {}): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/deleteUserOrgRequest`, user);
  }
  updateAutosaveCounter(counter: number): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/update-autosave-counter`, {counter});
  };
  updateWalkthrough(walkthrough: Record<string, any>): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/set-walkthrough`, {walkthrough});
  };

  deleteUser(oktaId): Observable<any> {
    return this.http.delete(environment.backend + `/v2/users/${oktaId}`);
  }

  createUser(user): Observable<any> {
    return this.http.post(environment.backend + '/v2/users', user);
  }

  updateUserName(name: {name: string}): Observable<any> {
   return this.http.post(environment.backend + '/v2/users/update-name', name);
  }

  updateUserProfile(data): Observable<any> {
   return this.http.post(environment.backend + '/v2/users/update-profile', data);
  }


}
