import { Component } from "@angular/core";
import { WorkspaceFacade } from "../workspace.facade";
import { BehaviorSubject } from "rxjs";
import { NbDialogRef } from "@nebular/theme";

@Component({
    selector: 'ngx-workspace-join-library',
    templateUrl: 'workspace-join-library.component.html',
    styleUrls: [ 'workspace-join-library.component.scss' ],
})
export class WorkspaceJoinLibraryComponent {

    constructor(private workspaceFacade: WorkspaceFacade,
                protected ref: NbDialogRef<WorkspaceJoinLibraryComponent>) { } 

    joinWorkspaceOverlayStatus$: BehaviorSubject<string> = this.workspaceFacade.joinWorkspaceOverlayStatus$;
    loading: boolean = false;


    logout() {
        this.loading = true;
        this.workspaceFacade.logout();
    }
    close() {
        this.ref.close();
    }

}