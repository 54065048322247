import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { AccountsService } from '../../services/accounts.service';
import { ToasterService } from '../../services/toaster.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'ngx-create-user-dialog',
  templateUrl: 'create-user-dialog.component.html',
  styleUrls: ['create-user-dialog.component.scss'],
})
export class CreateUserDialogComponent {

  @Input() orgInfo;
  orgId;
  email;
  firstName;
  lastName;
  editor = false;
  selfServ = false;
  loading = false;

  constructor(protected ref: NbDialogRef<CreateUserDialogComponent>,
              private accountsService: AccountsService,
              private toasterService: ToasterService) {}

  ngOnInit() {
    this.orgId = this.orgInfo._id
  }

  createUser() {
    this.loading = true;
    this.accountsService.createUser({
      orgId: this.orgId,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      editor: this.editor,
      selfServ: this.selfServ
    })
    .pipe(
      catchError((err: any) : Observable<any> => {
        this.toasterService.showToast('danger', 'Error creating user', err.message);
        this.loading = false;
        return throwError(err)
      })
    )
    .subscribe(user => {
      this.loading = false;
      this.toasterService.showToast('success', `User ${this.email} created successfully`, '');
      this.dismiss(user)
    });
  }

  dismiss(user = null) {
    this.ref.close(user);
  }
}
