export const staticContent = [
    {
        video: 'loader-1.mp4',
        title: 'Say hi to our powerful editor 👋',
        description: 'All the tools and assets you need to create amazing presentations. From slide templates to powerful integrations. '
    },
    {
        video: 'loader-2.mp4',
        title: 'AI Assistant - your new best friend',
        description: 'Generate compelling text and engaging visuals in the blink of an eye'
    },
    {
        video: 'loader-3.mp4',
        title: 'Mobile or Desktop?',
        description: 'All Storydocs are designed to look great on both mobile and desktop'
    },
    {
        video: 'loader-4.mp4',
        title: 'Your bookings times two',
        description: 'Get twice as many meetings by switching from PDFs to interactive Storydocs'
    },
    {
        video: 'loader-5.mp4',
        title: '“E” is for engagement',
        description: 'Storydocs are 60% more engaging than the average PDF. Go get those readers!'
    },
    {
        video: 'loader-6.mp4',
        title: 'Get real-time analytics',
        description: 'Gain full visibility of what happens after sending your deck. Available on your analytics panel'
    }
]